import UiLogo from '@/ui/components/UiLogo'

import { apiIdp } from '@/api/apiService'
import { getLink } from '@/helpers/getLink'

import { RELEASE_DATE_YEAR } from '@/constants'
import { GID_REQUISITES } from '@/pages/SsoLanding/constants'
import { GoyaBrowserEvent } from '@/features/GoyaAnalitics/types'
import { GoyaEventId } from '@/features/GoyaAnalitics/enums'
import { RoutePath } from '@/router/enums'

import * as S from './Footer.style'

import { useGoyaEvents } from './goya'
import { useGoyaAnalitics } from '@/features/GoyaAnalitics'

type PropsType = {
  isAuth?: boolean
  isSidebar?: boolean
  isLanding?: boolean
  isAbout?: boolean
}

// TODO надо вынести футер отдельно и потом обернуть в два разных HOC AuthFooter и MainFooter
const Footer = ({ isAuth = false, isSidebar = false, isLanding = false, isAbout = false }: PropsType) => {
  useGoyaEvents()
  const sendGoyaEvent = useGoyaAnalitics.use.sendEvent()

  const currentServerYear = apiIdp.date.getFullYear()

  const sendGoyaEventHandler = (uiEvent: GoyaBrowserEvent) => {
    sendGoyaEvent(GoyaEventId.FooterLink, uiEvent)
  }

  return (
    <S.Footer isAuth={isAuth} isSidebar={isSidebar} isLanding={isLanding} data-testid='footer'>
      <S.FooterInfoWrapper>
        <S.FooterInfo>
          Единый аккаунт для вселенной <br /> цифровых сервисов
        </S.FooterInfo>
        {!isSidebar ? <UiLogo width='88' height='52' /> : null}
      </S.FooterInfoWrapper>
      <S.FooterCopyrightWrapper>
        <S.FooterCopyrightLinkWrapper>
          <S.FooterLinkAbsolute
            data-testid='about'
            target='_blank'
            href={getLink(RoutePath.About)}
            onClick={sendGoyaEventHandler}
          >
            О сервисе
          </S.FooterLinkAbsolute>
          <S.FooterLinkAbsolute
            data-testid='faq'
            target='_blank'
            href={getLink(RoutePath.FAQ)}
            onClick={sendGoyaEventHandler}
          >
            Помощь
          </S.FooterLinkAbsolute>
          <S.FooterLinkAbsolute
            data-testid='personal_data'
            target='_blank'
            href={getLink(RoutePath.PersonalData)}
            onClick={sendGoyaEventHandler}
          >
            Конфиденциальность
          </S.FooterLinkAbsolute>
          {isLanding ? (
            <S.FooterLinkAbsolute data-testid='products' target='_blank' href={getLink(RoutePath.Products)}>
              Продукты ГИД
            </S.FooterLinkAbsolute>
          ) : null}
          <S.FooterLinkAbsolute
            data-testid='products'
            target='_blank'
            href={getLink(RoutePath.Agreement)}
            onClick={sendGoyaEventHandler}
          >
            Условия использования
          </S.FooterLinkAbsolute>
        </S.FooterCopyrightLinkWrapper>
        {isLanding ? (
          <S.FooterCopyright>
            <S.FooterCopyrightText>
              &copy; {RELEASE_DATE_YEAR}-{currentServerYear} ООО &laquo;Оператор Газпром ИД&raquo;
            </S.FooterCopyrightText>
            <S.FooterCopyrightText>
              ИНН: {GID_REQUISITES.INN}, ОГРН: {GID_REQUISITES.OGRN}
            </S.FooterCopyrightText>
            <S.FooterCopyrightText>
              <S.FooterCopyrightText>
                КПП: {GID_REQUISITES.KPP}, e-mail:
                <S.FooterLinkAbsolute data-testid='support_email' target='_blank' href='mailto:info@gid.ru'>
                  {' '}
                  info@gid.ru
                </S.FooterLinkAbsolute>
              </S.FooterCopyrightText>
              {isAbout ? (
                <S.FooterCopyrightText>
                  Пресс-служба:
                  <S.FooterLinkAbsolute
                    data-testid='press-office_email'
                    target='_blank'
                    href='mailto:press-office@gid.ru'
                  >
                    {' '}
                    press-office@gid.ru
                  </S.FooterLinkAbsolute>
                </S.FooterCopyrightText>
              ) : null}
            </S.FooterCopyrightText>
          </S.FooterCopyright>
        ) : (
          <S.FooterCopyright>
            &copy; {RELEASE_DATE_YEAR}-{currentServerYear} ООО &laquo;Оператор Газпром ИД&raquo;
          </S.FooterCopyright>
        )}
      </S.FooterCopyrightWrapper>
    </S.Footer>
  )
}

export default Footer
