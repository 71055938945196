import * as React from 'react'
import { RouteObject } from 'react-router-dom'

import { RoutePath } from '@/router/enums'

import LandingLayout from '@/layouts/LandingLayout'

const About = React.lazy(() => import('@/pages/SsoLanding/About'))
const NotFound = React.lazy(() => import('@/pages/SsoLanding/NotFound'))
const PersonalData = React.lazy(() => import('@/pages/SsoLanding/PersonalData'))
const PersonalDataConsent = React.lazy(() => import('@/pages/SsoLanding/PersonalDataConsent'))
const Agreement = React.lazy(() => import('@/pages/SsoLanding/Agreement'))
const Partners = React.lazy(() => import('@/pages/SsoLanding/Partners'))
const Countries = React.lazy(() => import('@/pages/SsoLanding/Countries'))
const Products = React.lazy(() => import('@/pages/SsoLanding/Products'))
const CookiesPolicy = React.lazy(() => import('@/pages/SsoLanding/CookiesPolicy'))
const ExternalServices = React.lazy(() => import('@/pages/SsoLanding/ExternalServices'))
const PersonalInfo = React.lazy(() => import('@/pages/SsoLanding/PersonalInfo'))

export const landingRoutes: RouteObject[] = [
  {
    element: <LandingLayout />,
    path: RoutePath.Home,
    children: [
      {
        element: <NotFound />,
        path: RoutePath.NotFound
      },
      {
        element: <About />,
        path: RoutePath.About
      },
      {
        element: <Agreement />,
        path: RoutePath.Agreement
      },
      {
        element: <PersonalData />,
        path: RoutePath.PersonalData
      },
      {
        element: <PersonalDataConsent />,
        path: RoutePath.PersonalDataConsent
      },
      {
        element: <Partners />,
        path: RoutePath.Partners
      },
      {
        element: <Countries />,
        path: RoutePath.Countries
      },
      {
        element: <Products />,
        path: RoutePath.Products
      },
      {
        element: <CookiesPolicy />,
        path: RoutePath.CookiesPolicy
      },
      {
        element: <ExternalServices />,
        path: RoutePath.ExternalServices
      },
      {
        element: <PersonalInfo />,
        path: RoutePath.PersonalInfo
      }
    ]
  }
]
