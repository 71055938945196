import { initialToastifyState } from './constants'
import { UiErrorToastifyActionType, UiErrorToastifyStateType } from './types'
import { UiErrorToastifyActionList } from './enum'

export const toastifyReducer = function <T>(
  state: UiErrorToastifyStateType<T>,
  action: UiErrorToastifyActionType<T>
): UiErrorToastifyStateType<T> {
  let secondsOTPData = null

  switch (action.type) {
    case UiErrorToastifyActionList.SET_ERRORS:
      return { ...state, errors: action.payload }
    case UiErrorToastifyActionList.SET_SECONDS:
      return { ...state, seconds: action.payload }
    case UiErrorToastifyActionList.SET_SECONDS_OTP:
      return {
        ...state,
        secondsOTP: {
          ...state.secondsOTP,
          [action.payload.principal]: {
            message: action.payload.message,
            date: new Date().getTime() + action.payload.seconds * 1000
          }
        }
      }
    case UiErrorToastifyActionList.SET_PHRASE_SECONDS_OTP:
      secondsOTPData = state.secondsOTP[action.payload.principal]
      if (secondsOTPData) {
        // модификация необходима для предотвращения ререндера
        secondsOTPData.phrase = action.payload.phrase
        return {
          ...state,
          secondsOTP: {
            ...state.secondsOTP,
            [action.payload.principal]: secondsOTPData
          }
        }
      }
      return state
    case UiErrorToastifyActionList.SET_CLOSE_TIME:
      return { ...state, closeTime: action.payload }
    case UiErrorToastifyActionList.CLEAR:
      return { ...state, seconds: 0, errors: [] }
    default:
      return { ...initialToastifyState }
  }
}
