import UiIcon from '@/ui/components/UiIcon'

import * as S from './RequestOTPButton.style'

type PropsType = {
  text: string
  onClick: () => void
  isLoading: boolean
}
const RequestOTPButton = ({ text, onClick, isLoading = false }: PropsType) => {
  return (
    <S.OTPButton data-testid='send-otp' type='button' onClick={onClick}>
      <S.SpinWrapper isSpinning={isLoading}>
        <UiIcon name='refreshArrow' width='20' height='20' />
      </S.SpinWrapper>
      <S.ButtonTitle>{text}</S.ButtonTitle>
    </S.OTPButton>
  )
}

export default RequestOTPButton
