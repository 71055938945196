import { useQuery } from '@tanstack/react-query'

import { apiIdp } from '@/api/apiService'
import onceRetryOn401 from './utils/onceRetryOn401'

import { Branding } from '@/store/brandingStore/types'

import { useBrandingSelector } from '@/store/brandingStore'

const getBranding = async (isRequestNewBranding: boolean): Promise<Branding> => {
  const requestPath = isRequestNewBranding ? 'flow/branding' : 'flow/completion/branding'

  const { data } = await apiIdp.get<Branding>(requestPath)
  return data
}

// TODO убрать использование флага новой ручки, когда фронт переедет на неё полностью
export const useGetBranding = (isRequestNewBranding: boolean = false) => {
  const status = useBrandingSelector.use.status()
  const setStatus = useBrandingSelector.use.setStatus()
  const setBranding = useBrandingSelector.use.setBranding()

  return useQuery({
    queryKey: ['branding'],
    queryFn: () => getBranding(isRequestNewBranding),
    enabled: status === 'needInit',
    onSuccess: setBranding,
    onError: () => {
      setStatus('error')
    },
    retry: onceRetryOn401
  })
}
