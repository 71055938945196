export enum ErrorFieldName {
  Credentials = 'credentials',
  PasswordConfirm = 'password-confirm',
  Password = 'password',
  Quuid = 'quuid'
}

export enum ErrorCodeName {
  OtpRateLimit = 'otp-rate-limit',
  TooManyAttemptsToVerify = 'too-many-attempts-to-verify',
  CaptchaKey = 'captcha-is-not-correct',
  OTPThrottlingUser = 'error',
  OTPThrottlingNewUser = 'registration-rate-limit',
  // снейк-кейс приходит с бэка из-за необходимости поддержки старых версий SDK, подстраиваем под него
  InvalidOTPCode = 'invalid_otp_code',
  FieldErrors = 'field-errors',
  OtpConflict = 'otp-conflict',
  PassworAlreadyUsed = 'password-already-used'
}

export type TErrorResponse = {
  field_name: ErrorFieldName
  error_description: string
}

export type TErrorToastify = {
  field_name?: ErrorFieldName
  error_code?: ErrorCodeName
  error_description: string
}

export type TErrorsResponse = {
  errors_code: ErrorCodeName
  errors_description: string
  field_errors: TErrorResponse[]
  retry_after_seconds?: number
  redirect_to?: string
}

export type TErrorCaptchaFactor = TErrorsResponse & {
  captcha_id: string
  captcha_image_base64: string
}
