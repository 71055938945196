import styled from '@emotion/styled'

import { css } from '@/ui/styles'

const { devices, gaps } = css

export const SidebarHeader = styled.div`
  display: flex;
  align-items: flex-end;
  padding: 0 ${gaps.g8};
  margin-bottom: ${gaps.g16};

  @media ${devices.d375} {
    padding: 0 ${gaps.g4};
  }
`

export const SidebarHeaderPhone = styled.div`
  min-width: 50rem;
  margin-left: auto;
  margin-right: ${gaps.g12};

  @media ${devices.d375} {
    margin-right: ${gaps.g8};
  }
`

export const SidebarHeaderLogout = styled.button`
  border: none;
  outline: none;
  cursor: pointer;
  padding: 0;
  width: ${gaps.g24};
  height: ${gaps.g24};
  background: inherit;
  --svg-color: ${({ theme }) => theme.colors.accent};
`
