// import sso_manual from '@/static/Руководство_пользователя_SSO.pdf'
// import gid_manual from '@/static/Руководство_пользователя_ГИД.pdf'

export const MAIL_GID_SUPPORT = 'sso-support@gid.ru'

export const MAIL_GID_DPO = 'dpo@gid.ru'

export const MAIL_GID_INFO = 'info@gid.ru'

export const RUSTORE_URL = 'https://apps.rustore.ru/app/ru/gid.app'

export const ADMIN_PANEL_URL = 'https://admin.app.gid.ru/app/'

export const REVOKE_CONSENT_PERSONAL_DATA_FILE1_URL =
  'https://docs.auth.gid.ru/objects-docs/manuals/products/sso/Приложение №1.pdf'

export const REVOKE_CONSENT_PERSONAL_DATA_FILE2_URL =
  'https://docs.auth.gid.ru/objects-docs/manuals/products/sso/Приложение №2.pdf'
