import { ThemeType } from '@/ui/contexts/themeContext/themeStyledTypes'

import { DEFAULT_GAP, DEFAULT_SIZE, LOADER_UNITS } from './constants'

import * as S from './UiLoader.style'

type PropsType = {
  size?: number
  gap?: number
  color?: keyof ThemeType['colors']
}

const UiLoader = ({ size = DEFAULT_SIZE, gap = DEFAULT_GAP, color = 'accent' }: PropsType) => (
  <S.LoaderWrapper data-testid='loader-wrapper' className='loader' unitsQuantity={LOADER_UNITS} $size={size} gap={gap}>
    {Array.from({ length: LOADER_UNITS }).map((_, index) => (
      <S.Loader key={index} animationOrder={index - 1} $size={size} color={color} />
    ))}
  </S.LoaderWrapper>
)

export default UiLoader
