import styled from '@emotion/styled'

import { ThemeType } from '@/ui/contexts/themeContext/themeStyledTypes'
import { css } from '@/ui/styles'

const { border, delay, devices, fontSizes, fontWeight, gaps, lineHeights } = css

type InputType = {
  error?: boolean
}

type InputTypeOTP = {
  lengthOTP: number
}

type TextType = {
  color?: keyof ThemeType['colors']
  cursor?: 'pointer' | 'normal'
  fontWeight?: keyof ThemeType['fontWeight']
}

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  gap: 9rem;
  margin: ${gaps.g24} 0;
`

export const Input = styled.input<InputType & InputTypeOTP>`
  width: 52.5rem;
  height: 56rem;
  padding: ${gaps.g12} 0;

  @media ${devices.d576} {
    width: 40.5rem;
  }

  border: ${({ theme }) => `1px solid ${theme.colors.dark10}`};
  border-radius: ${border.radius};

  text-align: center;
  color: ${({ theme }) => theme.colors.dark};
  font-size: ${fontSizes.fs24};
  line-height: ${lineHeights.lh32};
  font-weight: ${fontWeight.fw500};

  caret-color: ${({ theme, error }) => (error ? theme.colors.error : theme.colors.accent)};
  border-color: ${({ theme, error }) => (error ? theme.colors.error : theme.colors.dark10)};
  background-color: ${({ theme, error }) => (error ? theme.colors.inputBackOnError : theme.colors.inputBack)};

  text-overflow: ellipsis;
  outline: none;

  &:hover {
    border-color: ${({ theme, error }) => (error ? theme.colors.error : theme.colors.dark20)};
  }

  &:focus {
    border-color: ${({ theme, error }) => (error ? theme.colors.error : theme.colors.accent)};
    background-color: ${({ theme, error }) => (error ? theme.colors.inputBackOnError : theme.colors.inputBackOnFocus)};
  }

  &:disabled {
    color: ${({ theme }) => theme.colors.dark20};
    border: ${({ theme }) => `${border.width} solid ${theme.colors.dark10}`};
    background-color: transparent;
  }

  transition: border-color ${delay} ease-in-out;
`

export const CountdownWrapper = styled.p<TextType>`
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;

  height: 56rem;

  line-height: ${lineHeights.lh20};
  font-size: ${fontSizes.fs14};
  font-weight: ${({ fontWeight = 'fw500' }) => css.fontWeight[fontWeight]};
  color: ${({ theme }) => theme.colors.dark60};
  cursor: ${({ cursor = 'normal' }) => cursor};
`

export const TitleText = styled.p`
  text-align: center;

  font-size: ${fontSizes.fs16};
  font-weight: ${fontWeight.fw500};
  line-height: ${lineHeights.lh20};
  word-break: break-word;

  color: ${({ theme }) => theme.colors.dark60};
`
