import React from 'react'

import { RoutePath } from '@/router/enums'

// Хук работает только со static путями
const useCurrentRouteName = () => {
  const path = window.location.pathname
  return React.useMemo(() => {
    const pathArr = path.split('/') as RoutePath[]
    return Object.values(RoutePath).find(item => item === pathArr[pathArr.length - 1]) || '404'
  }, [path])
}

export default useCurrentRouteName
