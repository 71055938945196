import { ThemeColorsType } from '@/ui/contexts/themeContext/themeStyledTypes'

export const DARK_THEME: ThemeColorsType = {
  colors: {
    appBackground: '#101318',
    backgroundLight: '#101318',
    backgroundWhite: '#101318',

    modalBackground: '#282B2F',
    modalShadow: 'rgba(16, 19, 24, 0.9)',

    grayBackgroundCookie: '#282B2F',

    dark: 'rgba(255, 255, 255, 1)',
    dark5: 'rgba(255, 255, 255, 0.05)',
    dark10: 'rgba(255, 255, 255, 0.1)',
    dark20: 'rgba(255, 255, 255, 0.2)',
    dark40: 'rgba(255, 255, 255, 0.4)',
    dark60: 'rgba(255, 255, 255, 0.6)',
    dark70: 'rgba(255, 255, 255, 0.7)',
    dark80: 'rgba(255, 255, 255, 0.8)',

    inputBackAutofillBorder: '#484a4e',
    inputBackAutofill: '#2f3236',
    inputBack: 'rgba(255, 255, 255, 0.1)',
    inputBackOnFocus: 'rgba(49, 116, 246, 0.2)',
    inputBackOnError: 'rgba(241, 47, 105, 0.15)',

    colorBackground: '#101318'
  }
}
