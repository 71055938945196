import styled from '@emotion/styled'

import { css } from '@/ui/styles'

import { RoutePath } from '@/router/enums'

const { border, devices, gaps, width } = css

type PropsPage = {
  pageName?: string
}

export const LandingLayout = styled.div`
  min-height: 100vh;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  box-sizing: border-box;
`

export const Content = styled.div<PropsPage>`
  display: flex;
  flex: 1;
  flex-direction: column;
  box-sizing: border-box;
  width: ${({ pageName }) => (pageName === RoutePath.About ? 'unset' : width.w1100)};
  margin: ${({ pageName }) => (pageName === RoutePath.About ? 'unset' : '0 auto')};

  @media ${devices.d1100} {
    width: 100%;
  }

  @media ${devices.d375} {
    min-width: ${width.w320};
  }
`

export const FooterWrapper = styled.div`
  box-sizing: border-box;
  width: ${width.w1100};
  margin: 0 auto;
  padding: ${`0 ${gaps.g16} 74px`};

  @media ${devices.d1100} {
    display: block;
    width: 100%;
    padding: ${`0 ${gaps.g16} 40px`};
  }

  @media ${devices.d375} {
    display: block;
    min-width: ${width.w320};
    padding: ${gaps.g12};
  }
`

export const Separator = styled.hr`
  border: none;
  height: ${border.width};
  background-color: ${({ theme }) => theme.colors.dark10};

  &::-moz-placeholder {
    color: ${({ theme }) => theme.colors.dark10};
  }
`

export const FlexBoxCentered = styled.div`
  display: flex;
  flex: 1;
  justify-self: center;
  align-self: center;
  justify-content: center;
  align-items: center;
`
