import { AxiosError } from 'axios'

const onceRetryOn401 = (failureCount: number, error: AxiosError): boolean => {
  if (error?.response?.status === 401) {
    return false
  }

  if (failureCount >= 3) {
    return false
  }

  return true
}

export default onceRetryOn401
