import { PointAbout } from '@/pages/SsoLanding/About/enums'
import { PointAgreement } from '@/pages/SsoLanding/Agreement/enums'

enum RoutePath {
  AccountSelect = 'account',
  AccessDenied = 'access-denied',
  ServiceUnavailable = 'service-unavailable',
  AutoTestingPage = 'autotest',
  Home = '/',
  Callback = '/callback',
  UserOffice = 'office',
  MainPage = 'main',
  Services = 'services',
  Blocked = 'blocked',
  RestoreAccount = 'restore-account',
  RestorePassword = 'restore-password',
  Profile = 'profile',
  Change = 'change',
  Settings = 'settings',
  SettingsEmail = 'emails',
  SettingsRemoveAccount = 'remove',
  SettingsLoginHistory = 'history',
  SettingsDataAccess = 'data-access',

  Qr = 'qr',
  QrRefresh = 'refresh',

  Lost = 'lost',

  Auth = 'auth',
  Yandex = 'ya',
  Vk = 'vk',
  Social = 'social',

  Push = 'push',
  MobileId = 'mobileid',
  OtpCode = 'code',
  EmailOtp = 'email-otp',
  EmailPassword = 'email-password',
  FlashCall = 'flash-call',
  Password = 'password',
  RecoveryEmailOtp = 'recovery-email-otp',
  SetPassword = 'set-password',
  ChangePassword = 'change-password',
  RequestChangePassword = 'request-change-password',
  RecoveryOTP = 'recovery-otp',
  RecoveryFLashCall = 'recovery-flash-call',
  RecoveryPush = 'recovery-push',
  RecoveryMobileId = 'recovery-mobileid',
  RegistrationOTP = 'registration-otp',
  RegistrationFlashCall = 'registration-flash-call',
  RegistrationPush = 'registration-push',
  RegistrationMobileId = 'registration-mobileid',
  Completion = 'completion',
  Email = 'email',
  Details = 'details',
  Consent = 'consent',
  Removal = 'removal',
  AccountEmail = 'account-email',
  AccountDetails = 'account-details',
  AccountEmailConfirm = 'account-email-confirm',
  AccountEmailChange = 'account-email-change',
  Logout = 'logout',
  Confirm = 'confirm',

  NotFound = '*',

  PersonalData = 'legal/personal_data',
  PersonalDataConsent = 'legal/personal_data_consent',
  Agreement = 'legal/agreement',
  WithdrawalConsent = `${RoutePath.Agreement}#${PointAgreement.WithdrawalConsent}`,
  Partners = 'legal/partners',
  Countries = 'legal/countries',
  CookiesPolicy = 'legal/cookies_policy',
  ExternalServices = 'legal/external_services',
  PersonalInfo = 'legal/personal_info',

  Products = 'products',

  About = 'about',
  FAQ = `${About}#${PointAbout.FAQ}`
}

export default RoutePath
