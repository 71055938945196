import { HandleSwitch } from './types'

import * as S from './UiThemeSwitcher.style'

type PropsType = {
  onChange: (isSwitch: boolean) => void
}

const UiThemeSwitcher = ({ onChange }: PropsType) => {
  const handleSwitch: HandleSwitch = (e): void => onChange(e.target.checked)

  return (
    <S.UiThemeSwitcherWrapper>
      <S.UiThemeSwitcherCheckBox id='theme-switcher' type='checkbox' onChange={handleSwitch} />
      <S.UiThemeSwitcherLabel htmlFor='theme-switcher' />
    </S.UiThemeSwitcherWrapper>
  )
}

export default UiThemeSwitcher
