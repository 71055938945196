import { useThemeUpdater } from '@/ui/contexts/themeContext'

import { TClientInfo } from '@/types/auth'
import { Branding } from '@/store/brandingStore/types'

type SetBrandBackground = (clientInfo: TClientInfo | Branding | null) => void

export const useBrandBackground = () => {
  const { setBackImg } = useThemeUpdater()

  const setBrandBackground: SetBrandBackground = clientInfo => {
    if (clientInfo?.background_enabled && clientInfo?.background) setBackImg({ backgroundImg: clientInfo.background })
  }

  return { setBrandBackground }
}
