import { useMutation } from '@tanstack/react-query'

import { apiIdp, apiIdpPersonalAccount } from '@/api/apiService'
import { getDeviceType } from '@/helpers/getDeviceType'

import { TReactQueryMutate } from '@/types'
import {
  TCheckScorePersonalAccountRequest,
  TCheckScoreRequest,
  TCheckScoreResponse,
  TCustomCaptchaResponse,
  THeaders
} from '@/types/requestDataTypes'

export const useApiCustomCaptcha = (): TReactQueryMutate<string, TCustomCaptchaResponse> => {
  const { isSuccess, mutate, error, isLoading } = useMutation((captcha_id: string) => {
    return apiIdp.post<TCustomCaptchaResponse, { captcha_id: string }, THeaders>(
      'flow/captcha/refresh',
      { captcha_id },
      {
        headers: {
          'x-gid-device-type': getDeviceType()
        }
      }
    )
  })

  return { isSuccess, mutate, error: (error as Error)?.message, isLoading }
}

const checkScore = async ({ bs, step }: TCheckScoreRequest) => {
  const { data, status } = await apiIdp.post<TCheckScoreResponse, TCheckScoreRequest, THeaders>(
    '/flow/check-score',
    {
      bs,
      step
    },
    {
      headers: {
        'x-gid-device-type': getDeviceType()
      }
    }
  )
  return { ...data, status }
}

export const useCheckScore = () => {
  const { isSuccess, mutateAsync, error, isLoading } = useMutation(
    ['check-score'],
    async ({ bs, step }: TCheckScoreRequest) =>
      checkScore({
        bs,
        step
      })
  )

  return { isSuccess, mutateAsync, error: (error as Error)?.message, isLoading }
}

const checkScorePersonalAccount = async ({ ksid }: TCheckScorePersonalAccountRequest) => {
  const { data, status } = await apiIdpPersonalAccount.post<
    TCheckScoreResponse,
    TCheckScorePersonalAccountRequest,
    THeaders
  >(
    '/check-score',
    { ksid },
    {
      headers: {
        'x-gid-device-type': getDeviceType()
      }
    }
  )
  return { ...data, status }
}

export const useCheckScorePersonalAccount = () => {
  const { isSuccess, mutateAsync, error, isLoading } = useMutation(
    ['check-score-personal-account'],
    async ({ ksid }: TCheckScorePersonalAccountRequest) => checkScorePersonalAccount({ ksid })
  )

  return { isSuccess, mutateAsync, error, isLoading }
}
