import { devtools } from 'zustand/middleware'
import { create } from 'zustand'

import { IS_REAL_INTEGRATION } from '@/constants'
import { CheckScoreStep } from '@/enums/auth'

import createSelectors from '@/store/createSelectors'
import { CallbackSecure, CancelSecure } from '@/store/securityStore'

type CaptchaStoreState = {
  isCheckScoreLoading: boolean
  captchaIsExecuting: boolean
  captchaScreenIsShow: boolean
  captchaDasIsExecuting: boolean
  captchaImageBase64?: string
  captchaBS?: string
  captchaId?: string
  principal?: string
  step?: CheckScoreStep
  captchaBSPromise: Promise<void> | null
  verify: CallbackSecure
  cancel: CancelSecure
  needExecuteRecaptcha: boolean
}

type CaptchaStoreAction = {
  setIsCheckScoreLoading: (payload: boolean) => void
  setCaptchaId: (payload?: string) => void
  setCaptchaImageBase64: (payload?: string) => void
  setCaptchaScreenIsShow: (payload: boolean) => void
  setCaptchaIsExecuting: (payload: boolean) => void
  setCaptchaDasIsExecuting: (payload: boolean) => void
  setStep: (payload: CheckScoreStep) => void
  setCaptchaBS: (payload?: string) => void
  setPrincipal: (payload?: string) => void
  captchaOptionsFill: (captchaId: string, captchaImageBase64: string) => void
  setCaptchaBSPromise: (payload: Promise<void>) => void
  setVerify: (callback: CallbackSecure) => void
  setCancel: (callback: CancelSecure) => void
  setNeedExecuteRecaptcha: (payload: boolean) => void
}

export const captchaStore = create<CaptchaStoreState & CaptchaStoreAction>()(
  devtools(
    set => ({
      isCheckScoreLoading: false,
      captchaScreenIsShow: false,
      captchaIsExecuting: false,
      captchaDasIsExecuting: false,
      captchaBS: 'not-initialized',
      captchaImageBase64: undefined,
      captchaId: undefined,
      step: undefined,
      principal: undefined,
      captchaBSPromise: null,
      verify: () => new Promise(() => {}),
      cancel: () => {},
      needExecuteRecaptcha: false,
      setNeedExecuteRecaptcha: (payload: boolean) => set({ needExecuteRecaptcha: payload }),
      setIsCheckScoreLoading: (payload: boolean) => set({ isCheckScoreLoading: payload }),
      setCaptchaId: (payload?: string) => set({ captchaId: payload }),
      setCaptchaImageBase64: (payload?: string) => set({ captchaImageBase64: payload }),
      setCaptchaScreenIsShow: (payload: boolean) => set({ captchaScreenIsShow: payload }),
      setCaptchaIsExecuting: (payload: boolean) => set({ captchaIsExecuting: payload }),
      setCaptchaDasIsExecuting: (payload: boolean) => set({ captchaDasIsExecuting: payload }),
      setStep: (payload: CheckScoreStep) => set({ step: payload }),
      setCaptchaBS: (payload?: string) => set({ captchaBS: payload }),
      setPrincipal: (payload?: string) => set({ principal: payload }),
      captchaOptionsFill: (captchaId: string, captchaImageBase64: string) =>
        set({ captchaIsExecuting: true, captchaScreenIsShow: true, captchaId, captchaImageBase64 }),
      setCaptchaBSPromise: (payload: Promise<void>) => set({ captchaBSPromise: payload }),
      setVerify: (callback: CallbackSecure) => set({ verify: callback }),
      setCancel: (callback: CancelSecure) => set({ cancel: callback })
    }),
    { name: 'Captcha', enabled: !IS_REAL_INTEGRATION }
  )
)

export const useCaptchaSelector = createSelectors(captchaStore)
