import styled from '@emotion/styled'

export const AppThemeWrapper = styled.div`
  min-height: 100vh;
  height: 100%;
  background-color: ${({ theme }) => theme.colors.appBackground};
  color: ${({ theme }) => theme.colors.dark60};

  background-repeat: no-repeat;
  background-position: center;
  background-attachment: fixed;
  background-size: cover;
  background-image: ${({ theme }) => theme.backgroundImg};
`

export const FlexBoxCentered = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
`
