import { parsePhoneNumber } from 'libphonenumber-js'

export const phoneFormat = (s: string) => {
  let phone = s /* .replace(/[^0-9]/g, '') */

  if (phone.startsWith('8')) {
    phone = `+7${phone.slice(1)}`
  }

  return phone ? parsePhoneNumber(phone).formatInternational() : ''
}

export const isNumberAccessibleForFlashCall = (n: string): boolean => {
  return n.trim().startsWith('7') || n.trim().startsWith('+7')
}
