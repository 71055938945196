import { devtools } from 'zustand/middleware'
import { create } from 'zustand'

import { IS_REAL_INTEGRATION } from '@/constants'
import { CaptchaType } from './enums'

import createSelectors from '@/store/createSelectors'

type SecurityStoreState = {
  captchaType: CaptchaType
}

type SecurityStoreAction = {
  setCaptchaType: (captchaType: CaptchaType) => void
}

export const securityStore = create<SecurityStoreState & SecurityStoreAction>()(
  devtools(
    set => ({
      captchaType: CaptchaType.Captcha,
      setCaptchaType: (captchaType: CaptchaType) => set({ captchaType })
    }),
    { name: 'Security', enabled: !IS_REAL_INTEGRATION }
  )
)

export const useSecuritySelector = createSelectors(securityStore)
