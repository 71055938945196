import * as React from 'react'
import { Outlet } from 'react-router-dom'

import CaptchaErrorModal from '@/components/CaptchaErrorModal'

type PropsType = {
  children?: React.ReactNode
}

const SooLayoutStoreController = ({ children }: PropsType) => (
  <>
    {children || <Outlet />}
    <CaptchaErrorModal />
  </>
)

export default SooLayoutStoreController
