import * as React from 'react'
import * as ReactDOM from 'react-dom'
import { useEvent } from 'react-use'

import UiButton from '../UiButton'

import * as S from './UiModal.style'

type PropsType = {
  show: boolean
  title: React.ReactNode
  children?: React.ReactNode
  btnText?: string
  btnStyle?: 'primary' | 'secondary' | 'del'
  onClose: () => void
  persistent?: boolean
  image?: React.ReactNode
  width?: number | string
  footer?: React.ReactNode
  id?: string
  isLoadingBtn?: boolean
}

const UiModal = ({
  show,
  title,
  image,
  persistent = false,
  children,
  btnText = 'Продолжить',
  btnStyle = 'secondary',
  onClose,
  width,
  isLoadingBtn = false,
  footer = true,
  id
}: PropsType) => {
  const handleModalClick = (event: React.MouseEvent): void => {
    if (persistent) {
      event.stopPropagation()
    } else {
      onClose()
    }
  }

  const handleDialogClick = (event: React.MouseEvent): void => {
    event.stopPropagation()
  }

  const handleKeyDown: EventListener = React.useCallback(
    event => {
      if ('key' in event) {
        const { key } = event as unknown as React.KeyboardEvent
        if (key === 'Escape' && !persistent && show) onClose()
      }
    },
    [onClose, persistent, show]
  )

  useEvent('keydown', handleKeyDown as EventListener, document)

  const modalMarkup = (
    <>
      <S.Modal
        show={show}
        data-testid={id}
        onClick={handleModalClick}
        onKeyDown={event => handleKeyDown(event as unknown as Event)}
      >
        <S.ModalDialog onClick={handleDialogClick} width={width}>
          <S.ModalContent>
            <S.ModalHeader>
              {image && <S.ModalImage>{image}</S.ModalImage>}
              <S.ModalTitle data-testid='modal-title'>{title}</S.ModalTitle>
            </S.ModalHeader>
            <S.ModalBody>{children}</S.ModalBody>
            {typeof footer === 'boolean'
              ? footer && (
                  <S.ModalFooter>
                    <UiButton
                      name={id ? `${id}-accept` : undefined}
                      type='button'
                      buttonStyle={btnStyle}
                      onClick={onClose}
                      isLoading={isLoadingBtn}
                    >
                      {btnText}
                    </UiButton>
                  </S.ModalFooter>
                )
              : footer}
          </S.ModalContent>
        </S.ModalDialog>
      </S.Modal>
      <S.ModalBackdrop data-testid={id && `${id}-close`} show={show} onClick={handleModalClick} />
    </>
  )

  const root = document.getElementById('root')

  if (!root) return null

  return ReactDOM.createPortal(modalMarkup, root)
}

export default UiModal
