import { OneTimePassword } from '@/ui/components/UiOTP'

import { TypeKeySeconds } from './types'
import { AuthFactors } from '@/enums/auth'

export const getKeyOtpSeconds = (principal: string, factor?: TypeKeySeconds, isMobileId?: boolean): string => {
  switch (factor) {
    case AuthFactors.OTP:
    case AuthFactors.OTP_LK:
      if (isMobileId) return `${principal}_${OneTimePassword.MobileIdCode}`

      return `${principal}_${OneTimePassword.Code}`
    case AuthFactors.FLASH_CALL:
      return `${principal}_${OneTimePassword.FlashCall}`
    case OneTimePassword.Email:
      return `${principal}_${OneTimePassword.Email}`
    default:
      return principal
  }
}
