export enum ThemeActionTypeEnum {
  SET_THEME = 'setTheme',
  SET_BACK_IMG = 'setBackImg',
  SET_CUSTOM_COLORS = 'setCustomColors'
}

export enum ThemeList {
  DEFAULT = 'DEFAULT',
  DARK = 'DARK'
}

export enum CustomColor {
  Accent = 'accent',
  AccentHover = 'accent_hover',
  AccentPressed = 'accent_pressed',
  AccentButtonText = 'color_text_button',
  Secondary = 'secondary_accent',
  SecondaryHover = 'secondary_accent_hover',
  SecondaryPressed = 'secondary_accent_pressed',
  SecondaryButtonText = 'secondary_color_text_button',
  Background = 'color_background'
}
