import styled from '@emotion/styled'

import { css } from '@/ui/styles'

const { gaps, zIndex } = css

export const UiErrorToastifyStyled = styled.div`
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 10rem;
  border-radius: 10rem;
  z-index: ${zIndex.highest};
  top: 38rem;
  left: 50%;
  transform: translateX(-50%);
  width: 327rem;
  background-color: ${({ theme }) => theme.colors.error};
  color: ${({ theme }) => theme.colors.white};
  box-shadow:
    0 1px 10px 0 rgb(0 0 0 / 10%),
    0 2px 15px 0 rgb(0 0 0 / 5%);

  @media (max-width: 576px) {
    width: 288rem;
    top: ${gaps.g32};
  }
`
