import { css, Global } from '@emotion/react'

import { colors } from '@/ui/styles/colors'
import { fontSizes, fontWeight, lineHeights, width } from '@/ui/styles/variables'

export const GlobalCSSStyles = css`
  html {
    font-size: 1px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  body {
    font-family: 'PT Root UI', sans-serif;
    min-width: ${width.w320};
    margin: 0 auto;
    padding: 0;
    font-size: ${fontSizes.fs16};
    line-height: ${lineHeights.lh24};
    font-weight: ${fontWeight.fw500};
    /* color: ${colors.dark60}; */
    /* background-color: ${colors.appBackground}; */
  }

  a {
    text-decoration: none;
  }

  img {
    max-width: 100%;
    height: auto;
  }

  li {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  .grecaptcha-badge {
    display: none !important;
  }
`

export const GlobalStyles = () => <Global styles={GlobalCSSStyles} />
