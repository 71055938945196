import { colors } from '@/ui/styles/colors'
import {
  backgroundImg,
  border,
  delay,
  devices,
  fontSizes,
  fontWeight,
  gaps,
  lineHeights,
  width,
  zIndex
} from '@/ui/styles/variables'

export const THEME = {
  colors,
  backgroundImg,

  // immutable styles
  width,
  devices,
  fontWeight,
  fontSizes,
  lineHeights,
  gaps,
  border,
  delay,
  zIndex
}
