import React from 'react'

interface ErrorBoundaryProps {
  children: React.ReactNode
  fallback: React.ReactNode
  isShowFallback: boolean
}

interface ErrorBoundaryState {
  error: Error | null
}

class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props)
    this.state = { error: null }
  }

  componentDidCatch(error: Error): void {
    this.setState({ error })
  }

  render(): React.ReactNode {
    const { error } = this.state
    const { children, fallback, isShowFallback } = this.props

    if (error || isShowFallback) {
      return fallback
    }
    return children
  }
}

export default ErrorBoundary
