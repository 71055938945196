export enum AuthFactors {
  OTP = 'OTP',
  PUSH = 'PUSH',
  PASSWORD = 'PASSWORD',
  CASCADE_SPO = 'CASCADE_SPO',
  CASCADE_PO = 'CASCADE_PO',
  CASCADE_SO = 'CASCADE_SO',
  FLASH_CALL = 'FLASH_CALL',
  OTP_LK = 'OTP_LK',
  EMAIL_OTP = 'EMAIL_OTP',
  EMAIL_PASSWORD = 'EMAIL_PASSWORD'
}

export enum EnvBooleanValue {
  True = 'TRUE',
  False = 'FALSE'
}

export enum LocalStoragePostfix {
  OTP_TIMER = '_otp_timer',
  FLASH_CALL_TIMER = '_flash-call_timer'
}

export enum CheckScoreStep {
  FACTOR = 'FACTOR',
  LOGIN = 'LOGIN',
  LOGIN_EMAIL = 'LOGIN_EMAIL',
  RESET = 'RESET',
  FACTOR_EMAIL = 'FACTOR_EMAIL',
  EMAIL_PASSWORD = 'EMAIL_PASSWORD'
}

export enum AuthStep {
  RECOVERY = 'RECOVERY',
  REGISTER = 'REGISTER',
  LOGIN = 'LOGIN'
}

export enum ReasonReject {
  CaptchaCancel = 'CaptchaCancel'
}
