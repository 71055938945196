import * as React from 'react'
import { MutationCache, QueryCache, QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { useAuth } from 'react-oidc-context'
import { useNavigate } from 'react-router-dom'

import DisconnectModal from '@/components/DisconnectModal'
import ErrorBoundary from '@/components/ErrorBoundary'
import ErrorModal from '@/components/ErrorModal'

import { RoutePath } from '@/router/enums'

type PropsType = {
  children: React.ReactNode
}

const ContextWrapper = ({ children }: PropsType) => {
  const auth = useAuth()
  const navigator = useNavigate()
  const [isShowFallback, setIsShowFallback] = React.useState<boolean>(false)

  const onError = (error: unknown): void => {
    if (!(error instanceof AxiosError)) return

    if (Number(error?.response?.status) >= 500) {
      setIsShowFallback(true)
    }

    if (error?.response?.status === 401) {
      if (
        window.location.href.includes(`/${RoutePath.Auth}`) &&
        !window.location.href.includes(`/${RoutePath.Auth}/${RoutePath.Lost}`)
      ) {
        navigator(`/${RoutePath.Auth}/${RoutePath.Lost}`)
      }
      auth.removeUser().catch(() => null)
    }
  }

  const mutationCache = new MutationCache({ onError })

  const queryCache = new QueryCache({ onError })

  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false
      }
    },
    queryCache,
    mutationCache
  })

  return (
    <QueryClientProvider client={queryClient}>
      <ErrorBoundary fallback={<ErrorModal />} isShowFallback={isShowFallback}>
        <DisconnectModal />
        {children}
      </ErrorBoundary>
    </QueryClientProvider>
  )
}

export default ContextWrapper
