import styled from '@emotion/styled'

import { css } from '@/ui/styles'

const { devices } = css

export const Layout = styled.div`
  background-color: ${({ theme }) => theme.colors.appBackground};
  background-repeat: no-repeat;
  background-position: center;
  background-attachment: fixed;
  background-size: cover;
  background-image: ${({ theme }) => `url(${theme.backgroundImg})`};

  color: ${({ theme }) => theme.colors.dark60};

  width: 100%;
  height: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: center;

  @media ${devices.d576} {
    background-image: none;
  }
`

export const FlexBoxCentered = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`
