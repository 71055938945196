import { Link } from 'react-router-dom'

import UiIcon from '../UiIcon'

import { getLink } from '@/helpers/getLink'

import { RoutePath } from '@/router/enums'

type PropsType = {
  width: string
  height: string
}

const UiLogo = ({ width, height }: PropsType) => {
  return (
    <Link to={getLink(RoutePath.About)}>
      <UiIcon name='logo' width={width} height={height} />
    </Link>
  )
}

export default UiLogo
