import { IS_PROD } from '@/constants'
import { Domain, Protocol } from '@/enums'
import RoutePath from '../router/enums/enumPath'

export const getBaseURL = (domain: Domain, protocol = Protocol.HTTPS) => {
  return `${protocol}${domain}`
}

/**
 * @returns возвращает линк, зависимости от текущего окружения
 * @param path путь может быть enum RoutePath, либо массивом RoutePath,
 * если это комбинация
 * например:
 * [RouterPath.Auth, RouterPath.ChangePassword] = '${RouterPath.Auth}/${RouterPath.ChangePassword}'
 * @param forceProd если нужно явно указать что линк должен вести на прод
 */
export const getLink = (path?: RoutePath | RoutePath[], forceProd = false) => {
  let link = ''

  if (IS_PROD || forceProd) {
    switch (path) {
      // landing gid.ru
      case RoutePath.FAQ:
      case RoutePath.About:
      case RoutePath.Products:
        link += getBaseURL(Domain.Prod)
        break
      // another
      default:
        link += getBaseURL(Domain.ProdAuth)
    }
  }

  if (path) {
    switch (path) {
      case RoutePath.Home:
        link += RoutePath.Home
        break
      default:
        link += `/${Array.isArray(path) ? path.join('/') : path}`
    }
  }

  return link
}
