import * as React from 'react'

import { useApiUserData } from '@/api/requests/apiUserData'

import { TReactQuery } from '@/types'
import { TFullUserData } from '@/types/requestDataTypes'

import { usePrincipalStoreSelector } from '@/store/principalStore'

type TPrimaryPhoneRequest = {
  isLoading?: boolean
  primaryPhone: string
  error?: string
}

export const usePrimaryPhone = (): TPrimaryPhoneRequest => {
  const { isLoading, data: userData, error }: TReactQuery<TFullUserData> = useApiUserData()

  const primaryPhone = userData?.phones.find(phone => phone.primary)?.number ?? ''

  const updatePhone = usePrincipalStoreSelector.use.updatePhone()

  // сохраняем телефон на случай смены пароля
  React.useEffect(() => {
    if (primaryPhone) {
      updatePhone(primaryPhone)
      updatePhone(primaryPhone)
    }
  }, [primaryPhone, updatePhone])

  return { isLoading, primaryPhone, error }
}
