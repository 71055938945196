import * as React from 'react'

import { BrandingStoreController } from './brandingStore'
import { ClientInfoStoreController } from './clientInfoStore'
import { SecurityController } from './securityStore'

type PropsType = {
  children: React.ReactNode
}

const StoreControllers = ({ children }: PropsType) => {
  return (
    <SecurityController>
      <ClientInfoStoreController>
        <BrandingStoreController>{children}</BrandingStoreController>
      </ClientInfoStoreController>
    </SecurityController>
  )
}

export default StoreControllers
