import styled from '@emotion/styled'

import { css } from '@/ui/styles'

const { delay, devices, fontSizes, fontWeight, gaps, lineHeights, zIndex } = css

type ModalProps = {
  show: boolean
}

type ModalDialogProps = {
  width?: number | string
}

export const Modal = styled.div<ModalProps>`
  position: fixed;
  top: 0;
  left: 0;
  z-index: ${zIndex.l};
  display: ${({ show }) => (show ? 'flex' : 'none')};
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
  pointer-events: ${({ show }) => (show ? 'visible' : 'none')};
  opacity: ${({ show }) => (show ? '1' : '0')};
  transition: opacity ${delay} linear;
`

export const ModalDialog = styled.div<ModalDialogProps>`
  position: relative;
  width: auto;
  flex-grow: 1;
  max-width: ${({ width }) => (width ? `${width}px` : '500px')};
  margin: ${gaps.g16};
  pointer-events: none;
  transition: transform ${delay} linear;
  transform: translate(0, -50rem);

  ${Modal}.show > & {
    transform: none;
  }
`

export const ModalContent = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: ${gaps.g24};
  pointer-events: auto;
  background-color: ${({ theme }) => theme.colors.modalBackground};
  background-clip: padding-box;
  border-radius: 10rem;
  outline: 0;
`

export const ModalHeader = styled.div`
  color: ${({ theme }) => theme.colors.dark};
`

export const ModalImage = styled.div`
  margin-bottom: ${gaps.g16};
  display: flex;
  justify-content: center;
`

export const ModalTitle = styled.div`
  font-size: ${fontSizes.fs24};
  font-weight: ${fontWeight.fw500};
  line-height: ${lineHeights.lh32};
  margin: ${gaps.g8} 0 ${gaps.g8};
  text-align: center;

  @media ${devices.d768} {
    font-size: ${fontSizes.fs20};
    line-height: ${lineHeights.lh28};
  }
`

export const ModalBody = styled.div`
  color: ${({ theme }) => theme.colors.dark};
  font-size: ${fontSizes.fs16};
  line-height: ${lineHeights.lh24};
  text-align: center;
`

export const ModalFooter = styled.div`
  color: ${({ theme }) => theme.colors.accent};
  margin-top: ${gaps.g36};
`

export const ModalBackdrop = styled.div<ModalProps>`
  position: fixed;
  top: 0;
  left: 0;
  z-index: ${zIndex.m};
  width: 100vw;
  height: 100vh;
  transition: opacity ${delay} linear;
  background-color: ${({ theme }) => theme.colors.modalShadow};
  opacity: ${({ show }) => (show ? '1' : '0')};
  pointer-events: ${({ show }) => (show ? 'visible' : 'none')};
`
