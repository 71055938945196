/**
 * Конвертирует цвет из формата HEX в формат RGBA.
 * @param {string} hexColor - Цвет в формате HEX (#ABDDEE).
 * @param {number} alpha - Прозрачность (от 0 до 1).
 * @returns {string} - Цвет в формате RGBA.
 */
export const calcHexToRgba = (hexColor: string, alpha: number): string => {
  const r = parseInt(hexColor.substring(1, 3), 16)
  const g = parseInt(hexColor.substring(3, 5), 16)
  const b = parseInt(hexColor.substring(5), 16)

  const finishAlpha = alpha >= 1 ? 1 : alpha

  return `rgba(${r}, ${g}, ${b}, ${finishAlpha})`
}

/**
 * Конвертирует цвет из формата HEX в формат RGB.
 * @param {string} hexColor - Цвет в формате HEX (#ABDDEE).
 * @returns {string} - Цвет в формате RGB.
 */
export const calcHexToRgb = (hexColor: string): string => {
  const r = parseInt(hexColor.substring(1, 3), 16)
  const g = parseInt(hexColor.substring(3, 5), 16)
  const b = parseInt(hexColor.substring(5), 16)

  return `rgb(${r}, ${g}, ${b})`
}
