import { declinationOfTime } from '@/helpers/declinationOfTime'

export const getTimerInMinutesAndSeconds = (seconds: number): string => {
  const cSeconds = seconds % 60
  const cMinutes = Math.floor(seconds / 60)

  return `
      ${cMinutes ? declinationOfTime(cMinutes, 'minutes', true) : ''}
      ${cSeconds ? declinationOfTime(cSeconds, 'seconds', true) : ''}`
}
