import * as React from 'react'
import { useLocation } from 'react-router-dom'

import UiErrorToastify from './UiErrorToastify'

import { toastifyReducer } from './utils'

import { initialToastifyState } from './constants'
import { TErrorToastify } from '@/types/errors'
import {
  ErrorToastifyProviderPropsType,
  UiErrorToastifyActionType,
  UiErrorToastifyStateType,
  UiErrorToastifyUpdaterType
} from './types'
import { UiErrorToastifyActionList } from './enum'

const ErrorToastifyUpdater = React.createContext<UiErrorToastifyUpdaterType<TErrorToastify> | null>(null)
const ErrorToastifyState = React.createContext<UiErrorToastifyStateType<TErrorToastify> | null>(null)

export const useUiErrorToastifyState = () => {
  const context = React.useContext(ErrorToastifyState)
  if (!context) {
    throw new Error('UiErrorToastify state can be accessed only under ErrorToastifyProvider')
  }
  return context
}

export const useUiErrorToastifyUpdater = () => {
  const context = React.useContext(ErrorToastifyUpdater)
  if (!context) {
    throw new Error('UiErrorToastify updater can be accessed only under ErrorToastifyProvider')
  }
  return context
}

export const UiErrorToastifyProvider = ({ children }: ErrorToastifyProviderPropsType) => {
  const location = useLocation()

  const [state, dispatch] = React.useReducer(
    (state: UiErrorToastifyStateType<TErrorToastify>, action: UiErrorToastifyActionType<TErrorToastify>) =>
      toastifyReducer<TErrorToastify>(state, action),
    initialToastifyState
  )

  const clear = React.useCallback(() => {
    dispatch({ type: UiErrorToastifyActionList.CLEAR })
  }, [])

  const stateValue = React.useMemo<UiErrorToastifyStateType<TErrorToastify>>(() => {
    return {
      errors: state.errors,
      secondsOTP: state.secondsOTP,
      seconds: state.seconds,
      closeTime: state.closeTime
    }
  }, [state])

  const updaterValue = React.useMemo<UiErrorToastifyUpdaterType<TErrorToastify>>(() => {
    return {
      setErrors: (errors: TErrorToastify[]) =>
        dispatch({ type: UiErrorToastifyActionList.SET_ERRORS, payload: errors }),
      setSeconds: (seconds: number) => dispatch({ type: UiErrorToastifyActionList.SET_SECONDS, payload: seconds }),
      setSecondsOTP: (principal: string, seconds: number, message?: string) =>
        dispatch({ type: UiErrorToastifyActionList.SET_SECONDS_OTP, payload: { seconds, principal, message } }),
      setPhraseSecondsOTP: (principal: string, phrase: string) =>
        dispatch({ type: UiErrorToastifyActionList.SET_PHRASE_SECONDS_OTP, payload: { principal, phrase } }),
      setCloseTime: (closeTime: number | null) =>
        dispatch({ type: UiErrorToastifyActionList.SET_CLOSE_TIME, payload: closeTime }),
      clearErrors: clear
    }
  }, [clear])

  React.useEffect(() => {
    // Clear errors if location is changed
    clear()
  }, [clear, location])

  return (
    <ErrorToastifyState.Provider value={stateValue}>
      <ErrorToastifyUpdater.Provider value={updaterValue}>
        <UiErrorToastify
          errors={stateValue.errors}
          seconds={stateValue.seconds}
          closeTime={stateValue.closeTime}
          setCloseTime={updaterValue.setCloseTime}
          clearErrors={clear}
        />
        {children}
      </ErrorToastifyUpdater.Provider>
    </ErrorToastifyState.Provider>
  )
}
