import { OneTimePassword, PhraseVersion } from './enums'

export const TIME_TO_UPDATE_OTP_TIMER = 200

export const remainingTimePhrase: Record<OneTimePassword, string> = {
  [OneTimePassword.Code]: 'Повторно код можно получить через:',
  [OneTimePassword.MobileIdCode]: 'Повторно код можно получить через:',
  [OneTimePassword.Email]: 'Повторно код можно получить через:',
  [OneTimePassword.FlashCall]: 'Повторно звонок можно запросить через:'
}

export const lengthOneTimePassword: Record<OneTimePassword, number> = {
  [OneTimePassword.Code]: 6,
  [OneTimePassword.Email]: 6,
  [OneTimePassword.FlashCall]: 4,
  [OneTimePassword.MobileIdCode]: 4
}

export const getPhrase: Record<OneTimePassword, Record<PhraseVersion, string>> = {
  [OneTimePassword.Code]: {
    [PhraseVersion.Retry]: 'Получить код повторно',
    [PhraseVersion.Simple]: 'Получить код'
  },
  [OneTimePassword.MobileIdCode]: {
    [PhraseVersion.Retry]: 'Получить код повторно',
    [PhraseVersion.Simple]: 'Получить код'
  },
  [OneTimePassword.Email]: {
    [PhraseVersion.Retry]: 'Получить код повторно',
    [PhraseVersion.Simple]: 'Получить код'
  },
  [OneTimePassword.FlashCall]: {
    [PhraseVersion.Retry]: 'Получить звонок повторно',
    [PhraseVersion.Simple]: 'Получить звонок'
  }
}

export const templateTitle: Record<OneTimePassword, (principal?: string) => string> = {
  [OneTimePassword.Code]: () => 'Введите код, отправленный на ваш номер',
  [OneTimePassword.MobileIdCode]: () => 'Введите код, отправленный на ваш номер',
  [OneTimePassword.Email]: principal => `Введите код, отправленный на ${principal || 'ваш e-mail'}`,
  [OneTimePassword.FlashCall]: () => 'На ваш номер поступит звонок. Введите последние 4 цифры номера'
}
