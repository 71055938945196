import * as React from 'react'

import UiIcon from '@/ui/components/UiIcon'
import UiModal from '@/ui/components/UiModal'

import { WarningIconWrapper } from './CaptchaErrorModal.style'

import { useRecaptchaSelector } from '@/store/securityStore/recaptchaStore'

const CaptchaErrorModal = () => {
  const [isShowModal, setIsShowModal] = React.useState<boolean>(false)

  const recaptchaIsBroken = useRecaptchaSelector.use.recaptchaIsBroken()

  const title = (
    <p>
      {' '}
      Ошибка. <br /> Обновите страницу и попробуйте снова{' '}
    </p>
  )

  React.useEffect(() => {
    setIsShowModal(recaptchaIsBroken)
  }, [recaptchaIsBroken])

  const handleOnClose = () => {
    window.location.reload()
  }

  return (
    <UiModal
      show={isShowModal}
      title={title}
      btnText='Перезагрузить страницу'
      btnStyle='primary'
      id='captcha-error-modal'
      onClose={handleOnClose}
      image={
        <WarningIconWrapper>
          <UiIcon name='warning' width='88' height='88' />
        </WarningIconWrapper>
      }
    />
  )
}

export default CaptchaErrorModal
