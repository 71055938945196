import * as React from 'react'
import { AxiosError } from 'axios'
import { useLocation } from 'react-router-dom'

import { useCheckScore, useCheckScorePersonalAccount } from '@/api/requests/apiCustomCaptcha'
import { useEmailCheckScore } from '@/api/requests/apiEmailCheckScore'
import useErrorParser from '@/hooks/useErrorsParser'

import { CAPTCHA_EXECUTING_DELAY_MS } from '@/constants'
import { TErrorsResponse } from '@/types/errors'
import { AuthFactors, CheckScoreStep } from '@/enums/auth'
import { RoutePath } from '@/router/enums'

import { usePrincipalStoreSelector } from '@/store/principalStore'
import { CaptchaSignature, KSIDType, RecaptchaSignature, ResponseCaptcha, SecureSignature } from '@/store/securityStore'
import { captchaStore, useCaptchaSelector } from '@/store/securityStore/captchaStore'
import { recaptchaStore } from '@/store/securityStore/recaptchaStore'

export const useSecurityActions = () => {
  const { pathname } = useLocation()
  const isOfficeChangePassword = pathname?.endsWith(`/${RoutePath.UserOffice}/${RoutePath.RecoveryOTP}`)

  const { mutateAsync: checkScore, isLoading: isLoadingCheckScore } = useCheckScore()
  const { mutateAsync: emailCheckScore, isLoading: isLoadingEmailCheckScore } = useEmailCheckScore()
  const { mutateAsync: checkScorePersonalAccount, isLoading: isLoadingCheckScorePersonalAccount } =
    useCheckScorePersonalAccount()

  const phone = usePrincipalStoreSelector.use.phone()

  const captchaDasIsExecuting = useCaptchaSelector.use.captchaDasIsExecuting()
  const captchaBSPromise = useCaptchaSelector.use.captchaBSPromise()
  const principalCaptcha = useCaptchaSelector.use.principal?.()
  const needExecuteRecaptcha = useCaptchaSelector.use.needExecuteRecaptcha()
  const stateStep = useCaptchaSelector.use.step?.()
  const captchaIsExecuting = useCaptchaSelector.use.captchaIsExecuting()
  const captchaBS = useCaptchaSelector.use.captchaBS?.()

  const {
    setNeedExecuteRecaptcha,
    setPrincipal,
    setStep,
    setVerify,
    setCancel,
    captchaOptionsFill,
    setCaptchaScreenIsShow,
    setIsCheckScoreLoading
  } = captchaStore.getState()

  const {
    recaptchaIsReady,
    recaptchaRef,
    onCancel,
    recaptchaIsEnabled,
    setRecaptchaIsExecuting,
    setRecaptchaIsBroken,
    setRecaptchaIsExecutingLonger,
    setOnCancel,
    setOnVerify
  } = recaptchaStore.getState()

  const errorParser = useErrorParser(phone, AuthFactors.OTP)

  React.useEffect(() => {
    setIsCheckScoreLoading(isLoadingCheckScore || isLoadingEmailCheckScore || isLoadingCheckScorePersonalAccount)
  }, [isLoadingCheckScore, isLoadingCheckScorePersonalAccount, isLoadingEmailCheckScore, setIsCheckScoreLoading])

  const initCaptchaKsid = React.useCallback(async () => {
    if (captchaDasIsExecuting) {
      await captchaBSPromise
      try {
        return await window.kfp?.login_start()
      } catch {
        throw new Error(KSIDType.Empty)
      }
    }
    return Promise.resolve(KSIDType.Empty)
  }, [captchaBSPromise, captchaDasIsExecuting])

  const executeRecaptcha = React.useCallback(() => {
    if (recaptchaIsReady && recaptchaRef) {
      setRecaptchaIsExecuting(true)

      setTimeout(() => {
        if (recaptchaIsReady) {
          setRecaptchaIsExecutingLonger(true)
        }
      }, CAPTCHA_EXECUTING_DELAY_MS)

      recaptchaRef
        .execute()
        .then(() => {
          // У recaptcha нет события закрытия модалки,
          // поэтому считаем что модалку закрыли сразу, чтобы не блокировать пользователя в случае закрытия
          if (onCancel) {
            onCancel()
          }
        })
        .catch(() => {
          setRecaptchaIsExecuting(false)
          setRecaptchaIsBroken(true)
        })
    } else {
      setNeedExecuteRecaptcha(true)
    }
  }, [
    onCancel,
    recaptchaIsReady,
    recaptchaRef,
    setNeedExecuteRecaptcha,
    setRecaptchaIsBroken,
    setRecaptchaIsExecuting,
    setRecaptchaIsExecutingLonger
  ])

  const recaptcha: RecaptchaSignature = React.useCallback(
    (callback, cancel) => {
      setNeedExecuteRecaptcha(!needExecuteRecaptcha)
      setOnVerify(callback)
      setOnCancel(cancel)
    },
    [setNeedExecuteRecaptcha, setOnVerify, setOnCancel, needExecuteRecaptcha]
  )

  const captcha: CaptchaSignature = React.useCallback(
    (callback, cancel, step) => {
      return new Promise((resolve, reject) => {
        const saveStateStep = stateStep
        const stepIsChanged = step !== saveStateStep

        const isPrincipalChanged = principalCaptcha !== phone

        if (isPrincipalChanged) {
          setPrincipal(phone)
        }

        if (stepIsChanged) {
          setStep(step)
        }

        setVerify(callback)
        setCancel(cancel)

        const isNeedInitCheckScore = stepIsChanged || isPrincipalChanged

        if (!captchaIsExecuting || isNeedInitCheckScore) {
          if (isOfficeChangePassword) {
            initCaptchaKsid()
              .then(ksid => {
                if (!ksid) return checkScorePersonalAccount({ ksid: 'not-initialized' })

                return checkScorePersonalAccount({ ksid })
              })
              .then(async ({ captcha_id, captcha_image_base64, status }) => {
                if (status === 202) await callback()

                // Если вернулся captcha_id то вызывать капчу
                if (captcha_id) {
                  captchaOptionsFill(captcha_id, captcha_image_base64)
                  resolve(ResponseCaptcha.Ok)
                  return
                }

                // Если не вернулся captcha_id и сейчас логин вызывает инициализацию
                if (step === CheckScoreStep.LOGIN) {
                  // Нужно вызвать отправить ksid
                  initCaptchaKsid()
                    .then(ksid => {
                      callback(undefined, undefined, ksid)
                        // Promise always resolve
                        .catch(() => null)
                      resolve(ResponseCaptcha.Ok)
                    })
                    // Promise always resolve
                    .catch(() => null)
                } else {
                  // Если это не логин, то капча не нужна
                  resolve(ResponseCaptcha.NotNeeded)
                }

                // Сбрасываем шаг чтобы в след раз еще раз проверить нужна ли капча
                setStep(CheckScoreStep.RESET)
              })
              .catch((e: AxiosError<TErrorsResponse>) => {
                // в случае 500 статуса отображаем модалку с ошибкой
                if (e.response?.status === 500) {
                  setRecaptchaIsBroken(true)
                } else {
                  // в случае тротлинга перекинуть степ на предыдущий
                  if (stepIsChanged && saveStateStep) {
                    setStep(saveStateStep)
                  }

                  reject(e)
                }
              })
              .finally(() => null)
          } else if (captchaBSPromise) {
            captchaBSPromise
              .then(async () => {
                if (
                  step === CheckScoreStep.FACTOR_EMAIL ||
                  step === CheckScoreStep.EMAIL_PASSWORD ||
                  step === CheckScoreStep.LOGIN_EMAIL
                ) {
                  return emailCheckScore({ bs: captchaBS, step })
                }

                return checkScore({ bs: captchaBS, step })
              })
              .then(async ({ captcha_id, captcha_image_base64 }) => {
                // Если вернулся captcha_id то вызывать капчу
                if (captcha_id) {
                  captchaOptionsFill(captcha_id, captcha_image_base64)
                  resolve(ResponseCaptcha.Ok)
                  return
                }

                // Если не вернулся captcha_id и сейчас логин вызывает инициализацию
                if (step === CheckScoreStep.LOGIN) {
                  // Нужно вызвать отправить ksid
                  initCaptchaKsid()
                    .then(ksid => {
                      callback(undefined, undefined, ksid)
                        // Promise always resolve
                        .catch(() => null)
                      resolve(ResponseCaptcha.Ok)
                    })
                    // Promise always resolve
                    .catch(() => null)
                } else {
                  // Если это не логин, то капча не нужна
                  resolve(ResponseCaptcha.NotNeeded)
                }

                // Сбрасываем шаг чтобы в след раз еще раз проверить нужна ли капча
                setStep(CheckScoreStep.RESET)
              })
              .catch((e: AxiosError<TErrorsResponse>) => {
                // в случае 500 статуса отображаем модалку с ошибкой
                if (e.response?.status === 500) {
                  setRecaptchaIsBroken(true)
                } else {
                  // в случае тротлинга перекинуть степ на предыдущий
                  if (stepIsChanged && saveStateStep) {
                    setStep(saveStateStep)
                  }

                  reject(e)
                }
              })
              .finally(() => null)
          } else {
            let cb = checkScore

            if (
              step === CheckScoreStep.FACTOR_EMAIL ||
              step === CheckScoreStep.EMAIL_PASSWORD ||
              step === CheckScoreStep.LOGIN_EMAIL
            ) {
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              cb = emailCheckScore
            }

            cb({ bs: 'not-initialized', step })
              .then(({ captcha_id, captcha_image_base64 }) => {
                // Если вернулся captcha_id то вызывать капчу
                if (captcha_id) {
                  captchaOptionsFill(captcha_id, captcha_image_base64)
                  resolve(ResponseCaptcha.Ok)
                  return
                }

                // Если не вернулся captcha_id и сейчас логин вызывает инициализацию
                if (step === CheckScoreStep.LOGIN) {
                  // Нужно вызвать отправить ksid
                  initCaptchaKsid()
                    .then(ksid => {
                      callback(undefined, undefined, ksid)
                        // Promise always resolve
                        .catch(() => null)
                      resolve(ResponseCaptcha.Ok)
                    })
                    // Promise always resolve
                    .catch(() => null)
                } else {
                  // Если это не логин, то капча не нужна
                  resolve(ResponseCaptcha.NotNeeded)
                }

                // Сбрасываем шаг чтобы в след раз еще раз проверить нужна ли капча
                setStep(CheckScoreStep.RESET)
              })
              .catch((e: AxiosError<TErrorsResponse>) => {
                // в случае 500 статуса отображаем модалку с ошибкой
                if (e.response?.status === 500) {
                  setRecaptchaIsBroken(true)
                } else {
                  // в случае тротлинга перекинуть степ на предыдущий
                  if (stepIsChanged && saveStateStep) {
                    setStep(saveStateStep)
                  }

                  reject(e)
                }
              })
              .finally(() => null)
          }
        } else {
          setCaptchaScreenIsShow(true)
        }
      })
    },
    [
      stateStep,
      principalCaptcha,
      phone,
      setVerify,
      setCancel,
      captchaIsExecuting,
      setPrincipal,
      setStep,
      isOfficeChangePassword,
      captchaBSPromise,
      initCaptchaKsid,
      checkScorePersonalAccount,
      captchaOptionsFill,
      setRecaptchaIsBroken,
      emailCheckScore,
      captchaBS,
      checkScore,
      setCaptchaScreenIsShow
    ]
  )

  const initSecure: SecureSignature = React.useCallback(
    (callback, cancel, step) => {
      captcha(callback, cancel, step)
        .then(response => {
          if (response === ResponseCaptcha.Ok) {
            return ResponseCaptcha.Ok
          }

          if (recaptchaIsEnabled) {
            return recaptcha(callback, cancel)
          }

          if (!isOfficeChangePassword) return callback()
        })
        .catch((error: AxiosError<TErrorsResponse>) => {
          // В случе возникновении ошибки отменять анимацию
          cancel()
          errorParser(error)
        })
    },
    [captcha, errorParser, isOfficeChangePassword, recaptcha, recaptchaIsEnabled]
  )

  return {
    initCaptchaKsid,
    executeRecaptcha,
    recaptcha,
    captcha,
    initSecure
  }
}
