import UiIcon from '@/ui/components/UiIcon'

import { menuList } from './constants'
import { MenuListType } from './types'

import * as S from './AppMenu.style'

const AppMenu = () => {
  return (
    <nav>
      <S.AppMenuList>
        {menuList.map((item: MenuListType) => (
          <S.AppMenuItem data-testid={`${item.linkName}-menu`} key={item.linkName}>
            <S.AppMenuItemLink to={item.linkTo}>
              <S.AppMenuItemIcon>
                <UiIcon name={item.linkIconName} width='24' height='24' />
              </S.AppMenuItemIcon>
              {item.linkTitle}
            </S.AppMenuItemLink>
          </S.AppMenuItem>
        ))}
      </S.AppMenuList>
    </nav>
  )
}

export default AppMenu
