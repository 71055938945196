import { css as cssThemed, CssThemed } from '@emotion/react'
import styled from '@emotion/styled'
import { NavLink } from 'react-router-dom'

import { css } from '@/ui/styles'

const { border, devices, fontSizes, fontWeight, gaps, lineHeights, width } = css

const appMenuItemLink: CssThemed = ({ theme }) => cssThemed`
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: ${gaps.g16};
  color: ${theme.colors.dark60};
  font-weight: ${fontWeight.fw700};

  @media ${devices.d1100} {
    background-color: ${theme.colors.dark5};
    border-radius: ${border.radius};
  }

  @media ${devices.d768} {
    text-align: center;
    padding: ${gaps.g8} 0;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`

export const AppMenuList = styled.ul`
  margin: 0;
  padding: 0;

  @media ${devices.d1100} {
    display: flex;
    justify-content: space-between;
    max-width: ${width.w768};
    width: 100%;
  }
`

export const AppMenuItem = styled.li`
  @media ${devices.d1100} {
    width: 100%;
    margin: 0 ${gaps.g4};

    &:first-of-type {
      margin-left: 0;
    }

    &:last-of-type {
      margin-right: 0;
    }
  }

  @media ${devices.d768} {
    font-size: ${fontSizes.fs12};
  }
`

export const AppMenuItemLink = styled(NavLink)`
  ${appMenuItemLink};

  --svg-color: ${({ theme }) => theme.colors.dark60};

  &.active {
    color: ${({ theme }) => theme.colors.accent};
    background-color: ${({ theme }) => theme.colors.dark5};
    border-radius: ${border.radius};

    --svg-color: ${({ theme }) => theme.colors.accent};

    @media ${devices.d1100} {
      background-color: ${({ theme }) => theme.colors.white};
    }
  }
`

export const AppMenuItemIcon = styled.div`
  height: ${lineHeights.lh26};
  margin-right: ${gaps.g16};

  @media ${devices.d768} {
    margin-right: 0;
  }
`
