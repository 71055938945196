import Footer from '@/components/Footer'
import AppMenu from '@/components/Sidebar/components/AppMenu'
import SidebarHeader from '@/components/Sidebar/components/SidebarHeader'

import * as S from './Sidebar.style'

type PropsType = {
  onLogout: () => void
}

const Sidebar = ({ onLogout }: PropsType) => {
  return (
    <S.Sidebar>
      <SidebarHeader onLogout={onLogout} />
      <AppMenu />
      <S.SidebarFooter>
        <Footer isSidebar />
      </S.SidebarFooter>
    </S.Sidebar>
  )
}

export default Sidebar
