const sizes = {
  s320: 320,
  s360: 360,
  s375: 375,
  s450: 450,
  s576: 576,
  s710: 710,
  s768: 768,
  s1024: 1024,
  s1100: 1100,
  s1200: 1200,
  s1440: 1440,
  s2560: 2560
}

export const width = {
  w320: `${sizes.s320}px`,
  w360: `${sizes.s360}px`,
  w375: `${sizes.s375}px`,
  w450: `${sizes.s450}px`,
  w576: `${sizes.s576}px`,
  w710: `${sizes.s710}px`,
  w768: `${sizes.s768}px`,
  w1024: `${sizes.s1024}px`,
  w1100: `${sizes.s1100}px`,
  w1440: `${sizes.s1440}px`,
  w2560: `${sizes.s2560}px`,
  full: `100%`
}

export const devices = {
  d320: `(max-width: ${sizes.s320}px)`,
  d375: `(max-width: ${sizes.s375}px)`,
  d450: `(max-width: ${sizes.s450}px)`,
  d576: `(max-width: ${sizes.s576}px)`,
  d710: `(max-width: ${sizes.s710}px)`,
  d768: `(max-width: ${sizes.s768}px)`,
  d1024: `(max-width: ${sizes.s1024}px)`,
  d1100: `(max-width: ${sizes.s1100}px)`,
  d1200: `(max-width: ${sizes.s1200}px)`,
  d1440: `(max-width: ${sizes.s1440}px)`,
  d2560: `(max-width: ${sizes.s2560}px)`
}

export const fontWeight = {
  fw400: 400,
  fw500: 500,
  fw700: 700
}

export const fontSizes = {
  fs12: '12rem',
  fs14: '14rem',
  fs16: '16rem',
  fs18: '18rem',
  fs20: '20rem',
  fs24: '24rem',
  fs28: '28rem',
  fs33: '33rem',
  fs40: '40rem',
  fs48: '48rem',
  fs60: '60rem',
  fs65: '65rem'
}

export const lineHeights = {
  lh16: '16rem',
  lh18: '18rem',
  lh20: '20rem',
  lh24: '24rem',
  lh25: '25rem',
  lh26: '26rem',
  lh28: '28rem',
  lh32: '32rem',
  lh36: '36rem',
  lh40: '40rem',
  lh48: '48rem',
  lh56: '56rem',
  lh71: '71rem',
  lh77: '77rem'
}

export const gaps = {
  g4: '4rem',
  g8: '8rem',
  g10: '10rem',
  g12: '12rem',
  g16: '16rem',
  g20: '20rem',
  g24: '24rem',
  g28: '28rem',
  g32: '32rem',
  g36: '36rem',
  g40: '40rem'
}

export const zIndex = {
  highest: '99999',
  xl: '100',
  l: '10',
  m: '9',
  s: '8',
  xs: '7',
  outOfBound: '-1'
}

export const border = {
  radius: '10rem',
  width: '1rem'
}

export const delay = '.15s'

export const backgroundImg = 'none'
