import * as React from 'react'
import { useAuth } from 'react-oidc-context'
import { useNavigate } from 'react-router-dom'

import { ThemeList, useThemeUpdater } from '@/ui/contexts/themeContext'

import { RoutePath } from '@/router/enums'

import { useClientInfoSelector } from '@/store/clientInfoStore'

/**
 * Хук выхода из авторизации SSO
 */
const useRedirectTo = () => {
  const auth = useAuth()
  const navigate = useNavigate()

  const updateRedirect = useClientInfoSelector.use.setRedirect()

  const { setTheme } = useThemeUpdater()

  const [startRedirectTo, setStartRedirectTo] = React.useState<string | null>(null)

  React.useEffect(() => {
    setStartRedirectTo(null)
  }, [navigate])

  const redirectTo = React.useCallback(
    (redirectTo?: string | null, requestChangePassword = false): void => {
      const reset = () => {
        if (!auth.isAuthenticated) {
          sessionStorage.clear()
        }
      }

      if (requestChangePassword) {
        if (auth.isAuthenticated) {
          // TODO: пока в лк нет темной темы сбрасываем ее
          setTheme(ThemeList.DEFAULT)
        }

        if (auth) updateRedirect(redirectTo as string)
        navigate(`/${RoutePath.Auth}/${RoutePath.RequestChangePassword}`)
        return
      }

      const path = redirectTo ?? `/${RoutePath.UserOffice}/${RoutePath.MainPage}`

      setStartRedirectTo(path)

      reset()

      if (path.startsWith('http')) {
        // TODO: пока на бэке нет отдельной ручки для получения брэндинга на set-password, навигируемся без перезагрузки

        if (path.endsWith(RoutePath.SetPassword)) {
          const route = new URL(path)

          navigate(route.pathname)
        } else {
          window.location.href = path
        }
      } else {
        navigate(path)

        // TODO: пока в лк нет темной темы сбрасываем ее
        setTheme(ThemeList.DEFAULT)
      }
    },
    [auth, updateRedirect, navigate, setTheme]
  )

  return { redirectTo, startRedirectTo }
}

export default useRedirectTo
