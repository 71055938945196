import { css, CssThemed } from '@emotion/react'
import styled from '@emotion/styled'

export const visuallyHiddenStyles: CssThemed = () => css`
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  border: 0;
  padding: 0;
  white-space: nowrap;
  clip-path: inset(100%);
  clip: rect(0 0 0 0);
  overflow: hidden;

  :not(:focus):not(:active) {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    border: 0;
    padding: 0;
    white-space: nowrap;
    clip-path: inset(100%);
    clip: rect(0 0 0 0);
    overflow: hidden;
  }
`

export const VisuallyHidden = styled.span`
  ${visuallyHiddenStyles}

  :not(:focus):not(:active) {
    ${visuallyHiddenStyles}
  }
`
