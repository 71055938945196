import * as React from 'react'
import { useInterval } from 'react-use'

import { getTimerInMinutesAndSeconds } from '@/helpers/getTimerInMinutesAndSeconds'
import { timeDifferenceInSeconds } from '@/helpers/timeDifferenceInSeconds'

import { TIME_TO_UPDATE_ERROR_TIMER } from './constants'
import { TErrorToastify } from '@/types/errors'

import { UiErrorToastifyStyled } from './UiErrorToastify.style'

type PropsType = {
  errors: TErrorToastify[]
  seconds: number | null
  clearErrors: () => void
  closeTime: number | null
  setCloseTime: (timer: number | null) => void
}

const UiErrorToastify = ({ errors, seconds, closeTime, setCloseTime, clearErrors }: PropsType) => {
  const [timer, setTimer] = React.useState<string>('')
  const [countdown, setCountdown] = React.useState<number>(-1)
  const [dateMilliseconds, setDateMilliseconds] = React.useState<number | null>(null)

  const updateCountdown = React.useCallback(() => {
    if (dateMilliseconds) {
      const seconds = timeDifferenceInSeconds(dateMilliseconds)

      setTimer(getTimerInMinutesAndSeconds(seconds))
      setCountdown(seconds <= 0 ? -1 : seconds)
    }
  }, [dateMilliseconds])

  React.useEffect(() => {
    if (countdown <= 0) {
      clearErrors()
    }
  }, [clearErrors, countdown])

  React.useEffect(() => {
    if (seconds) {
      setCountdown(seconds)
      setDateMilliseconds(new Date().getTime() + seconds * 1000)
      updateCountdown()
    } else {
      setTimer('')
    }
  }, [seconds, updateCountdown])

  React.useEffect(() => {
    if (closeTime) {
      setTimeout(clearErrors, closeTime)
      setCloseTime(null)
    }
  }, [closeTime, setCloseTime, clearErrors])

  useInterval(updateCountdown, countdown > 0 && seconds ? TIME_TO_UPDATE_ERROR_TIMER : null)

  if (!errors.length) return null

  return (
    <div>
      <UiErrorToastifyStyled data-testid='error-notification'>
        {errors.filter(error => Boolean(error?.error_description)).map(error => error?.error_description).join(', ') + timer}
      </UiErrorToastifyStyled>
    </div>
  )
}

export default UiErrorToastify
