import styled from '@emotion/styled'

type IconWrapperType = {
  color?: string
}

export const IconWrapper = styled.div<IconWrapperType>`
  color: ${({ color }) => color};

  display: flex;
  align-items: center;
  justify-content: center;
`
