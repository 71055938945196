import { devtools } from 'zustand/middleware'
import { create } from 'zustand'

import { IS_REAL_INTEGRATION } from '@/constants'
import { StoreStatus } from '../types'
import { Branding } from '@/store/brandingStore/types'

import createSelectors from '../createSelectors'

type BrandingStore = {
  status: StoreStatus
  branding: Branding | null
  isNewRequestPath: boolean
}

type BrandingActions = {
  setStatus: (status: StoreStatus) => void
  setBranding: (branding: Branding) => void
  // TODO удалить после полного перехода на новую ручку branding
  setIsNewRequestPath: (isNewRequestPath: boolean) => void
}

const brandingStore = create<BrandingStore & BrandingActions>()(
  devtools(
    set => ({
      status: 'idle',
      branding: null,
      isNewRequestPath: false,
      setStatus: status => set({ status }),
      setBranding: branding => set({ branding, status: 'idle' }),
      setIsNewRequestPath: isNewRequestPath => set({ isNewRequestPath })
    }),
    { name: 'Branding', enabled: !IS_REAL_INTEGRATION }
  )
)

export const useBrandingSelector = createSelectors(brandingStore)
