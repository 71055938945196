import * as React from 'react'

import { useThemeUpdater } from '@/ui/contexts/themeContext'
import { CustomColor } from '@/ui/contexts/themeContext/enums'
import { colors } from '@/ui/styles/colors'
import { calcHexToRgba } from '@/ui/styles/utils'

import { CustomColors } from '@/store/brandingStore/types'

type UpdateCustomColors = (customColors: CustomColors | null) => void

export const useCustomColors = () => {
  const { setCustomColors } = useThemeUpdater()

  const updateCustomColors: UpdateCustomColors = React.useCallback(
    customColors => {
      if (customColors) {
        const {
          [CustomColor.Accent]: accent,
          [CustomColor.AccentHover]: accentHover,
          [CustomColor.AccentPressed]: accentPressed,
          [CustomColor.AccentButtonText]: accentButtonText,
          [CustomColor.Secondary]: secondary,
          [CustomColor.SecondaryHover]: secondaryHover,
          [CustomColor.SecondaryPressed]: secondaryPressed,
          [CustomColor.SecondaryButtonText]: secondaryButtonText,
          [CustomColor.Background]: appBackground
        } = customColors

        const inputBackOnFocus = accent ? calcHexToRgba(accent, 0.1) : 'trensparent'

        setCustomColors({
          accent: accent || colors.accent,
          accentHover: accentHover || colors.accentHover,
          accentPressed: accentPressed || colors.accentPressed,
          accentButtonText: accentButtonText || colors.accentButtonText,
          secondary: secondary || colors.secondary,
          secondaryHover: secondaryHover || colors.secondaryHover,
          secondaryPressed: secondaryPressed || colors.secondaryPressed,
          secondaryButtonText: secondaryButtonText || colors.secondaryButtonText,
          appBackground: appBackground || colors.appBackground,
          inputBackOnFocus
        })
      }
    },
    [setCustomColors]
  )

  return { updateCustomColors }
}
