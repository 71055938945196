import { keyframes } from '@emotion/react'
import styled from '@emotion/styled'

import { ThemeType } from '@/ui/contexts/themeContext/themeStyledTypes'

type LoaderWrapperProps = {
  unitsQuantity: number
  $size: number
  gap: number
}

type LoaderProps = {
  animationOrder: number
  $size: number
  color: keyof ThemeType['colors']
}

const ANIMATION_DELAY_SHIFT = 0.16

const pulse = keyframes`
  0%,
  70%,
  100% {
    width: 0%;
    height: 0%;
  }
  25% {
    width: 100%;
    height: 100%;
  }
`

export const LoaderWrapper = styled.span<LoaderWrapperProps>`
  align-self: center;
  width: 100%;
  height: 100%;

  display: grid;
  grid-template-columns: ${({ unitsQuantity, $size }) => `repeat(${unitsQuantity}, ${$size}rem)`};
  grid-template-rows: ${({ $size }) => `${$size}rem`};
  gap: ${({ gap }) => `${gap}rem`};

  place-content: center;
  place-items: center;
`

export const Loader = styled.span<LoaderProps>`
  display: block;
  border-radius: 50%;
  background-color: ${({ theme, color }) => theme.colors[color]};

  animation: ${pulse} 0.9s infinite ease-in-out both;
  animation-delay: ${({ animationOrder }) => `${animationOrder * ANIMATION_DELAY_SHIFT}s`};
`
