export enum OneTimePassword {
  FlashCall = 'flash-call',
  Code = 'code',
  Email = 'email',
  MobileIdCode = 'mobile-id-code'
}

export const enum PhraseVersion {
  Retry = 'Retry',
  Simple = 'Simple'
}

export const enum KeyHandleType {
  Backspace = 'Backspace',
  ArrowLeft = 'ArrowLeft',
  ArrowRight = 'ArrowRight',
  Delete = 'Delete'
}
