import * as React from 'react'
import { Navigate, RouteObject } from 'react-router-dom'

import { AccessType } from '@/types'
import { RoutePath } from '@/router/enums'

import SooLayoutStoreController from '../store/sooLayoutStore/sooLayoutStoreController'
import CompletionLayout from '@/layouts/CompletionLayout/CompletionLayout'
import SsoLayout from '@/layouts/SsoLayout'

const AccountSelect = React.lazy(() => import('@/pages/Sso/AccountSelect'))
const SsoAuth = React.lazy(() => import('@/pages/Sso/Auth'))

const YandexIDAuthHandler = React.lazy(
  () => import('@/pages/Sso/Auth/components/SocialButtonsList/components/YandexIDAuthHandler')
)
const VkIDAuthHandler = React.lazy(
  () => import('@/pages/Sso/Auth/components/SocialButtonsList/components/VkIDAuthHandler')
)
const SocialAuth = React.lazy(() => import('@/pages/Sso/SocialAuth'))
const Qr = React.lazy(() => import('@/pages/Sso/QR'))
const QrConfirm = React.lazy(() => import('@/pages/Sso/QrConfirm'))
const QrRefresh = React.lazy(() => import('@/pages/Sso/QrRefresh'))
const LoginAndAccountRecoveryPush = React.lazy(() => import('@/pages/Sso/MobileId/LoginAndAccountRecoveryPush'))
const LoginAndAccountRecoveryMobileId = React.lazy(
  () => import('@/pages/Sso/MobileId/LoginAndAccountRecoveryMobileId/LoginAndAccountRecoveryMobileId')
)
const Details = React.lazy(() => import('@/pages/Sso/Completion/Details'))
const Email = React.lazy(() => import('@/pages/Sso/Completion/Email'))
const EmailOtp = React.lazy(() => import('@/pages/Sso/OTP/EmailOTP/EmailOTP'))
const EmailPassword = React.lazy(() => import('@/pages/Sso/EmailPassword/EmailPassword'))
const Consent = React.lazy(() => import('@/pages/Sso/Completion/Consent'))
const OtpCode = React.lazy(() => import('@/pages/Sso/OTP'))
const FlashCall = React.lazy(() => import('@/pages/Sso/FlashCall'))
const Password = React.lazy(() => import('@/pages/Sso/Password'))
const ChangePassword = React.lazy(() => import('@/pages/Sso/ChangePassword'))
const RegistrationFlashCall = React.lazy(() => import('@/pages/Sso/RegistrationFlashCall'))
const RegistrationOTP = React.lazy(() => import('@/pages/Sso/RegistrationOTP'))
const RegistrationPush = React.lazy(() => import('@/pages/Sso/MobileId/RegistrationPush'))
const RegistrationMobileId = React.lazy(() => import('@/pages/Sso/MobileId/RegistrationMobileId'))
const SetPassword = React.lazy(() => import('@/pages/Sso/SetPassword'))
const RequestChangePassword = React.lazy(() => import('@/pages/Sso/RequestChangePassword'))
const Removal = React.lazy(() => import('@/pages/Sso/Removal'))
const Blocked = React.lazy(() => import('@/pages/Sso/Blocked'))
const RecoveryFlashCall = React.lazy(() => import('@/pages/Sso/RecoveryFlashCall/RecoveryFlashCall'))
const RecoveryOTP = React.lazy(() => import('@/pages/Sso/RecoveryOTP'))
const RecoveryEmailOTP = React.lazy(() => import('@/pages/Sso/RecoveryEmailOTP/RecoveryEmailOTP'))
const PasswordRecoveryPush = React.lazy(() => import('@/pages/Sso/MobileId/PasswordRecoveryPush'))
const PasswordRecoveryMobileId = React.lazy(() => import('@/pages/Sso/MobileId/PasswordRecoveryMobileId'))
const SocialConfirm = React.lazy(() => import('@/pages/Sso/SocialConfirm'))
const Lost = React.lazy(() => import('@/pages/Sso/Lost'))

export const ssoRoutes = (access: AccessType): RouteObject[] => {
  const { isAuthenticated, state } = access

  const isMobileIdAuth: boolean = (state as { isMobileIdAuth: boolean })?.isMobileIdAuth

  return [
    {
      element: <SooLayoutStoreController />,
      children: [
        {
          element: <SsoLayout />,
          path: RoutePath.Auth,
          children: [
            {
              element: <SsoAuth />,
              index: true
            },
            {
              path: RoutePath.AccountSelect,
              element: <AccountSelect />
            },
            {
              path: RoutePath.Lost,
              element: <Lost />
            },
            {
              path: RoutePath.Qr,
              children: [
                {
                  element: <Qr />,
                  index: true
                },

                {
                  element: <QrConfirm />,
                  path: RoutePath.Confirm
                },
                {
                  element: <QrRefresh />,
                  path: RoutePath.QrRefresh
                }
              ]
            },
            {
              element: isMobileIdAuth ? <LoginAndAccountRecoveryPush /> : <Navigate to='/' />,
              path: RoutePath.Push
            },
            {
              element: isMobileIdAuth ? <LoginAndAccountRecoveryMobileId /> : <Navigate to='/' />,
              path: RoutePath.MobileId
            },

            {
              element: <CompletionLayout />,
              children: [
                {
                  path: RoutePath.Completion,
                  children: [
                    {
                      element: <Details />,
                      index: true
                    },
                    {
                      element: <Email />,
                      path: RoutePath.Email
                    }
                  ]
                },
                {
                  element: <Consent />,
                  path: RoutePath.Consent
                }
              ]
            },
            {
              element: <Removal />,
              path: RoutePath.Removal
            },
            {
              element: <OtpCode />,
              path: RoutePath.OtpCode
            },
            {
              element: <EmailOtp />,
              path: RoutePath.EmailOtp
            },
            {
              element: <FlashCall />,
              path: RoutePath.FlashCall
            },
            {
              element: <Password />,
              path: RoutePath.Password
            },
            {
              element: <EmailPassword />,
              path: RoutePath.EmailPassword
            },
            {
              element: isAuthenticated ? <ChangePassword /> : <Navigate to={`/${RoutePath.Auth}`} />,
              path: RoutePath.ChangePassword
            },
            {
              element: <RecoveryOTP />,
              path: RoutePath.RecoveryOTP
            },
            {
              element: <RecoveryEmailOTP />,
              path: RoutePath.RecoveryEmailOtp
            },
            {
              element: <RecoveryFlashCall />,
              path: RoutePath.RecoveryFLashCall
            },
            {
              element: isMobileIdAuth ? <PasswordRecoveryPush /> : <Navigate to='/' />,
              path: RoutePath.RecoveryPush
            },
            {
              element: isMobileIdAuth ? <PasswordRecoveryMobileId /> : <Navigate to='/' />,
              path: RoutePath.RecoveryMobileId
            },
            {
              element: <Blocked />,
              path: RoutePath.Blocked
            },
            {
              element: <RegistrationOTP />,
              path: RoutePath.RegistrationOTP
            },
            {
              element: <RegistrationFlashCall />,
              path: RoutePath.RegistrationFlashCall
            },
            {
              element: isMobileIdAuth ? <RegistrationPush /> : <Navigate to='/' />,
              path: RoutePath.RegistrationPush
            },
            {
              element: isMobileIdAuth ? <RegistrationMobileId /> : <Navigate to='/' />,
              path: RoutePath.RegistrationMobileId
            },
            {
              element: <SetPassword />,
              path: RoutePath.SetPassword
            },
            {
              element: <RequestChangePassword />,
              path: RoutePath.RequestChangePassword
            },
            {
              element: <YandexIDAuthHandler />,
              path: RoutePath.Yandex
            },
            {
              element: <VkIDAuthHandler />,
              path: RoutePath.Vk
            },
            {
              path: RoutePath.Social,
              children: [
                {
                  element: <SocialAuth />,
                  index: true
                },
                {
                  element: <SocialConfirm />,
                  path: RoutePath.Confirm
                }
              ]
            }
          ]
        }
      ]
    }
  ]
}
