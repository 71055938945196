import { useQuery } from '@tanstack/react-query'
import { useAuth } from 'react-oidc-context'

import { apiIdp } from '@/api/apiService'
import onceRetryOn401 from './utils/onceRetryOn401'

import { TClientInfo } from '@/types/auth'
import { TGidClientQuery } from '@/types/requestDataTypes'

import { useClientInfoSelector } from '@/store/clientInfoStore'

export const getClientData = async (): Promise<TClientInfo> => {
  const { data } = await apiIdp.get<TClientInfo, TGidClientQuery>('flow/login')
  return data
}

const useClientData = () => {
  const auth = useAuth()
  const status = useClientInfoSelector.use.status()
  const setStatus = useClientInfoSelector.use.setStatus()
  const setClientInfo = useClientInfoSelector.use.setClientInfo()

  return useQuery({
    queryKey: ['clientInfo'],
    queryFn: getClientData,
    enabled: status === 'needInit' && !auth.isLoading && !auth.isAuthenticated,
    onSuccess: setClientInfo,
    onError: () => setStatus('error'),
    retry: onceRetryOn401
  })
}

export default useClientData
