export const TIME_TO_UPDATE_ERROR_TIMER = 200

export const initialToastifyState = {
  errors: [],
  seconds: 0,
  secondsOTP: {},
  closeTime: null
}

export const EMAIL_DESCRIPTION_ERROR = 'Регистрация возможна только по номеру телефона'
