import { MenuListType } from '@/components/Sidebar/components/AppMenu/types'

import { MenusNames, RouteName, RoutePath } from '@/router/enums'

export const menuList: MenuListType[] = [
  {
    linkName: RouteName.MainPage,
    linkTitle: MenusNames.MainPage,
    linkTo: RoutePath.MainPage,
    linkIconName: 'home'
  },
  {
    linkName: RouteName.Services,
    linkTitle: MenusNames.Services,
    linkTo: RoutePath.Services,
    linkIconName: 'services'
  },
  {
    linkName: RouteName.Profile,
    linkTitle: MenusNames.Profile,
    linkTo: RoutePath.Profile,
    linkIconName: 'user'
  },
  {
    linkName: RouteName.Settings,
    linkTitle: MenusNames.Settings,
    linkTo: RoutePath.Settings,
    linkIconName: 'settings'
  }
]
