import { Navigate } from 'react-router-dom'

import { RoutePath } from '@/router/enums'

type PropsType = {
  caller: string
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const RestartSessionRedirect = ({ caller }: PropsType) => {
  // console.log(`🚀 redirect caller:`, caller)
  return <Navigate to={`/${RoutePath.UserOffice}/${RoutePath.MainPage}`} />
}

export default RestartSessionRedirect
