import { createJSONStorage, devtools, persist } from 'zustand/middleware'
import { create } from 'zustand'

import { IS_REAL_INTEGRATION } from '@/constants'
import { AuthenticationFlow, TPrincipalInfo } from '@/types/auth'
import { AuthFactors } from '@/enums/auth'

import createSelectors from '../createSelectors'

interface IPrincipalStore {
  principalInfo: TPrincipalInfo | null
  updatePrincipalInfo: (principalInfo: TPrincipalInfo | null) => void
  resetPrincipalInfo: () => void
  isDirtyPrincipalInfo: boolean
  updateIsDirtyPrincipalInfo: () => void

  isPhoneTosAccepted: boolean
  setIsPhoneTosAccepted: (setIsPhoneTosAccepted: boolean) => void
  toggleIsPhoneTosAccepted: () => void
  isPhoneTargetingAccepted: boolean
  toggleIsPhoneTargetingAccepted: () => void

  isEmailTosAccepted: boolean
  toggleIsEmailTosAccepted: () => void
  isEmailTargetingAccepted: boolean
  toggleIsEmailTargetingAccepted: () => void

  resetAllPolicyCheckboxes: () => void

  updateAuthFactors: (authFactors: AuthenticationFlow) => void
}

interface IPhoneInfo {
  phone: string
  updatePhone: (value: string) => void
  removePhone: () => void
}

interface IHheWindow {
  hheWindow: WindowProxy | null
  checkHHE: (currentFactor: AuthFactors, hhe_url?: string) => Promise<void>
}

interface IEmail {
  email: string | null
  updateEmail: (value: string | null) => void
}

export const principalStore = create<IPrincipalStore & IPhoneInfo & IHheWindow & IEmail>()(
  devtools(
    persist(
      set => ({
        principalInfo: null,
        updatePrincipalInfo: (principalInfo: TPrincipalInfo | null) => {
          set(() => ({ principalInfo }))
        },
        resetPrincipalInfo: () =>
          set(() => ({
            principalInfo: null,
            isPhoneTosAccepted: false,
            isPhoneTargetingAccepted: false,
            isEmailTosAccepted: false,
            isEmailTargetingAccepted: false
          })),
        isDirtyPrincipalInfo: false,
        updateIsDirtyPrincipalInfo: () => set(() => ({ isDirtyPrincipalInfo: true })),

        email: null,
        updateEmail: (email: string | null) => set({ email }),

        phone: '+7',
        updatePhone: phone => set(() => ({ phone })),
        removePhone: () => set(() => ({ phone: '' })),

        isPhoneTosAccepted: false,
        setIsPhoneTosAccepted: (isPhoneTosAccepted: boolean) => set({ isPhoneTosAccepted }),
        toggleIsPhoneTosAccepted: () => set(state => ({ isPhoneTosAccepted: !state.isPhoneTosAccepted })),
        isPhoneTargetingAccepted: false,
        toggleIsPhoneTargetingAccepted: () =>
          set(state => ({ isPhoneTargetingAccepted: !state.isPhoneTargetingAccepted })),

        isEmailTosAccepted: false,
        toggleIsEmailTosAccepted: () => set(state => ({ isEmailTosAccepted: !state.isEmailTosAccepted })),
        isEmailTargetingAccepted: false,
        toggleIsEmailTargetingAccepted: () =>
          set(state => ({ isEmailTargetingAccepted: !state.isEmailTargetingAccepted })),

        resetAllPolicyCheckboxes: () =>
          set({
            isPhoneTosAccepted: false,
            isPhoneTargetingAccepted: false,
            isEmailTosAccepted: false,
            isEmailTargetingAccepted: false
          }),

        hheWindow: null,
        checkHHE: async (currentFactor: AuthFactors, hhe_url?: string) => {
          // TODO add check for hhe_url
          return Promise.resolve()
        },
        updateHheWindow: (hheWindow: WindowProxy | null) => {
          set(() => ({ hheWindow }))
        },

        updateAuthFactors: (authentication_flow: AuthenticationFlow) =>
          set(state => ({
            principalInfo: { ...state.principalInfo, authentication_flow } as TPrincipalInfo
          }))
      }),
      {
        name: 'phone',
        storage: createJSONStorage(() => localStorage, {}),
        partialize: state => ({
          phone: state.phone,
          email: state.email
        })
      }
    ),
    { name: 'Principal', enabled: !IS_REAL_INTEGRATION }
  )
)

export const usePrincipalStoreSelector = createSelectors(principalStore)
