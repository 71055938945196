import { EnvBooleanValue } from '@/enums/auth'

export const IS_GOYA_QUERIES_ENABLED = Boolean(import.meta.env.VITE_APP_GOYA_API_URL)

export const DEV_GOYA_QUERY_PARAMS = String(import.meta.env.VITE_APP_GOYA_DEV).includes(EnvBooleanValue.True)
  ? {
      cid: '1000000000000001',
      session_id: '10000000000000_00000000000_00001',
      appmetrica_device_id: '10000000000000000001',
      product_name: 'dev-gazprom-id',
      some_field: 'some-data'
    }
  : undefined

export const GOYA_COOKIE_NAME_WITH_EQUAL_SIGN = 'goya='

export const ALLOWED_GOYA_COOKIE_FIELDS = ['cid', 'session_id', 'appmetrica_device_id', 'product_name']

export const CONST_FIELD_EVENT_GROUP = 'event'

export const CONST_FIELD_EVENT_ELEMENT_LOCATION = 'webview'

export const CONST_FIELD_TOUCH_POINT = 'web'

export const PAGE_VIEW_EVENT_NAME = 'pageview'
