import styled from '@emotion/styled'
import { Link } from 'react-router-dom'

import { ThemeType } from '@/ui/contexts/themeContext/themeStyledTypes'
import { css } from '@/ui/styles'

const { devices, fontSizes, fontWeight, gaps, lineHeights, width } = css

type LinksType = {
  ml?: string
  mr?: string
  mt?: string
  mb?: string
  fontWeight?: keyof ThemeType['fontWeight']
}

type FlexBoxType = {
  direction?: 'row' | 'row-reverse' | 'column' | 'column-reverse'
  align?: 'flex-start' | 'flex-end' | 'center' | 'baseline' | 'stretch'
  justify?:
    | 'start'
    | 'flex-start'
    | 'center'
    | 'end'
    | 'flex-end'
    | 'space-between'
    | 'space-around'
    | 'space-evenly'
    | 'stretch'
    | 'left'
    | 'right'
    | 'baseline'
  grow?: string
  shrink?: string
  basis?: string
  wrap?: 'wrap' | 'nowrap' | 'wrap-reverse'
}

type TextType = {
  color?: keyof ThemeType['colors']
  fontWeight?: keyof ThemeType['fontWeight']
  cursor?: 'pointer' | 'normal'
  fontSize?: keyof ThemeType['fontSizes']
  textAlign?: 'center' | 'left' | 'right'
  pointerEvents?: 'none' | 'auto'
}

type ContainerType = {
  marginTop?: keyof ThemeType['gaps']
  marginBottom?: keyof ThemeType['gaps']
  maxW?: keyof ThemeType['width']
  width?: keyof ThemeType['width']
}

export const Header = styled.div`
  margin-bottom: ${gaps.g24};
`

export const HeaderTitle = styled.h2`
  font-weight: ${fontWeight.fw500};
  font-size: ${fontSizes.fs24};
  line-height: ${lineHeights.lh32};
  text-align: center;
  color: ${({ theme }) => theme.colors.dark};
`

export const SubHeader = styled.p`
  line-height: ${lineHeights.lh24};
  margin-top: ${gaps.g8};
  text-align: center;
`

export const Text = styled.p<TextType>`
  line-height: ${lineHeights.lh20};
  font-size: ${({ fontSize }) => (fontSize ? fontSizes[fontSize] : fontSizes.fs14)};
  text-align: ${({ textAlign }) => textAlign ?? 'center'};
  color: ${({ theme, color = 'dark' }) => theme.colors[color]};
  font-weight: ${({ fontWeight = 'fw500' }) => css.fontWeight[fontWeight]};
  cursor: ${({ cursor = 'normal' }) => cursor};
  pointer-events: ${({ pointerEvents }) => pointerEvents ?? 'auto'};
`

export const Links = styled(Link)<LinksType>`
  color: ${({ theme }) => theme.colors.accent};
  font-weight: ${({ fontWeight }) => (fontWeight ? css.fontWeight[fontWeight] : css.fontWeight.fw700)};
  ${({ mt }) => mt && `margin-top: ${mt}`};
  ${({ mr }) => mr && `margin-right: ${mr}`};
  ${({ mb }) => mb && `margin-bottom: ${mb}`};
  ${({ ml }) => ml && `margin-left: ${ml}`};

  &,
  &:visited {
    color: ${({ theme }) => theme.colors.accent};
  }

  &:hover {
    color: ${({ theme }) => theme.colors.accentHover};
  }

  &:active {
    color: ${({ theme }) => theme.colors.accentPressed};
  }
`

export const FlexBox = styled.div<FlexBoxType>`
  display: flex;
  justify-content: ${({ justify }) => justify ?? 'center'};
  align-items: ${({ align }) => align ?? 'center'};
  flex-direction: ${({ direction }) => direction ?? 'row'};
  flex-basis: ${({ basis }) => basis ?? 'auto'};
  flex-wrap: ${({ wrap }) => wrap ?? 'nowrap'};
  flex-grow: ${({ grow }) => grow ?? 0};
  flex-shrink: ${({ shrink }) => shrink ?? 1};
`

export const Container = styled.div<ContainerType>`
  margin-top: ${({ marginTop }) => (marginTop ? gaps[marginTop] : '0')};
  margin-bottom: ${({ marginBottom }) => (marginBottom ? gaps[marginBottom] : '0')};
  ${({ maxW }) => maxW ? 'max-width: ' + width[maxW] + ';' : ''}
  ${({ width: bWidth }) => bWidth ? 'width: ' + width[bWidth] + ';' : ''}
`

export const WrapperButton = styled.div`
  width: 168rem;

  @media ${devices.d768} {
    width: 100%;
    margin-bottom: ${gaps.g16};

    &:last-of-type {
      margin-bottom: unset;
    }
  }
`

export const FlexButtons = styled.div`
  display: flex;
  justify-content: space-between;
  gap: ${gaps.g24};

  @media ${devices.d576} {
    flex-direction: column-reverse;
    gap: ${gaps.g16};
  }
`

export const AuthText = styled.p<TextType>`
  line-height: ${lineHeights.lh20};
  font-size: ${({ fontSize }) => (fontSize ? fontSizes[fontSize] : fontSizes.fs14)};
  text-align: ${({ textAlign }) => textAlign ?? 'center'};
  color: ${({ theme }) => theme.colors.dark60};
  font-weight: ${({ fontWeight = 'fw500' }) => css.fontWeight[fontWeight]};
  cursor: ${({ cursor = 'normal' }) => cursor};
`

export const Form = styled.form`
  margin-bottom: ${gaps.g24};
`

export const EmailLink = styled.div`
  width: 100%;
  margin-bottom: 50rem;
`

export const BlockedImg = styled.img`
  width: 160rem;
`

export const WrapperImg = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 8rem;
`

export const WrapperCheckbox = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${gaps.g24};
  margin-top: ${gaps.g24};
`
