import { GoyaEventConfig } from './types'
import { EventAction, EventCategory, EventContext, EventLabel } from './enums'

// confluence code_id:36
export const footerLinkEventConfig: GoyaEventConfig = {
  event_category: EventCategory.Info,
  event_action: EventAction.ElementClick,
  event_label: EventLabel.Link
}

// confluence code_id:2
// confluence code_id:3
export const nextButtonEventConfig: GoyaEventConfig = {
  event_category: EventCategory.Auth,
  event_action: EventAction.ButtonClick,
  event_label: EventLabel.Dalee
}

// confluence code_id:4
export const authPushApproved: GoyaEventConfig = {
  event_category: EventCategory.Auth,
  event_action: EventAction.ElementClick,
  event_label: EventLabel.PodtverditeVhod,
  event_context: EventContext.Prinyat
}

const authPushSharedFields: GoyaEventConfig = {
  event_category: EventCategory.Auth,
  event_action: EventAction.ButtonClick,
  event_label: EventLabel.KakProdolzhim
}

// confluence code_id:5
export const authPushModalGetSms: GoyaEventConfig = {
  ...authPushSharedFields,
  event_context: EventContext.PoluchitSms
}

// confluence code_id:6
export const authPushModalTryAgain: GoyaEventConfig = {
  ...authPushSharedFields,
  event_context: EventContext.NachatSnachala
}

// confluence code_id:20
export const regPushApproved: GoyaEventConfig = {
  event_category: EventCategory.Reg,
  event_action: EventAction.ElementClick,
  event_label: EventLabel.PodtverditeVhod,
  event_context: EventContext.Prinyat
}

const regPushSharedFields: GoyaEventConfig = {
  event_category: EventCategory.Reg,
  event_action: EventAction.ButtonClick,
  event_label: EventLabel.KakProdolzhim
}

// confluence code_id:21
export const regPushModalGetSms: GoyaEventConfig = {
  ...regPushSharedFields,
  event_context: EventContext.PoluchitSms
}

// confluence code_id:22
export const regPushModalTryAgain: GoyaEventConfig = {
  ...regPushSharedFields,
  event_context: EventContext.NachatSnachala
}

// confluence code_id:7
export const authOtpSuccess: GoyaEventConfig = {
  event_category: EventCategory.Auth,
  event_action: EventAction.Success,
  event_label: EventLabel.VvodKodaIzSMS
}

// confluence code_id:8
export const authOtpReject: GoyaEventConfig = {
  event_category: EventCategory.Auth,
  event_action: EventAction.Rejected,
  event_label: EventLabel.VvodKodaIzSMS,
  event_context: EventContext.NevernyiKod
}

// confluence code_id:9
export const authGetCodeAgain: GoyaEventConfig = {
  event_category: EventCategory.Auth,
  event_action: EventAction.ElementClick,
  event_label: EventLabel.VvodKodaIzSMS,
  event_context: EventContext.PoluchitKodPovtorno
}

// confluence code_id:10
export const authByPass: GoyaEventConfig = {
  event_category: EventCategory.Auth,
  event_action: EventAction.ElementClick,
  event_label: EventLabel.VvodKodaIzSMS,
  event_context: EventContext.VoitiPoParolu
}

// confluence code_id:11
export const authOtpThrottling: GoyaEventConfig = {
  event_category: EventCategory.Auth,
  event_action: EventAction.Rejected,
  event_label: EventLabel.VvodKodaIzSMS,
  event_context: EventContext.Limit
}

// confluence code_id:23
export const regOtpSuccess: GoyaEventConfig = {
  event_category: EventCategory.Reg,
  event_action: EventAction.Success,
  event_label: EventLabel.VvodKodaIzSMS
}

// confluence code_id:24
export const regOtpReject: GoyaEventConfig = {
  event_category: EventCategory.Reg,
  event_action: EventAction.Rejected,
  event_label: EventLabel.VvodKodaIzSMS,
  event_context: EventContext.NevernyiKod
}

// confluence code_id:26
export const regGetCodeAgain: GoyaEventConfig = {
  event_category: EventCategory.Reg,
  event_action: EventAction.ElementClick,
  event_label: EventLabel.VvodKodaIzSMS,
  event_context: EventContext.PoluchitKodPovtorno
}

// confluence code_id:25
export const regOtpThrottling: GoyaEventConfig = {
  event_category: EventCategory.Reg,
  event_action: EventAction.Rejected,
  event_label: EventLabel.VvodKodaIzSMS,
  event_context: EventContext.Limit
}

// confluence code_id:12
export const recoverAccount: GoyaEventConfig = {
  event_category: EventCategory.Auth,
  event_action: EventAction.ButtonClick,
  event_label: EventLabel.AkkauntZablokirovan,
  event_context: EventContext.VernutAccount
}

const captchaSharedFields: GoyaEventConfig = {
  event_category: EventCategory.Auth,
  event_action: EventAction.ButtonClick,
  event_label: EventLabel.Capcha
}

// confluence code_id:13
export const captchaRefresh: GoyaEventConfig = {
  ...captchaSharedFields,
  event_action: EventAction.ElementClick,
  event_context: EventContext.Obnovit
}

// confluence code_id:14
export const captchaNext: GoyaEventConfig = {
  ...captchaSharedFields,
  event_context: EventContext.Dalee
}

// confluence code_id:15
export const captchaCancel: GoyaEventConfig = {
  ...captchaSharedFields,
  event_context: EventContext.Otmenit
}

// confluence code_id:16
export const captchaWrong: GoyaEventConfig = {
  ...captchaSharedFields,
  event_action: EventAction.Rejected,
  event_context: EventContext.NevernyiKod
}

const byPassEnterSharedFields: GoyaEventConfig = {
  event_category: EventCategory.Auth,
  event_action: EventAction.ElementClick,
  event_label: EventLabel.VoitiPoParolu
}

// confluence code_id:17
export const byPassEnterBtn: GoyaEventConfig = {
  ...byPassEnterSharedFields,
  event_action: EventAction.ButtonClick,
  event_context: EventContext.Voiti
}

// confluence code_id:18
export const forgotsPass: GoyaEventConfig = {
  ...byPassEnterSharedFields,
  event_context: EventContext.NePomnuParol
}

// confluence code_id:19
export const enterByOtp: GoyaEventConfig = {
  ...byPassEnterSharedFields,
  event_context: EventContext.VoitiSPodtverzhdeniemNomera
}

// confluence code_id:27
export const setPasswordSave: GoyaEventConfig = {
  event_category: EventCategory.Auth,
  event_action: EventAction.ButtonClick,
  event_label: EventLabel.UstanovkaParolya,
  event_context: EventContext.Sohranit
}

// confluence code_id:28
export const passMismatch: GoyaEventConfig = {
  event_category: EventCategory.Auth,
  event_action: EventAction.Rejected,
  event_label: EventLabel.UstanovkaParolya,
  event_context: EventContext.ParoliNeSovpadaut
}

// confluence code_id:29
export const passMisrequirement: GoyaEventConfig = {
  event_category: EventCategory.Auth,
  event_action: EventAction.Rejected,
  event_label: EventLabel.UstanovkaParolya,
  event_context: EventContext.TrebovaniyaKParolu
}

// confluence code_id:30
export const passTimerModalShow: GoyaEventConfig = {
  event_category: EventCategory.Auth,
  event_action: EventAction.Rejected,
  event_label: EventLabel.UstanovkaParolya,
  event_context: EventContext.VremiaIsteklo
}

// confluence code_id:31
export const passTimerModalButton: GoyaEventConfig = {
  event_category: EventCategory.Auth,
  event_action: EventAction.ButtonClick,
  event_label: EventLabel.UstanovkaParolya,
  event_context: EventContext.VremiaIsteklo
}

// confluence code_id:32
export const continueCompletionInfo: GoyaEventConfig = {
  event_category: EventCategory.Info,
  event_action: EventAction.ButtonClick,
  event_label: EventLabel.Dozapolnenie,
  event_context: EventContext.Prodolzhit
}

// confluence code_id:33
export const invalidCompletionInfo: GoyaEventConfig = {
  event_category: EventCategory.Info,
  event_action: EventAction.Rejected,
  event_label: EventLabel.Dozapolnenie
}

// confluence code_id:44
export const completionEmailInputContinue: GoyaEventConfig = {
  event_category: EventCategory.Info,
  event_action: EventAction.ButtonClick,
  event_label: EventLabel.Email,
  event_context: EventContext.Prodolzhit
}

// confluence code_id:34
export const completionEmailConfirmGetOtpAgain: GoyaEventConfig = {
  event_category: EventCategory.Info,
  event_action: EventAction.ButtonClick,
  event_label: EventLabel.Email,
  event_context: EventContext.PoluchitKodPovtorno
}

// confluence code_id:35
export const completionEmailConfirmSkip: GoyaEventConfig = {
  event_category: EventCategory.Info,
  event_action: EventAction.ButtonClick,
  event_label: EventLabel.Email,
  event_context: EventContext.Propustit
}

// confluence code_id:37
export const authEmailOtpSuccess: GoyaEventConfig = {
  event_category: EventCategory.Auth,
  event_action: EventAction.Success,
  event_label: EventLabel.VvodKodaIzEmail
}

// confluence code_id:38
export const authEmailOtpReject: GoyaEventConfig = {
  event_category: EventCategory.Auth,
  event_action: EventAction.Rejected,
  event_label: EventLabel.VvodKodaIzEmail,
  event_context: EventContext.NevernyiKod
}

// confluence code_id:39
export const authEmailGetCodeAgain: GoyaEventConfig = {
  event_category: EventCategory.Auth,
  event_action: EventAction.ElementClick,
  event_label: EventLabel.VvodKodaIzEmail,
  event_context: EventContext.PoluchitKodPovtorno
}

// confluence code_id:40
export const authEmailOtpThrottling: GoyaEventConfig = {
  event_category: EventCategory.Auth,
  event_action: EventAction.Rejected,
  event_label: EventLabel.VvodKodaIzEmail,
  event_context: EventContext.Limit
}

// confluence code_id:41
export const byEmailPassEnterBtn: GoyaEventConfig = {
  event_category: EventCategory.Auth,
  event_action: EventAction.ButtonClick,
  event_label: EventLabel.VoitiPoEmailParolu,
  event_context: EventContext.Voiti
}

// confluence code_id:42
export const forgotsEmailPass: GoyaEventConfig = {
  event_category: EventCategory.Auth,
  event_action: EventAction.ElementClick,
  event_label: EventLabel.VoitiPoEmailParolu,
  event_context: EventContext.NePomnuParol
}

// confluence code_id:43
export const enterByEmailOtp: GoyaEventConfig = {
  event_category: EventCategory.Auth,
  event_action: EventAction.ElementClick,
  event_label: EventLabel.VoitiPoEmailParolu,
  event_context: EventContext.VoitiSPodtverzhdeniemNomera
}

// confluence code_id:47
export const continueWithAccount: GoyaEventConfig = {
  event_category: EventCategory.Auth,
  event_action: EventAction.ButtonClick,
  event_label: EventLabel.VyborAkkaunta,
  event_context: EventContext.ProdolzhitSNomerom
}

// confluence code_id:48
export const authWithAnotherMethod: GoyaEventConfig = {
  event_category: EventCategory.Auth,
  event_action: EventAction.ButtonClick,
  event_label: EventLabel.VyborAkkaunta,
  event_context: EventContext.VoitiDrugimSposobom
}

// confluence code_id:49
export const reloadOnLostPage: GoyaEventConfig = {
  event_category: EventCategory.AuthReg,
  event_action: EventAction.ButtonClick,
  event_label: EventLabel.MyVasPoteryali,
  event_context: EventContext.YaZdes
}
