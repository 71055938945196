import React from 'react'
import { useAuth } from 'react-oidc-context'
import { useLocation, useRoutes } from 'react-router-dom'
import { useLocalStorage } from 'react-use'

import { UiErrorToastifyProvider } from '@/ui/components/UiErrorToastify'
import UiLoader from '@/ui/components/UiLoader'
import { ThemeProvider } from '@/ui/contexts/themeContext'

import { ApiClient } from '@/api/apiService'
import ContextWrapper from '@/contexts'
import { usePlaywrightTestStatus } from './hooks/usePlaywrightTestStatus'
import { useAutoTesterStatus } from '@/hooks/useAutoTesterStatus'

import { IGNORE_OIDC_ERROR_PAGES, LOADER_GAP, LOADER_SIZE } from '@/constants'

import { AppThemeWrapper, FlexBoxCentered } from './App.style'

import StoreControllers from './store/StoreControllers'
import routes from '@/router'

const oidcIsOn = true

const App = () => {
  const auth = useAuth()
  const location = useLocation()
  const isAutoTester = useAutoTesterStatus()
  const isAutoTesterWithoutLocal = useAutoTesterStatus(false)
  const isPlayWrightTest = usePlaywrightTestStatus()

  const [token] = useLocalStorage<string>('token')

  const element = useRoutes(
    routes(
      {
        isAuthenticated: auth.isAuthenticated || isAutoTester || isPlayWrightTest,
        state: (location?.state as Record<string, string>) ?? undefined
      },
      isAutoTesterWithoutLocal || isPlayWrightTest
    )
  )

  React.useEffect(() => {
    if (isAutoTester && token) {
      ApiClient.setToken(token)
    } else {
      ApiClient.setToken(auth.user?.access_token ?? null)
    }
  }, [isAutoTester, token, auth])

  const view = () => {
    if (oidcIsOn && auth.isLoading) {
      return (
        <FlexBoxCentered>
          <UiLoader size={LOADER_SIZE.L} gap={LOADER_GAP.L} />
        </FlexBoxCentered>
      )
    }

    if (oidcIsOn && auth.error && !IGNORE_OIDC_ERROR_PAGES.includes(window.location.pathname)) {
      return <div>Oops... {auth.error?.message}</div>
    }

    return element
  }

  return (
    <ThemeProvider>
      <UiErrorToastifyProvider>
        <ContextWrapper>
          <StoreControllers>
            <AppThemeWrapper>{view()}</AppThemeWrapper>
          </StoreControllers>
        </ContextWrapper>
      </UiErrorToastifyProvider>
    </ThemeProvider>
  )
}

export default App
