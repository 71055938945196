import UiIcon from '@/ui/components/UiIcon'
import UiLoader from '@/ui/components/UiLoader'
import UiLogo from '@/ui/components/UiLogo'
import { VisuallyHidden } from '@/ui/styles/helpers'

import { phoneFormat } from '@/helpers/phoneHelpers'
import { usePrimaryPhone } from '@/hooks/usePrimaryPhone'

import { LOADER_GAP, LOADER_SIZE } from '@/constants'

import * as S from './SidebarHeader.style'

type PropsType = {
  onLogout: () => void
}

const SidebarHeader = ({ onLogout }: PropsType) => {
  const { isLoading, primaryPhone, error } = usePrimaryPhone()

  const primaryFormattedPhone = phoneFormat(primaryPhone) || 'Номер не задан'

  const phone = error ? <p>{error}</p> : primaryFormattedPhone

  return (
    <S.SidebarHeader>
      <UiLogo width='104' height='52' />

      <S.SidebarHeaderPhone data-testid='user-phone'>
        {isLoading ? <UiLoader size={LOADER_SIZE.S} gap={LOADER_GAP.S} /> : phone}
      </S.SidebarHeaderPhone>

      <S.SidebarHeaderLogout data-testid='logout' type='button' onClick={onLogout}>
        <UiIcon name='exit' width='24' height='24' />
        <VisuallyHidden>Выход</VisuallyHidden>
      </S.SidebarHeaderLogout>
    </S.SidebarHeader>
  )
}

export default SidebarHeader
