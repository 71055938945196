import { useMutation } from '@tanstack/react-query'

import { apiIdp } from '@/api/apiService'
import { getDeviceType } from '@/helpers/getDeviceType'

import { TEmailCheckScoreRequest, TEmailCheckScoreResponse, THeaders } from '@/types/requestDataTypes'

const emailCheckScore = async (body: TEmailCheckScoreRequest) => {
  const { data } = await apiIdp.post<TEmailCheckScoreResponse, TEmailCheckScoreRequest, THeaders>(
    '/flow/email/check-score',
    body,
    {
      headers: {
        'x-gid-device-type': getDeviceType()
      }
    }
  )
  return data
}

export const useEmailCheckScore = () => {
  return useMutation(['email-check-score'], emailCheckScore)
}
