import { ReactComponent as activeDevice } from '@/assets/icons/activeDevice.svg'
import { ReactComponent as address } from '@/assets/icons/address.svg'
import { ReactComponent as arrowDown } from '@/assets/icons/arrowDown.svg'
import { ReactComponent as arrowLeft } from '@/assets/icons/arrowLeft.svg'
import { ReactComponent as arrowRight } from '@/assets/icons/arrowRight.svg'
import { ReactComponent as arrowUp } from '@/assets/icons/arrowUp.svg'
import { ReactComponent as arrowUpRight } from '@/assets/icons/arrowUpRight.svg'
import { ReactComponent as backendError } from '@/assets/icons/backendError.svg'
import { ReactComponent as backspace } from '@/assets/icons/backspace.svg'
import { ReactComponent as bigSuccess } from '@/assets/icons/bigSuccess.svg'
import { ReactComponent as calendar } from '@/assets/icons/calendar.svg'
import { ReactComponent as check } from '@/assets/icons/check.svg'
import { ReactComponent as checkmark } from '@/assets/icons/checkmark.svg'
import { ReactComponent as circleArrow } from '@/assets/icons/circleArrow.svg'
import { ReactComponent as circleError } from '@/assets/icons/circleError.svg'
import { ReactComponent as circleSuccess } from '@/assets/icons/circleSuccess.svg'
import { ReactComponent as clear } from '@/assets/icons/clear.svg'
import { ReactComponent as congradulations } from '@/assets/icons/congradulations.svg'
import { ReactComponent as date } from '@/assets/icons/date.svg'
import { ReactComponent as disconnect } from '@/assets/icons/disconnect.svg'
import { ReactComponent as edit } from '@/assets/icons/edit.svg'
import { ReactComponent as error } from '@/assets/icons/error.svg'
import { ReactComponent as exclamation } from '@/assets/icons/exclamation.svg'
import { ReactComponent as exit } from '@/assets/icons/exit.svg'
import { ReactComponent as eyeFalse } from '@/assets/icons/eyeFalse.svg'
import { ReactComponent as eyeTrue } from '@/assets/icons/eyeTrue.svg'
import { ReactComponent as fileText } from '@/assets/icons/fileText.svg'
import { ReactComponent as globe } from '@/assets/icons/globe.svg'
import { ReactComponent as history } from '@/assets/icons/history.svg'
import { ReactComponent as home } from '@/assets/icons/home.svg'
import { ReactComponent as info } from '@/assets/icons/info.svg'
import { ReactComponent as location } from '@/assets/icons/location.svg'
import { ReactComponent as logo } from '@/assets/icons/logo.svg'
import { ReactComponent as logoSimple } from '@/assets/icons/logoSimple.svg'
import { ReactComponent as mail } from '@/assets/icons/mail.svg'
import { ReactComponent as man } from '@/assets/icons/man.svg'
import { ReactComponent as noConnection } from '@/assets/icons/no-connection.svg'
import { ReactComponent as password } from '@/assets/icons/password.svg'
import { ReactComponent as phone } from '@/assets/icons/phone.svg'
import { ReactComponent as plus } from '@/assets/icons/plus.svg'
import { ReactComponent as promoQR } from '@/assets/icons/promoQR.svg'
import { ReactComponent as refreshArrow } from '@/assets/icons/refreshArrow.svg'
import { ReactComponent as search } from '@/assets/icons/search.svg'
import { ReactComponent as serviceFailure } from '@/assets/icons/serviceFailure.svg'
import { ReactComponent as services } from '@/assets/icons/services.svg'
import { ReactComponent as serviceSuccess } from '@/assets/icons/serviceSuccess.svg'
import { ReactComponent as settings } from '@/assets/icons/settings.svg'
import { ReactComponent as success } from '@/assets/icons/success.svg'
import { ReactComponent as trash } from '@/assets/icons/trash.svg'
import { ReactComponent as university } from '@/assets/icons/university.svg'
import { ReactComponent as user } from '@/assets/icons/user.svg'
import { ReactComponent as userInCircle } from '@/assets/icons/userInCircle.svg'
import { ReactComponent as vkLogo } from '@/assets/icons/vkLogo.svg'
import { ReactComponent as warning } from '@/assets/icons/warning.svg'
import { ReactComponent as woman } from '@/assets/icons/woman.svg'
import { ReactComponent as yandexLogo } from '@/assets/icons/yandexLogo.svg'

const icons = {
  activeDevice,
  arrowDown,
  arrowLeft,
  arrowRight,
  arrowUp,
  arrowUpRight,
  backendError,
  bigSuccess,
  calendar,
  check,
  checkmark,
  circleArrow,
  circleError,
  circleSuccess,
  clear,
  congradulations,
  date,
  disconnect,
  edit,
  error,
  exclamation,
  exit,
  eyeFalse,
  eyeTrue,
  fileText,
  history,
  home,
  info,
  location,
  logo,
  logoSimple,
  mail,
  man,
  noConnection,
  password,
  phone,
  plus,
  refreshArrow,
  promoQR,
  search,
  serviceFailure,
  services,
  serviceSuccess,
  settings,
  success,
  trash,
  university,
  user,
  userInCircle,
  warning,
  woman,
  backspace,
  yandexLogo,
  address,
  globe,
  vkLogo
}

export default icons
