export const declinationOfTime = (
  number: number,
  timeUnit: 'years' | 'months' | 'days' | 'hours' | 'minutes' | 'seconds',
  genitive = false
) => {
  const cases = [2, 0, 1, 1, 1, 2]

  let titles: string[] = []

  switch (timeUnit) {
    case 'years':
      titles = ['год', 'года', 'лет']
      break

    case 'months':
      titles = ['месяц', 'месяца', 'месяцев']
      break

    case 'days':
      titles = ['день', 'дня', 'дней']
      break

    case 'hours':
      titles = ['час', 'часа', 'часов']
      break

    case 'minutes':
      titles = [genitive ? 'минуту' : 'минута', 'минуты', 'минут']
      break

    case 'seconds':
      titles = [genitive ? 'секунду' : 'секунда', 'секунды', 'секунд']
      break

    default:
  }

  return `${number}\u00A0${
    titles[number % 100 > 4 && number % 100 < 20 ? 2 : cases[number % 10 < 5 ? number % 10 : 5]]
  }`
}
