import * as React from 'react'
import { useMutation } from '@tanstack/react-query'
import axios from 'axios'
import { useEvent } from 'react-use'

import UiIcon from '@/ui/components/UiIcon'
import UiModal from '@/ui/components/UiModal'
import { ThemeList, useThemeState } from '@/ui/contexts/themeContext'

import { SuccessIconWrapper } from './DisconnectModal.style'
import * as S from '@/pages/Sso/summarySsoPages.style'

const DisconnectModal = () => {
  const [isShowModal, setIsShowModal] = React.useState<boolean>(false)

  const { mutateAsync, isLoading } = useMutation(
    ['check-online'],
    async () => {
      await axios.get(window.location.origin)
    },
    { retry: 3, networkMode: 'always' }
  )

  const themeType = useThemeState()

  const handleOnClose = () => {
    mutateAsync().catch(() => setIsShowModal(true))
  }

  const handleConnectionChange = React.useCallback((event: Event) => {
    if (event.type === 'offline') {
      setIsShowModal(true)
    }
    if (event.type === 'online') {
      setIsShowModal(false)
    }
  }, [])

  useEvent('offline', handleConnectionChange, window)
  useEvent('online', handleConnectionChange, window)

  const icon = (
    <SuccessIconWrapper>
      <UiIcon name='disconnect' color={themeType === ThemeList.DARK ? 'white' : '#1B2C4E'} width='88' height='88' />
    </SuccessIconWrapper>
  )

  return (
    <UiModal
      show={isShowModal}
      title='Нет подключения к интернету'
      btnText='Попробовать снова'
      id='disconnect-modal'
      onClose={handleOnClose}
      image={icon}
      isLoadingBtn={isLoading}
    >
      <S.Text fontSize='fs16'>Проверьте соединение</S.Text>
    </UiModal>
  )
}

export default DisconnectModal
