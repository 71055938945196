import * as React from 'react'
import { useAuth } from 'react-oidc-context'

import UiButton from '@/ui/components/UiButton'
import UiModal from '@/ui/components/UiModal'

import { getSupportEmailTemplate, getTemplateMailHref } from './utils'

import * as S from '@/pages/Sso/summarySsoPages.style'

const ErrorModal = () => {
  const { user } = useAuth()

  const supportEmailTemplate = getSupportEmailTemplate(user?.profile?.sub)
  const templateMailHref = getTemplateMailHref(supportEmailTemplate)

  const handleMailToSupport = () => {
    window.location.href = templateMailHref
  }

  const handleCloseModal = () => {
    window.location.reload()
  }

  return (
    <UiModal
      width={408}
      show
      title='Ошибка'
      btnText='Написать в поддержку'
      btnStyle='primary'
      id='error-modal'
      onClose={() => null}
      persistent
      footer={false}
    >
      <S.Text fontSize='fs16' color='dark60'>
        Что-то пошло не так
      </S.Text>
      <S.Container marginTop='g12'>
        <S.FlexBox direction='column' align='stretch'>
          <UiButton type='button' buttonStyle='primary' onClick={handleMailToSupport}>
            Написать в поддержку
          </UiButton>
          <UiButton
            type='button'
            buttonStyle='secondary'
            unBordered
            customPadding='16rem 24rem 0rem'
            onClick={handleCloseModal}
          >
            Закрыть
          </UiButton>
        </S.FlexBox>
      </S.Container>
    </UiModal>
  )
}

export default ErrorModal
