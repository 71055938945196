import { MAIL_GID_SUPPORT } from '@/constants/landing'

import { GetSupportEmailTemplate, SupportEmailTemplate } from './type'

export const getTemplateMailHref = (template: SupportEmailTemplate) => {
  const templateData = new URLSearchParams(template)

  const templateMailURL = new URL(`mailto:${MAIL_GID_SUPPORT}`)
  templateMailURL.search = templateData.toString()

  return decodeURI(templateMailURL.href)
}

export const getSupportEmailTemplate: GetSupportEmailTemplate = (gidID = 'Не определено') => ({
  subject: encodeURIComponent('Вход в Газпром ID'),
  body: encodeURIComponent(
    `Введите, пожалуйста, свой номер телефона: +7
Подробно опишите ситуацию:


_______________________________________________
Сохраните данные ниже, они необходимы для решения вопроса.

Информация об уcтройстве: ${window.navigator.userAgent}
Профиль: ${gidID}`
  )
})
