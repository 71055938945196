import styled from '@emotion/styled'

import { css } from '@/ui/styles'

const { border, devices, gaps } = css

export const CompletionPageWrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.appBackground};
  height: max-content;
  width: 100%;
  max-width: 584rem;
  margin-top: 60rem;
  padding: 64rem ${gaps.g24} ${gaps.g24};
  border: ${border.width} solid ${({ theme }) => theme.colors.dark10};
  border-radius: ${gaps.g20};
  display: flex;
  flex-direction: column;
  align-items: center;

  @media ${devices.d576} {
    border: none;
    border-radius: 0px;
    height: 100%;
    min-height: 100vh;
    padding: 48rem 16rem 16rem;
    margin-top: 0rem;
  }
`
