import { css as cssThemed, Theme } from '@emotion/react'
import styled from '@emotion/styled'

import { css } from '@/ui/styles'

const { delay, fontSizes, fontWeight, gaps } = css

type ButtonProps = {
  buttonStyle?: 'primary' | 'secondary' | 'del' | 'customizedSecondary'
  isLoading?: boolean
  unBordered?: boolean
  customPadding?: string
}

type SetButtonStyleProps = {
  style: ButtonProps['buttonStyle']
  theme: Theme
}

function setButtonStyle({ style, theme }: SetButtonStyleProps) {
  // TODO переписать через SWITCH и по DRY

  if (style === 'primary') {
    return {
      backgroundColor: theme.colors.accent,
      color: `${theme.colors.accentButtonText} !important`,

      '&:hover': {
        backgroundColor: theme.colors.accentHover
      },

      '&:active': {
        backgroundColor: theme.colors.accentPressed
      },

      '&:disabled': {
        backgroundColor: theme.colors.dark5,
        color: `${theme.colors.dark20} !important`,
        cursor: 'not-allowed'
      }
    }
  }

  if (style === 'customizedSecondary') {
    return {
      backgroundColor: theme.colors.secondary,
      color: `${theme.colors.secondaryButtonText} !important`,

      '&:hover': {
        backgroundColor: theme.colors.secondaryHover
      },

      '&:active': {
        backgroundColor: theme.colors.secondaryPressed
      },

      '&:disabled': {
        backgroundColor: theme.colors.dark5,
        color: `${theme.colors.dark20} !important`,
        cursor: 'not-allowed'
      }
    }
  }

  if (style === 'del') {
    return {
      backgroundColor: 'transparent',
      color: theme.colors.error,
      border: `1px solid ${theme.colors.dark10}`,

      '&:hover': {
        color: theme.colors.error,
        border: `1px solid ${theme.colors.dark20}`
      },

      '&:active': {
        color: theme.colors.error,
        border: `1px solid ${theme.colors.dark20}`
      },

      '&:disabled': {
        backgroundColor: theme.colors.dark5,
        color: theme.colors.dark20,
        border: `1px solid ${theme.colors.dark10}`,
        cursor: 'not-allowed'
      }
    }
  }

  return {
    backgroundColor: 'transparent',
    color: `${theme.colors.accent} !important`,
    border: `1px solid ${theme.colors.dark10}`,

    '&:hover': {
      color: `${theme.colors.accentHover} !important`,
      borderColor: theme.colors.dark20
    },

    '&:active': {
      color: `${theme.colors.accentPressed} !important`,
      border: `1px solid ${theme.colors.dark20}`
    },

    '&:disabled': {
      backgroundColor: theme.colors.dark5,
      color: `${theme.colors.dark20} !important`,
      border: theme.colors.dark20,
      cursor: 'not-allowed'
    }
  }
}

const unBorderedCSS = cssThemed`
  &,
  &:hover,
  &:active {
    border: none;
  }
`

export const Button = styled.button<ButtonProps>`
  position: relative;
  font-size: ${fontSizes.fs18};
  font-weight: ${fontWeight.fw700};
  padding: ${gaps.g20} ${gaps.g24};
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 56rem;
  border-radius: 10rem;
  transition: border-color ${delay} ease-in-out;
  pointer-events: ${({ isLoading }) => (isLoading ? 'none' : 'all')};

  ${({ buttonStyle, theme }) => !!buttonStyle && setButtonStyle({ style: buttonStyle, theme })};
  ${({ customPadding }) => (customPadding ? `padding: ${customPadding};` : '')}
  ${({ unBordered }) => (unBordered ? unBorderedCSS : '')}
`
