import React from 'react'

import UiLoader from '../UiLoader'

import * as S from './UiButton.style'

import { useBrandingSelector } from '@/store/brandingStore'
import { useClientInfoSelector } from '@/store/clientInfoStore'

type PropsType = {
  children: React.ReactNode
  type?: 'submit' | 'button' | 'reset'
  name?: string
  buttonStyle?: 'primary' | 'secondary' | 'del' | 'customizedSecondary'
  isLoading?: boolean
  disabled?: boolean
  unBordered?: boolean
  customPadding?: string
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void | Promise<void>
}

const UiButton = ({
  type = 'button',
  name = 'button',
  buttonStyle,
  isLoading = false,
  disabled = false,
  unBordered = false,
  customPadding,
  onClick,
  children
}: PropsType) => {
  const brandingInfo = useBrandingSelector.use.branding()

  const isSecondaryStyleCustomized =
    (!!brandingInfo?.custom_colors?.secondary_accent ||
      // TODO удалить этот флаг после полного переезда на отдельный branding
      !!brandingInfo?.custom_colors?.secondary_accent) &&
    buttonStyle === 'secondary'

  const buttonProps = {
    type,
    buttonStyle: isSecondaryStyleCustomized ? 'customizedSecondary' : buttonStyle,
    isLoading,
    disabled,
    unBordered,
    customPadding,
    ...(onClick
      ? {
          onClick: (event: React.MouseEvent<HTMLButtonElement>) => onClick(event)
        }
      : {})
  }

  const getCurrentLoaderColor = () => {
    switch (buttonStyle) {
      case 'del':
        return 'error'
      case 'secondary':
        return 'accent'
      case 'customizedSecondary':
        return 'secondaryButtonText'
      default:
        return 'accentButtonText'
    }
  }

  return (
    <S.Button {...buttonProps} data-testid={name}>
      {isLoading ? <UiLoader color={getCurrentLoaderColor()} size={16} /> : children}
    </S.Button>
  )
}

export default UiButton
