import { createJSONStorage, devtools, persist } from 'zustand/middleware'
import { create } from 'zustand'

import { IS_REAL_INTEGRATION } from '@/constants'
import { TClientInfo } from '@/types/auth'
import { StoreStatus } from '../types'

import createSelectors from '../createSelectors'

type ClientInfoStore = {
  status: StoreStatus
  clientInfo: TClientInfo | null
  redirect: string | null
}

type ClientInfoActions = {
  setStatus: (status: StoreStatus) => void
  setClientInfo: (value: TClientInfo) => void
  setRedirect: (value: string) => void
}

export const clientInfoStore = create<ClientInfoStore & ClientInfoActions>()(
  devtools(
    set => ({
      status: 'idle',
      clientInfo: null,
      redirect: null,
      setStatus: status => set({ status }),
      setClientInfo: clientInfo => set({ clientInfo, status: 'idle' }),
      setRedirect: (value: string) => set(() => ({ redirect: value }))
    }),
    { name: 'Client Info', enabled: !IS_REAL_INTEGRATION }
  )
)

export const useClientInfoSelector = createSelectors(clientInfoStore)
