import styled from '@emotion/styled'

import { css } from '@/ui/styles'

const { gaps, zIndex } = css

export const UiThemeSwitcherWrapper = styled.div`
  position: relative;
`
export const UiThemeSwitcherLabel = styled.label`
  position: absolute;
  top: 0;
  left: 0;
  width: 42rem;
  height: 26rem;
  border-radius: ${gaps.g16};
  background: ${({ theme }) => theme.colors.dark20};
  cursor: pointer;
  &::after {
    content: '';
    display: block;
    border-radius: 50%;
    width: 18rem;
    height: 18rem;
    margin: 4rem;
    background: ${({ theme }) => theme.colors.white};
    transition: 0.2s;
  }
`

export const UiThemeSwitcherCheckBox = styled.input`
  opacity: 0;
  z-index: ${zIndex.highest};
  border-radius: 15rem;
  width: 42rem;
  height: 26rem;
  &:checked + ${UiThemeSwitcherLabel} {
    background: ${({ theme }) => theme.colors.accent};
    &::after {
      content: '';
      display: block;
      border-radius: 50%;
      width: 18rem;
      height: 18rem;
      margin-left: 21rem;
      transition: 0.2s;
    }
  }
`
