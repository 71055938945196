import { getUserTimezone } from '@/helpers/getUserTimezone'

import { IS_REAL_INTEGRATION } from '@/constants'
import {
  ALLOWED_GOYA_COOKIE_FIELDS,
  CONST_FIELD_EVENT_ELEMENT_LOCATION,
  CONST_FIELD_EVENT_GROUP,
  CONST_FIELD_TOUCH_POINT,
  GOYA_COOKIE_NAME_WITH_EQUAL_SIGN
} from './constants'
import {
  GoyaBrowserEvent,
  GoyaConfigWithOptionalUiEvent,
  GoyaEventConstAndCalcParams,
  GoyaEventCookieParams,
  GoyaStoreEventConfig
} from './types'
import { EventContext, EventLabel } from './enums'

export const getGoyaCookieValue = (): string | undefined => {
  const rawCookies = window.document.cookie
  const rawGoyaCookie = rawCookies.split('; ').find(cookie => cookie.startsWith(GOYA_COOKIE_NAME_WITH_EQUAL_SIGN))

  return rawGoyaCookie ? rawGoyaCookie.slice(GOYA_COOKIE_NAME_WITH_EQUAL_SIGN.length) : undefined
}

const parseGoyaCookie = (base64GoyaCookieData: string): GoyaEventCookieParams | undefined => {
  try {
    const parsedCookieData = JSON.parse(window.atob(base64GoyaCookieData.replace(/"/g, ''))) as GoyaEventCookieParams

    if (parsedCookieData) {
      const filteredCookieData: GoyaEventCookieParams = {}

      for (const fieldName in parsedCookieData) {
        if (ALLOWED_GOYA_COOKIE_FIELDS.includes(fieldName)) {
          filteredCookieData[fieldName as keyof GoyaEventCookieParams] =
            parsedCookieData[fieldName as keyof GoyaEventCookieParams]
        }
      }

      if (Object.keys(filteredCookieData).length) {
        return filteredCookieData
      }
    }

    return undefined
  } catch {
    if (!IS_REAL_INTEGRATION) console.log('Invalid g-cookie')

    return undefined
  }
}

const getConstAndCalcGoyaParams = (configWithUiEvent: GoyaConfigWithOptionalUiEvent): GoyaEventConstAndCalcParams => {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const { event_category, event_action, event_label, event_context, screen_name, event_name } = configWithUiEvent.config

  const getContext = () => {
    if (event_context) {
      return event_context
    }

    if (event_label === EventLabel.Link) {
      return `${window.location.origin}${configWithUiEvent.uiEvent?.currentTarget?.getAttribute('href') ?? ''}`
    }

    return undefined
  }

  return {
    event_group: CONST_FIELD_EVENT_GROUP,
    event_element_location: CONST_FIELD_EVENT_ELEMENT_LOCATION,
    touch_point: CONST_FIELD_TOUCH_POINT,
    event_name: event_name || `${event_category}-${event_action}-${event_label}`,
    screen_size: `${window.screen.width}x${window.screen.height}`,
    page_url: `${window.location.origin}${window.location.pathname}`,
    event_timestamp: Date.now(),
    event_timezone: getUserTimezone('goya'),
    event_context: getContext() as EventContext,
    screen_name
  }
}

export const prepareGoyaEventUrl = (
  cookieValue: string,
  configWithUiEvent: GoyaConfigWithOptionalUiEvent
): URL | undefined => {
  let eventUrl: URL | undefined

  try {
    eventUrl = new URL(String(import.meta.env.VITE_APP_GOYA_API_URL))
  } catch {
    if (!IS_REAL_INTEGRATION) console.log('Invalid env GOYA_API_URL')
  }

  if (eventUrl) {
    const goyaEventCookieParams = parseGoyaCookie(cookieValue)
    if (!goyaEventCookieParams) return undefined

    const goyaEventConstAndCalcParams = getConstAndCalcGoyaParams(configWithUiEvent)
    const goyaEventConfig = configWithUiEvent.config

    const goyaEventParamsObject = {
      ...goyaEventConstAndCalcParams,
      ...goyaEventConfig,
      ...goyaEventCookieParams
    }

    for (const [key, value] of Object.entries(goyaEventParamsObject)) {
      if (value) {
        eventUrl.searchParams.set(key, String(value))
      }
    }
  }

  return eventUrl
}

export const sendConfiguredEvent = (config: GoyaStoreEventConfig, uiEvent?: GoyaBrowserEvent): void => {
  const goyaCookieValue = getGoyaCookieValue()
  if (!goyaCookieValue) return

  const eventUrl = prepareGoyaEventUrl(goyaCookieValue, { config, uiEvent })

  if (eventUrl) {
    fetch(eventUrl, { keepalive: true }).catch(() => {})
  }
}

export const getCurrentScreenName = (): string => {
  const { pathname } = window.location

  return pathname
}
