import axios, { AxiosInstance, AxiosRequestConfig, AxiosRequestHeaders, AxiosResponse } from 'axios'
// import { ErrorResponseType, ErrorsType, SuccessResponseType } from '@/api/types'
// import { RequestStatus } from './enums'

const headers: Partial<AxiosRequestHeaders> = {
  'Content-Type': 'application/json; charset=utf-8',
  'X-Requested-With': 'XMLHttpRequest'
}

const timeout = 10 * 1000

export class ApiClient {
  private readonly client: AxiosInstance

  private token: string | null = null

  public date: Date = new Date()

  protected CSRFToken: string | null = null

  private static idpInstance: ApiClient | null = null

  private static personalAccountInstance: ApiClient | null = null

  private constructor(url = '') {
    this.client = this.initClient(url)
  }

  private initClient(url: string): AxiosInstance {
    const client = axios.create({
      baseURL: url,
      headers,
      timeout
    })

    client.interceptors.request.use(
      config => {
        if (config.method !== 'GET') {
          if (!!this.CSRFToken && !!config.headers) config.headers['X-CSRFToken'] = this.CSRFToken
        }

        if (!!this.token && !!config.headers) config.headers.Authorization = `Bearer ${this.token}`

        return config
      },
      error => {
        return Promise.reject(error)
      }
    )

    return client
  }

  public static getInstance(instance: 'IDP' | 'PersonalAccount'): ApiClient {
    if (instance === 'IDP') {
      if (this.idpInstance === null) {
        this.idpInstance = new ApiClient('/api/v1')
      }
      return this.idpInstance
    }

    if (instance === 'PersonalAccount') {
      if (this.personalAccountInstance === null) {
        this.personalAccountInstance = new ApiClient('/api/v1/personal-account')
      }
      return this.personalAccountInstance
    }

    throw new Error('Instance not defined')
  }

  private getToken() {
    const cookie = document.cookie.split('; ')
    const csrfTokenElement = cookie.find(row => row.startsWith('csrfToken'))
    this.CSRFToken = csrfTokenElement ? csrfTokenElement.split('=')[1] : null
  }

  public static setToken = (token: string | null) => {
    ApiClient.getInstance('PersonalAccount').token = token
    ApiClient.getInstance('IDP').token = token
  }

  async get<TResponse, TQuery = null>(
    url: string,
    params?: TQuery,
    payload?: AxiosRequestConfig,
    controller?: AbortController
  ): Promise</* SuccessResponseType<TResponse> | ErrorResponseType */ AxiosResponse<TResponse>> {
    const config: AxiosRequestConfig = {
      url,
      params,
      method: 'GET',
      signal: controller?.signal,
      ...payload
    }
    const response: /* SuccessResponseType<TResponse> | ErrorResponseType */ AxiosResponse<TResponse> =
      await this.request(config)
    if (!this.CSRFToken) this.getToken()
    return response
  }

  async post<TResponse, TData = undefined, TConfig = undefined>(
    url: string,
    data?: TData,
    payload?: TConfig,
    controller?: AbortController
  ): Promise</* SuccessResponseType<TResponse> | ErrorResponseType */ AxiosResponse<TResponse>> {
    const config: AxiosRequestConfig = {
      url,
      method: 'POST',
      data,
      signal: controller?.signal,
      ...payload
    }
    return this.request(config)
  }

  async put<TResponse, TData = undefined>(
    url: string,
    data: TData,
    controller?: AbortController
  ): Promise</* SuccessResponseType<TResponse> | ErrorResponseType */ AxiosResponse<TResponse>> {
    const config: AxiosRequestConfig = {
      url,
      method: 'PUT',
      data,
      signal: controller?.signal
    }
    return this.request(config)
  }

  async patch<TResponse>(
    url: string,
    data?: TResponse,
    controller?: AbortController
  ): Promise</* SuccessResponseType<TResponse> | ErrorResponseType */ AxiosResponse<TResponse>> {
    const config: AxiosRequestConfig = {
      url,
      method: 'PATCH',
      data,
      signal: controller?.signal
    }
    return this.request(config)
  }

  async delete<TResponse, TQuery = null>(
    url: string,
    data: TResponse | null,
    params?: TQuery | null,
    controller?: AbortController
  ): Promise</* SuccessResponseType<TResponse> | ErrorResponseType */ AxiosResponse<TResponse>> {
    const config: AxiosRequestConfig = {
      url,
      method: 'DELETE',
      data,
      params,
      signal: controller?.signal
    }
    return this.request(config)
  }

  protected async request<TResponse>(
    config: AxiosRequestConfig
  ): Promise</* SuccessResponseType<TResponse> | ErrorResponseType */ AxiosResponse<TResponse>> {
    // try {
    const response = await this.client(config)

    if (response.headers.date) this.date = new Date(String(response.headers.date))

    // 302 redirect
    if ('responseURL' in response.request && response.headers['content-type'] === 'text/html') {
      window.location.href = (response.request as { responseURL: string }).responseURL
    }

    return response
    // return { status: RequestStatus.Success, data: response.data as TResponse }
    // } catch (e: unknown) {
    //   return this.handleError(e as AxiosError)
    // }
  }

  // protected handleError(error: AxiosError): ErrorResponseType {
  //   return {
  //     status: RequestStatus.Error,
  //     errors:
  //       error.response?.data as ErrorsType ?? { code: 'Unknown error' },
  //   }
  // }
}

export const apiIdp = ApiClient.getInstance('IDP')
export const apiIdpPersonalAccount = ApiClient.getInstance('PersonalAccount')
