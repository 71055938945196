import { RoutePath } from '@/router/enums'

export const FIRST_STEP_PATHS_ALLOWED: string[] = [
  `/${RoutePath.Auth}/${RoutePath.SetPassword}`,
  `/${RoutePath.Auth}/${RoutePath.ChangePassword}`,
  `/${RoutePath.Auth}/${RoutePath.RequestChangePassword}`,
  `/${RoutePath.Auth}/${RoutePath.RecoveryOTP}`,
  `/${RoutePath.Auth}/${RoutePath.Removal}`,
  `/${RoutePath.Auth}/${RoutePath.Completion}`,
  `/${RoutePath.Auth}/${RoutePath.Completion}/${RoutePath.Email}`,
  `/${RoutePath.Auth}/${RoutePath.Consent}`,
  `/${RoutePath.Auth}/${RoutePath.Qr}`,
  `/${RoutePath.Auth}/${RoutePath.Qr}/${RoutePath.Confirm}`,
  `/${RoutePath.Auth}/${RoutePath.Qr}/${RoutePath.QrRefresh}`,
  `/${RoutePath.Auth}/${RoutePath.Social}/${RoutePath.Confirm}`,
  `/${RoutePath.Auth}`,
  `/${RoutePath.Auth}/${RoutePath.Vk}`,
  `/${RoutePath.Auth}/${RoutePath.Social}`,
  `/${RoutePath.Auth}/${RoutePath.Yandex}`,
  `/${RoutePath.Auth}/${RoutePath.AccountSelect}`,
  `/${RoutePath.Auth}/${RoutePath.OtpCode}`,
  `/${RoutePath.Auth}/${RoutePath.RegistrationOTP}`,
  `/${RoutePath.Auth}/${RoutePath.MobileId}`,
  `/${RoutePath.Auth}/${RoutePath.RegistrationMobileId}`,
  `/${RoutePath.Auth}/${RoutePath.Push}`,
  `/${RoutePath.Auth}/${RoutePath.RegistrationPush}`,
  `/${RoutePath.Auth}/${RoutePath.Blocked}`
]
