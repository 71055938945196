import * as React from 'react'
import { useAuth } from 'react-oidc-context'

import { RoutePath } from '@/router/enums'

import { footerLinkEventConfig, GoyaEventId, goyaEventsStore } from '@/features/GoyaAnalitics'

export const useGoyaEvents = () => {
  const { addEvents } = goyaEventsStore.getState()
  const auth = useAuth()

  React.useEffect(() => {
    // при переходе на статические страницы открываемые в новой вкладке auth.isAuthenticated === false
    // определяем источник перехода по referrer
    const isReffererOffice = window.document.referrer.includes(RoutePath.UserOffice)

    if (auth.isAuthenticated || isReffererOffice) return

    addEvents([GoyaEventId.FooterLink, footerLinkEventConfig])
  }, [addEvents, auth.isAuthenticated])
}
