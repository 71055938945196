import * as React from 'react'
import { useLocation } from 'react-router-dom'
import { useBoolean } from 'react-use'

import { SRC_DAS_JS } from './constants'
import { RoutePath } from '@/router/enums'

import { captchaStore, useCaptchaSelector } from './captchaStore'
import { useClientInfo, useClientInfoSelector } from '@/store/clientInfoStore'
import { CaptchaType } from '@/store/securityStore'
import { recaptchaStore } from '@/store/securityStore/recaptchaStore'
import { useSecurityActions } from '@/store/securityStore/securityActions'
import { securityStore } from '@/store/securityStore/securityStore'
import { TClientInfo } from '@/types/auth'

type PropsType = {
  children?: React.ReactNode
  isDas?: boolean
}

const SecurityController = ({ children, isDas }: PropsType) => {
  const [dasInit, setDasInit] = React.useState(false)
  const prevClientInfo = React.useRef<TClientInfo | null>(null)
  const { executeRecaptcha } = useSecurityActions()

  const { setCaptchaType } = securityStore.getState()

  const { recaptchaIsReady, setRecaptchaIsBroken, setRecaptchaIsEnabled, setSiteKey } = recaptchaStore.getState()

  const { captchaDasIsExecuting, captchaBSPromise, setCaptchaBSPromise, setCaptchaBS } = captchaStore.getState()

  const setCaptchaDasIsExecuting = useCaptchaSelector.use.setCaptchaDasIsExecuting()

  const [needExecuteRecaptcha, toggleNeedExecuteRecaptcha] = useBoolean(false)

  const clientInfo = useClientInfo()
  const isError = useClientInfoSelector.use.status() === 'error'
  const { pathname } = useLocation()

  const dasForcedList = [
    `/${RoutePath.Qr}/${RoutePath.Confirm}`,
    `/${RoutePath.RecoveryOTP}`,
    `/${RoutePath.UserOffice}`
  ]
  const isDASForced =
    pathname?.endsWith(`/${RoutePath.Qr}/${RoutePath.Confirm}`) ||
    pathname?.endsWith(`/${RoutePath.RecoveryOTP}`) ||
    // + все страницы ЛК для SFR-2101
    pathname?.includes(`/${RoutePath.UserOffice}`) ||
    !!isDas

  const checkDasScriptExist = () => {
    const scripts = document.getElementsByTagName('script')
    for (let index = 0; index < scripts.length; index++) {
      console.log(scripts[index].src)
      if (scripts[index].src === `${SRC_DAS_JS}?version=0.2`) {
        return true
      }
    }
    return false
  }

  React.useEffect(() => {
    if (clientInfo || isDASForced) {
      let scriptDAS: HTMLScriptElement | null = null

      // const { recaptcha_public_key: sitekey, enable_kfp: enableKfp } = clientInfo
      const sitekey = clientInfo?.recaptcha_public_key || null
      const enableKfp = clientInfo?.enable_kfp || isDASForced
      const prevEnableKfp = prevClientInfo?.current?.enable_kfp

      // recaptcha
      if (sitekey) {
        setRecaptchaIsEnabled(true)
        setSiteKey(sitekey)
      }

      // GID captcha
      if (enableKfp && !prevEnableKfp && !dasInit) {
        setDasInit(true)
        scriptDAS = document.createElement('script')

        scriptDAS.src = `${SRC_DAS_JS}?version=0.2`
        scriptDAS.async = true

        scriptDAS.onload = () => {
          setCaptchaType(CaptchaType.Captcha)
          setCaptchaDasIsExecuting(true)

          setCaptchaBSPromise(
            new Promise(resolve => {
              try {
                window.kfp
                  .get_oxxfgh_async()
                  .then(bs => {
                    setCaptchaBS(bs)
                  })
                  .catch(() => {
                    setCaptchaBS('not-initialized')
                  })
                  .finally(() => {
                    resolve()
                  })
              } catch {
                console.error('Script is not working correctly')
                resolve()
                setCaptchaBS('not-initialized')
              }
            })
          )
        }

        scriptDAS.onerror = () => {
          setCaptchaBS('not-initialized')
        }

        document.body.appendChild(scriptDAS)
      }

      prevClientInfo.current = clientInfo

      return () => {
        if (scriptDAS) document.body.removeChild(scriptDAS)
      }
    }

    return () => {}
  }, [
    captchaBSPromise,
    captchaDasIsExecuting,
    clientInfo,
    isDASForced,
    isError,
    setCaptchaBS,
    setCaptchaBSPromise,
    setCaptchaDasIsExecuting,
    setCaptchaType,
    setRecaptchaIsBroken,
    setRecaptchaIsEnabled,
    setSiteKey,
    prevClientInfo,
    dasInit
  ])

  React.useEffect(() => {
    if (recaptchaIsReady && needExecuteRecaptcha) {
      toggleNeedExecuteRecaptcha(false)
      executeRecaptcha()
    }
  }, [executeRecaptcha, needExecuteRecaptcha, recaptchaIsReady, toggleNeedExecuteRecaptcha])

  return children
}

export default SecurityController
