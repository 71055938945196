import * as React from 'react'
import { useAuth } from 'react-oidc-context'
import { Navigate, Outlet, useNavigate } from 'react-router-dom'

import UiLoader from '@/ui/components/UiLoader'
// TODO it's a plug for hide Footer and Menu on some screen resolution
// import { MainLayoutStateType } from '@/layouts/MainLayout/types'
import Footer from '@/components/Footer'
import Sidebar from '@/components/Sidebar'

import { useAutoTesterStatus } from '@/hooks/useAutoTesterStatus'
import useCurrentRouteName from '@/hooks/useCurrentRouteName'
import { usePlaywrightTestStatus } from '@/hooks/usePlaywrightTestStatus'

import { LOADER_GAP, LOADER_SIZE } from '@/constants'
import { LOCATION_URL } from './constants'
import { RoutePath } from '@/router/enums'

import * as S from './MainLayout.style'

import { DEV_GOYA_QUERY_PARAMS } from '@/features/GoyaAnalitics'

const MainLayout = () => {
  const navigate = useNavigate()
  const auth = useAuth()
  const pageName = useCurrentRouteName()
  const isAutoTester = useAutoTesterStatus()
  const isPlaywrightTest = usePlaywrightTestStatus()

  React.useEffect(() => {
    const authInit = async () => {
      await auth.signinRedirect({
        extraQueryParams: DEV_GOYA_QUERY_PARAMS
      })
    }

    if (!auth.isLoading && !auth.isAuthenticated && !isAutoTester && !isPlaywrightTest) {
      window.sessionStorage.setItem(LOCATION_URL, window.location.pathname + window.location.search)

      authInit().catch((e: string) => {
        throw new Error(e)
      })
    }
  }, [auth, isAutoTester, isPlaywrightTest])

  // TODO it's a plug for hide Footer and Menu on some screen resolution
  // const state = location.state as MainLayoutStateType

  const onLogout = () => {
    navigate(`/${RoutePath.UserOffice}/${RoutePath.Logout}`, { state: { isGetSsoLayout: true } })
  }

  // TODO it's a plug for hide Footer and Menu on some screen resolution
  // const toggleInterface = () => {
  //   return state?.inside ?? true
  // }

  // TODO add toggle option

  const localUrl = window.sessionStorage.getItem(LOCATION_URL)

  if (localUrl) {
    window.sessionStorage.removeItem(LOCATION_URL)
    return <Navigate to={localUrl ?? '/'} />
  }

  if ((auth.isLoading || !auth.isAuthenticated) && !isAutoTester && !isPlaywrightTest) {
    return (
      <S.FlexBoxCentered>
        <UiLoader size={LOADER_SIZE.L} gap={LOADER_GAP.L} />
      </S.FlexBoxCentered>
    )
  }

  if (auth.error) {
    return <div>Oops... {auth.error?.message}</div>
  }

  return (
    <React.StrictMode>
      <S.MainLayout data-pagename={pageName}>
        <S.MainLayoutSidebar>
          <Sidebar onLogout={onLogout} />
        </S.MainLayoutSidebar>

        <S.MainLayoutWorkspace>
          <React.Suspense fallback={<UiLoader />}>
            <Outlet />
          </React.Suspense>
        </S.MainLayoutWorkspace>

        <S.MainLayoutFooter>
          <Footer />
        </S.MainLayoutFooter>
      </S.MainLayout>
    </React.StrictMode>
  )
}

export default MainLayout
