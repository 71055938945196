import React from 'react'
import { Navigate, RouteObject } from 'react-router-dom'

import { RoutePath } from '@/router/enums'

const AccessDenied = React.lazy(() => import('@/pages/Misc/AccessDenied'))
const ServiceUnavailable = React.lazy(() => import('@/pages/Misc/ServiceUnavailable'))
const AutoTestingPage = React.lazy(() => import('@/pages/Misc/AutoTestingPage'))

export const serviceRoutes = (isAuthenticated: boolean, isAutoTester: boolean): RouteObject[] => {
  return [
    {
      element: isAuthenticated ? <AccessDenied /> : <Navigate to='/' />,
      path: RoutePath.AccessDenied
    },
    {
      element: <ServiceUnavailable />,
      path: RoutePath.ServiceUnavailable
    },
    {
      element: isAutoTester ? <AutoTestingPage /> : <Navigate to='/' />,
      path: RoutePath.AutoTestingPage
    }
  ]
}
