import { css as cssThemed, keyframes } from '@emotion/react'
import styled from '@emotion/styled'

import { css } from '@/ui/styles'

const { border, fontSizes, fontWeight, gaps, lineHeights } = css

type SpinWrapperPropsType = {
  isSpinning: boolean
}

export const OTPButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: ${gaps.g16};

  background-color: transparent;
  width: 100%;
  height: 56rem;

  border: ${border.width} solid ${({ theme }) => theme.colors.dark10};
  border-radius: ${border.radius};

  color: ${({ theme }) => theme.colors.accent};
  line-height: ${lineHeights.lh18};
  font-size: ${fontSizes.fs18};
  font-weight: ${fontWeight.fw700};

  cursor: pointer;
`

const spinKeyframes = keyframes`
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(360deg);
	}
`

const animationCss = cssThemed`
  animation: ${spinKeyframes} 1.6s infinite linear;
`
export const SpinWrapper = styled.span<SpinWrapperPropsType>`
  ${({ isSpinning }) => (isSpinning ? animationCss : '')}
`

export const ButtonTitle = styled.span``
