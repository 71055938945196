type TimezoneFormat = 'goya'

export const getUserTimezone = (format?: TimezoneFormat): string => {
  const timeZoneOffset = new Date().getTimezoneOffset()

  const hoursOffset = String(Math.floor(Math.abs(timeZoneOffset / 60)))
  const minutesOffset = String(Math.abs(timeZoneOffset % 60))

  switch (format) {
    case 'goya': {
      return `${timeZoneOffset > 0 ? '-' : ''}${hoursOffset}${
        Number(minutesOffset) > 0 ? `.${minutesOffset.padStart(2, '0')}` : ''
      }`
    }

    default: {
      return `${timeZoneOffset > 0 ? '-' : '+'}${hoursOffset.padStart(2, '0')}:${minutesOffset.padStart(2, '0')}`
    }
  }
}
