import styled from '@emotion/styled'

import { css } from '@/ui/styles'

const { devices, gaps, width } = css

export const MainLayout = styled.div`
  display: flex;
  box-sizing: border-box;
  width: ${width.w1100};
  min-height: 100vh;
  margin: 0 auto;
  padding: ${gaps.g12};

  @media ${devices.d1100} {
    display: block;
    max-width: ${width.w768};
    width: 100%;
  }

  @media ${devices.d375} {
    display: block;
    min-width: ${width.w320};
    padding: ${gaps.g16} ${gaps.g12};
  }
`

export const MainLayoutSidebar = styled.div`
  height: max-content;
  margin-right: ${gaps.g16};
  width: 345rem;
  position: fixed;

  @media ${devices.d1100} {
    margin-right: 0;
    width: 100%;
    position: static;
  }
`

export const MainLayoutWorkspace = styled.div`
  box-sizing: border-box;
  width: 707rem;
  margin-left: 361rem;

  @media ${devices.d1100} {
    width: 100%;
    margin-left: 0;
  }
`

export const MainLayoutFooter = styled.div`
  display: none;

  @media ${devices.d1100} {
    display: block;
  }
`

export const FlexBoxCentered = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
`
