import { useMutation, useQuery } from '@tanstack/react-query'

import { apiIdpPersonalAccount } from '@/api/apiService'

import { TReactQuery, TReactQueryMutate } from '@/types'
import { TFullUserData, TRequestInfo } from '@/types/requestDataTypes'

const getUserData = async (): Promise<TFullUserData> => {
  const { data } = await apiIdpPersonalAccount.get<TFullUserData>('users')
  return data
}

export const useApiUserData = (): TReactQuery<TFullUserData> => {
  const { data, isSuccess, isError, isLoading } = useQuery(['userData'], () => getUserData())

  return { data, isSuccess, isError, isLoading }
}

export const useApiUpdUserDate = (): TReactQueryMutate<TRequestInfo> => {
  const { isSuccess, mutate, error, isLoading } = useMutation((userData: TRequestInfo) => {
    return apiIdpPersonalAccount.put('users', userData)
  })

  return { isSuccess, mutate, error: (error as Error)?.message, isLoading }
}
