import styled from '@emotion/styled'

import { css } from '@/ui/styles'

const { border, devices, gaps } = css

export const Sidebar = styled.div`
  box-sizing: border-box;
  padding: 12rem 10rem;
  padding-bottom: 23rem;
  border: ${border.width} solid ${({ theme }) => theme.colors.dark10};
  border-radius: ${border.radius};

  @media ${devices.d1100} {
    border: none;
    padding: 0;
    margin-bottom: ${gaps.g16};
  }

  @media ${devices.d375} {
    margin-bottom: ${gaps.g12};
  }
`

export const SidebarFooter = styled.div`
  @media ${devices.d1100} {
    display: none;
  }
`
