import { devtools } from 'zustand/middleware'
import Reaptcha from 'reaptcha'
import { create } from 'zustand'

import { IS_REAL_INTEGRATION } from '@/constants'
import { CallbackSecure, CancelSecure } from './types'

import createSelectors from '@/store/createSelectors'

type RecaptchaStoreState = {
  sitekey: string
  recaptchaIsReady: boolean
  recaptchaIsExecuting: boolean
  recaptchaIsBroken: boolean
  recaptchaIsEnabled: boolean
  recaptchaIsExecutingLonger: boolean
  onVerify: CallbackSecure | null
  onCancel: CancelSecure | null
  recaptchaRef: Reaptcha | null
}

type RecaptchaStoreActions = {
  setSiteKey: (payload: string) => void
  setRecaptchaIsReady: (payload: boolean) => void
  setRecaptchaIsExecuting: (payload: boolean) => void
  setRecaptchaIsExecutingLonger: (payload: boolean) => void
  setRecaptchaIsBroken: (payload: boolean) => void
  setRecaptchaIsEnabled: (payload: boolean) => void
  setRecaptchaRef: (payload?: Reaptcha | null) => void
  setOnVerify: (payload: CallbackSecure) => void
  setOnCancel: (payload: CancelSecure) => void
}

export const recaptchaStore = create<RecaptchaStoreState & RecaptchaStoreActions>()(
  devtools(
    set => ({
      sitekey: '',
      recaptchaIsReady: false,
      recaptchaIsExecutingLonger: false,
      recaptchaIsBroken: false,
      recaptchaIsExecuting: false,
      recaptchaIsEnabled: false,
      captchaBSPromise: null,
      onVerify: null,
      onCancel: null,
      recaptchaRef: null,
      setSiteKey: (payload: string) => set({ sitekey: payload }),
      setRecaptchaIsReady: (payload: boolean) => set({ recaptchaIsReady: payload }),
      setRecaptchaIsExecuting: (payload: boolean) => set({ recaptchaIsExecuting: payload }),
      setRecaptchaIsExecutingLonger: (payload: boolean) => set({ recaptchaIsExecutingLonger: payload }),
      setRecaptchaIsBroken: (payload: boolean) => set({ recaptchaIsBroken: payload }),
      setRecaptchaIsEnabled: (payload: boolean) => set({ recaptchaIsEnabled: payload }),
      setOnVerify: (payload: CallbackSecure) => set({ onVerify: payload }),
      setOnCancel: (payload: CancelSecure) => set({ onCancel: payload }),
      setRecaptchaRef: (payload?: Reaptcha | null) => set({ recaptchaRef: payload })
    }),
    { name: 'Recaptcha', enabled: !IS_REAL_INTEGRATION }
  )
)

export const useRecaptchaSelector = createSelectors(recaptchaStore)
