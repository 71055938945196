import { Outlet } from 'react-router-dom'

import Footer from '@/components/Footer'

import * as S from './CompletionLayout.style'

import { useBrandingInfo } from '@/store/brandingStore'

const CompletionLayout = () => {
  const brandingInfo = useBrandingInfo()

  return (
    <S.CompletionPageWrapper>
      <Outlet />
      {brandingInfo?.gid_branding ? <Footer isAuth /> : null}
    </S.CompletionPageWrapper>
  )
}

export default CompletionLayout
