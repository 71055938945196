export const enum GoyaEventId {
  FooterLink = 'footerLink',
  NextButton = 'nextButton',
  PushApproved = 'pushApproved',
  PushModalGetSms = 'pushModalGetSms',
  PushModalStartAgain = 'pushModalStartAgain',
  PostLoginSuccess = 'postLoginSuccess',
  PostLoginReject = 'postLoginReject',
  OtpGetAgain = 'otpGetAgain',
  AuthByPass = 'authByPass',
  OtpThrottling = 'otpThrottling',
  RecoverAccount = 'recoverAccount',
  CaptchaRefresh = 'captchaRefresh',
  CaptchaNext = 'captchaNext',
  CaptchaCancel = 'captchaCancel',
  CaptchaWrong = 'captchaWrong',
  EnterByPass = 'enterByPass',
  ForgotsPass = 'forgotsPass',
  EnterByOtp = 'enterByOtp',
  SetPassword = 'setPassword',
  PassMismatch = 'passMismatch',
  PassMisrequirement = 'passMisrequirement',
  PassTimerModalShow = 'passTimerModalShow',
  PassTimerModalButton = 'passTimerModalButton',
  ContinueCompletionButton = 'continueCompletionButton',
  InvalidCompletionInfo = 'invalidCompletionInfo',
  CompletionEmailConfirmSkip = 'completionEmailConfirmSkip',
  ContinueWithNumber = 'continueWithNumber',
  AuthWithAnotherMethod = 'authWithAnotherMethod',
  ReloadOnLostPage = 'reloadOnLostPage'
}

export const enum EventCategory {
  Auth = 'auth',
  Reg = 'reg',
  Info = 'info',
  AuthReg = 'auth(reg)'
}

export const enum EventAction {
  ButtonClick = 'button_click',
  ElementClick = 'element_click',
  Rejected = 'rejected',
  Success = 'success'
}

export const enum EventLabel {
  AkkauntZablokirovan = 'akkaunt_zablokirovan',
  Avtorizaciya = 'avtorizaciya',
  Capcha = 'capcha',
  Dalee = 'dalee',
  KakProdolzhim = 'kak_prodolzhim',
  Link = 'link',
  PodtverditeVhod = 'podtverdite_vhod',
  Registraciya = 'registraciya',
  UstanovkaParolya = 'ustanovka_parolya',
  VoitiPoParolu = 'voiti_po_parolu',
  VoitiPoEmailParolu = 'voiti_po_email_parolu',
  VvodKodaIzSMS = 'vvod_koda_iz_sms',
  VvodKodaIzEmail = 'vvod_koda_iz_email',
  Dozapolnenie = 'dozapolnenie',
  Email = 'email',
  VyborAkkaunta = 'vybor_akkaunta',
  MyVasPoteryali = 'my_vas_poteryali'
}

export const enum EventContext {
  Dalee = 'dalee',
  Limit = 'limit',
  NachatSnachala = 'nachat_snachala',
  NePomnuParol = 'ne_pomnu_parol',
  NevernyiKod = 'nevernyi_kod',
  Obnovit = 'obnovit',
  Ok = 'ok',
  Otmenit = 'otmenit',
  ParoliNeSovpadaut = 'paroli_ne_sovpadaut',
  Podrobnee = 'podrobnee',
  PoluchitKodPovtorno = 'poluchit_kod_povtorno',
  PoluchitSms = 'poluchit_sms',
  Prinyat = 'prinyat',
  Sohranit = 'sohranit',
  Telefon = 'telefon',
  Voiti = 'voiti',
  VoitiDrugimSposobom = 'voiti_drugim_sposobom',
  VoitiPoParolu = 'voiti_po_parolu',
  VoitiSPodtverzhdeniemNomera = 'voiti_s_podtverzhdeniem_nomera',
  VernutAccount = 'vernut_accaunt',
  TrebovaniyaKParolu = 'trebovaniya_k_parolu',
  VremiaIsteklo = 'vremia_isteklo',
  Prodolzhit = 'prodolzhit',
  ProdolzhitSNomerom = 'prodolzhit_s_nomerom',
  Propustit = 'propustit',
  YaZdes = 'ya_zdes'
}
