import { ThemeType } from '../contexts/themeContext/themeStyledTypes'

export const colors: ThemeType['colors'] = {
  appBackground: 'rgba(242, 245, 249, 1)',
  backgroundLight: 'rgba(249, 250, 253, 1)',
  backgroundWhite: 'rgba(255, 255, 255, 1)',

  modalBackground: 'rgba(249, 250, 253, 1)',
  modalShadow: 'rgba(27, 44, 78, 0.4)',

  black: '#000',
  lightBlack: '#121212',

  blue: '#0078d2',
  skyBlue: '#A4C2E7',

  gray: '#9CA5B5',
  grayLight: '#f3f4f6',
  grayAdditional: '#E9ECEF',
  grayDark: '#E8EAED',
  grayBackgroundCookie: '#F9FAFD',

  dark: 'rgba(27, 44, 78, 1)',
  dark5: 'rgba(27, 44, 78, 0.05)',
  dark10: 'rgba(27, 44, 78, 0.1)',
  dark20: 'rgba(27, 44, 78, 0.2)',
  dark40: 'rgba(27, 44, 78, 0.4)',
  dark60: 'rgba(27, 44, 78, 0.6)',
  dark70: 'rgba(27, 44, 78, 0.7)',
  dark80: 'rgba(27, 44, 78, 0.8)',

  darkBlue10: 'rgba(21, 52, 82, 0.1)',
  darkBlue20: 'rgba(21, 52, 82, 0.2)',

  accent: 'rgb(0, 121, 194)',
  accentHover: 'rgb(0, 112, 180)',
  accentPressed: 'rgb(0, 105, 169)',
  accentButtonText: 'rgb(255, 255, 255)',
  secondary: 'rgb(0, 121, 194)',
  secondaryHover: 'rgb(0, 112, 180)',
  secondaryPressed: 'rgb(0, 105, 169)',
  secondaryButtonText: 'rgb(255, 255, 255)',

  accent5: 'rgba(0, 121, 194, 0.05)',
  accent10: 'rgba(0, 121, 194, 0.1)',
  accent20: 'rgba(0, 121, 194, 0.2)',

  error: 'rgb(241, 47, 105)',
  error10: 'rgba(241, 47, 105, 0.1)',
  error15: 'rgba(241, 47, 105, 0.15)',

  errorLight: '#EB5757',

  errorHover: '#CC2658',
  errorActive: '#AA224B',

  white: 'rgba(255, 255, 255, 1)',
  white5: 'rgba(255, 255, 255, 0.05)',
  white10: 'rgba(255, 255, 255, 0.1)',
  white20: 'rgba(255, 255, 255, 0.2)',
  white60: 'rgba(255, 255, 255, 0.6)',
  white70: 'rgba(255, 255, 255, 0.7)',

  borderCookieButton: '#6E7191',

  inputBackAutofillBorder: 'rgba(27, 44, 78, 0.1)',
  inputBackAutofill: 'rgba(255, 255, 255, 1)',
  inputBack: 'rgba(255, 255, 255, 1)',
  inputBackOnFocus: 'rgba(255, 255, 255, 1)',
  inputBackOnError: 'rgba(255, 255, 255, 1)',

  buttonText: 'rgb(255, 255, 255)',
  colorTextButton: 'rgb(255, 255, 255)',
  colorBackground: 'rgba(242, 245, 249, 1)'
}
