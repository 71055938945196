import * as React from 'react'
import { BrowserHistory, createBrowserHistory } from 'history'
import { Router } from 'react-router-dom'

const HistoryContext = React.createContext<BrowserHistory | null>(null)

export const useHistory = () => React.useContext(HistoryContext)

type PropsType = {
  children: React.ReactNode
}

const BrowserRouter = ({ children }: PropsType) => {
  const { current: history } = React.useRef(createBrowserHistory({ window }))
  const [{ location }, setHistoryState] = React.useState<Partial<BrowserHistory> & { location: Partial<Location> }>({
    location: history.location
  })

  React.useLayoutEffect(() => history.listen(setHistoryState), [history])

  return (
    <Router location={location} navigator={history}>
      <HistoryContext.Provider value={history}>{children}</HistoryContext.Provider>
    </Router>
  )
}

export default BrowserRouter
