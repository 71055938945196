import * as React from 'react'

import { ThemeList, useThemeUpdater } from '@/ui/contexts/themeContext'

import { useGetBranding } from '@/api/requests/apiBrandingInfo'
import { useBrandBackground } from '@/hooks/useBrendBackground'
import { useCustomColors } from '@/hooks/useCustomColors'

import { useBrandingSelector } from './brandingStore'

type PropsType = {
  children: React.ReactNode
}

export const BrandingStoreController = ({ children }: PropsType) => {
  const isNewRequestPath = useBrandingSelector.use.isNewRequestPath()
  const branding = useBrandingSelector.use.branding()
  const setStatus = useBrandingSelector.use.setStatus()

  const { setTheme } = useThemeUpdater()
  const { updateCustomColors } = useCustomColors()
  const { setBrandBackground } = useBrandBackground()

  const { isFetching } = useGetBranding(isNewRequestPath)

  React.useEffect(() => {
    if (branding) {
      setTheme(branding.theme || ThemeList.DEFAULT)
      updateCustomColors(branding.custom_colors)
      setBrandBackground(branding)
    } else if (isFetching) {
      setStatus('loading')
    }
  }, [branding, isFetching, setBrandBackground, setStatus, setTheme, updateCustomColors])

  return children
}

export const useBrandingInfo = (isNewRequestPath: boolean = false) => {
  const brandingInfo = useBrandingSelector.use.branding()
  const status = useBrandingSelector.use.status()
  const setStatus = useBrandingSelector.use.setStatus()
  const setIsNewRequestPath = useBrandingSelector.use.setIsNewRequestPath()

  React.useEffect(() => {
    setIsNewRequestPath(isNewRequestPath)
    if (!brandingInfo && status !== 'error') setStatus('needInit')
  }, [brandingInfo, isNewRequestPath, setIsNewRequestPath, setStatus, status])

  return brandingInfo
}
