import * as React from 'react'
import { useInterval } from 'react-use'

import { useUiErrorToastifyState, useUiErrorToastifyUpdater } from '@/ui/components/UiErrorToastify'
import RequestOTPButton from './components/RequestOTPButton'
import UiLoader from '../UiLoader'

import { updateArray } from './utils'
import { getTimerInMinutesAndSeconds } from '@/helpers/getTimerInMinutesAndSeconds'
import { timeDifferenceInSeconds } from '@/helpers/timeDifferenceInSeconds'

import {
  getPhrase,
  lengthOneTimePassword,
  remainingTimePhrase,
  templateTitle,
  TIME_TO_UPDATE_OTP_TIMER
} from './constants'
import { ErrorCodeName } from '@/types/errors'
import { FocusHandle } from './types'
import { KeyHandleType, OneTimePassword } from './enums'

import * as S from './UiOTP.style'

import { GoyaEventId, useGoyaAnalitics } from '@/features/GoyaAnalitics'
import { useCaptchaSelector } from '@/store/securityStore'

type PropsType = {
  onChange: (val: string | null) => void
  onClearErrorsHandler?: () => void
  requestOTP: () => Promise<unknown>
  error?: boolean
  principal?: string
  auto?: boolean
  delayCountdown?: number
  isShowCountdown?: boolean
  isShowCountdownButton?: boolean
  oneTimePasswordType?: OneTimePassword
  customTitle?: string
  isVerifying?: boolean
}

const UiOTP = React.forwardRef<FocusHandle, PropsType>(
  (
    {
      onChange,
      onClearErrorsHandler,
      error,
      requestOTP,
      principal = 'default',
      delayCountdown = 0,
      isShowCountdown = true,
      isShowCountdownButton = false,
      oneTimePasswordType = OneTimePassword.Code,
      auto = true,
      customTitle,
      isVerifying = false
    },
    ref
  ) => {
    const sendGoyaEvent = useGoyaAnalitics.use.sendEvent()

    const captchaScreenIsShow = useCaptchaSelector.use.captchaScreenIsShow()

    const [countdown, setCountdown] = React.useState<number>(0)
    const [digits, setDigits] = React.useState<string[]>(new Array(lengthOneTimePassword[oneTimePasswordType]).fill(''))

    const inputRefs = React.useRef<Array<HTMLInputElement>>(
      new Array<HTMLInputElement>(lengthOneTimePassword[oneTimePasswordType])
    )
    const focusRef = React.useRef<HTMLInputElement | null>(null)

    const lengthInputs = inputRefs.current.length

    const autoGetCode = React.useRef<boolean>(auto)

    const [visibleCountdown, setVisibleCountdown] = React.useState<boolean>(false)
    const [isFirstGetCode, setIsFirstGetCode] = React.useState<boolean>(true)
    const [isLoadingRequest, setIsLoadingRequest] = React.useState<boolean>(false)

    const timerTypeKey = `${principal}_${oneTimePasswordType}`

    const { secondsOTP, errors } = useUiErrorToastifyState()

    const { setPhraseSecondsOTP } = useUiErrorToastifyUpdater()

    const isInputsDisabled = errors.some(
      ({ error_code }) =>
        error_code &&
        [
          ErrorCodeName.OTPThrottlingUser,
          ErrorCodeName.OTPThrottlingNewUser,
          ErrorCodeName.TooManyAttemptsToVerify
        ].includes(error_code)
    )

    const isMountedRef = React.useRef(true)

    const currentDataSeconds = secondsOTP[timerTypeKey]

    const phrase = currentDataSeconds?.phrase ?? remainingTimePhrase[oneTimePasswordType]

    const handleRequestOtp = React.useCallback(() => {
      setIsLoadingRequest(true)

      requestOTP()
        .catch(() => null)
        // Отмена на странице капчи
        .finally(() => {
          if (isMountedRef.current) {
            setIsLoadingRequest(false)
          }
        })
    }, [requestOTP])

    React.useEffect(() => {
      isMountedRef.current = true
      return () => {
        isMountedRef.current = false
      }
    }, [])

    React.useImperativeHandle(ref, () => ({
      focus() {
        inputRefs.current[0].focus()
      }
    }))

    useInterval(
      () => {
        const currentDataSeconds = secondsOTP[timerTypeKey]

        if (currentDataSeconds) {
          setCountdown(timeDifferenceInSeconds(currentDataSeconds.date))
        }
      },
      countdown > 0 ? TIME_TO_UPDATE_OTP_TIMER : null
    )

    React.useEffect(() => {
      if (isVerifying || isInputsDisabled || captchaScreenIsShow) return

      const findFirstEmptyValue = digits.findIndex(value => value === '')
      const currentIndex = findFirstEmptyValue === -1 ? digits.length - 1 : findFirstEmptyValue

      inputRefs.current[currentIndex].focus()
    }, [isVerifying, isInputsDisabled, digits, captchaScreenIsShow])

    React.useEffect(() => {
      // Сохраняем обработчики событий в Map, чтобы мы могли их удалить позже
      const currentRefs = inputRefs.current
      const focusHandlers = new Map<HTMLInputElement, () => void>()

      const focusHandler = (element: HTMLInputElement) => () => {
        focusRef.current = element
      }

      currentRefs.forEach(input => {
        const handler = focusHandler(input)
        focusHandlers.set(input, handler)
        input.addEventListener('focus', handler)
      })

      return () => {
        currentRefs.forEach(input => {
          const handler = focusHandlers.get(input)
          if (handler) {
            input.removeEventListener('focus', handler)
          }
        })
      }
    }, [])

    React.useEffect(() => {
      if (currentDataSeconds) {
        const { date } = currentDataSeconds

        const timeDifferent = timeDifferenceInSeconds(date)

        if (timeDifferent > 0) {
          setPhraseSecondsOTP(timerTypeKey, remainingTimePhrase[oneTimePasswordType])
          setCountdown(timeDifferent)
          setIsFirstGetCode(false)
          autoGetCode.current = false
        }
      }
    }, [currentDataSeconds, oneTimePasswordType, setPhraseSecondsOTP, timerTypeKey])

    // Автополучение кода
    React.useEffect(() => {
      if (!autoGetCode.current) return

      autoGetCode.current = false

      if (currentDataSeconds) {
        const { date } = currentDataSeconds

        // Получение последней даты запроса кода и сравнение с текущей
        const secondsSinceLastRequest = timeDifferenceInSeconds(date)

        if (secondsSinceLastRequest <= 0) {
          handleRequestOtp()
        } else {
          setCountdown(secondsSinceLastRequest)
        }
      } else {
        handleRequestOtp()
      }
    }, [currentDataSeconds, isShowCountdown, handleRequestOtp, timerTypeKey])

    // логика показа обратного отсчета
    React.useEffect(() => {
      if (isShowCountdown && !visibleCountdown) {
        if (countdown > 0) {
          // показать сразу обратный отсчет
          setVisibleCountdown(true)
        } else {
          // задержка перед отображением обратного отсчета
          setTimeout(() => {
            if (isMountedRef) setVisibleCountdown(true)
          }, delayCountdown)
        }
      }
    }, [countdown, delayCountdown, isShowCountdown, setVisibleCountdown, visibleCountdown])

    const updateDigits = (digits: string[]) => {
      setDigits(digits)

      if (!digits.some(digit => digit === '')) {
        onChange(digits.join(''))
      } else {
        onChange(null)
      }
    }

    const handleChange = (index: number, event?: React.FormEvent<HTMLInputElement>) => {
      if (!event) {
        return
      }

      const { value } = event.currentTarget

      // Для проверки подставновки из autocomplete
      if (value?.length === digits.length) {
        updateDigits([...value])
        return
      }

      const oldDigit = digits[index]

      const isValueSame = oldDigit === value

      // если поле дополняется или изменяется заменяем старое на новое, если не изменяется, то оставляем так
      const newDigit = isValueSame ? value : value.trim().replace(oldDigit, '')

      // если пользователь ввел не число, или просто что-то непонятное, то выходим без заполнения
      if (!newDigit || Number.isNaN(Number(newDigit))) {
        return
      }

      updateDigits(updateArray(digits, index, newDigit))

      // если все значения заполнены, снимаем фокус с выбранной ячейки
      if (inputRefs.current.every(({ value }) => value)) {
        focusRef.current?.blur()
        return
      }

      const inputs = inputRefs.current

      // если ячейка не последняя производим фокус,
      // если нет, то пользователь еще может вернуться назад с помощью стрелок
      if (index < inputs.length - 1) {
        inputs[index + 1].focus()
      }
    }

    const handleKeyDown = (index: number, keyPressed: string) => {
      const isFirstField = index === 0
      const isLastField = index === digits.length - 1

      switch (keyPressed) {
        case KeyHandleType.Backspace: {
          if (digits[index]) {
            updateDigits(updateArray(digits, index, ''))
          } else if (!isFirstField) {
            inputRefs.current[index - 1].focus()
            updateDigits(updateArray(digits, index - 1, ''))
          }
          break
        }

        case KeyHandleType.Delete: {
          updateDigits(updateArray(digits, index, ''))
          break
        }

        case KeyHandleType.ArrowRight: {
          if (!isLastField) {
            inputRefs.current[index + 1].focus()
          }
          break
        }

        case KeyHandleType.ArrowLeft: {
          if (!isFirstField) {
            inputRefs.current[index - 1].focus()
          }
          break
        }
        default:
          break
      }
    }

    const handlePaste = (event: React.ClipboardEvent<HTMLInputElement>) => {
      event.preventDefault()

      const digitsFromPaste = event.clipboardData
        .getData('Text')
        .replaceAll(/[^0-9]/g, '')
        .split('')

      const { length } = digitsFromPaste

      let newDigits = digits

      if (length === lengthInputs) {
        newDigits = digitsFromPaste
      }

      if (length < lengthInputs) {
        newDigits = [...digitsFromPaste, ...Array<string>(lengthInputs - length).fill('')]
      }

      if (length > lengthInputs) {
        newDigits = digitsFromPaste.filter((digit, index) => index < lengthInputs)
      }

      updateDigits(newDigits)

      const lastInput = newDigits.findIndex(digit => digit === '')

      inputRefs.current[lastInput > -1 ? lastInput : lengthInputs - 1].focus()
    }

    const setRef = (ref: HTMLInputElement | null, index: number) => {
      if (ref) {
        inputRefs.current[index] = ref
      }
    }

    const resetInputOTP = (): void => {
      updateDigits(digits.fill(''))
      inputRefs.current[0].focus()
    }

    const onRequestOTPButton = (): void => {
      sendGoyaEvent(GoyaEventId.OtpGetAgain)
      handleRequestOtp()
      resetInputOTP()
    }

    const titleBlock = () => {
      const title = customTitle ?? templateTitle[oneTimePasswordType](principal)

      if (title) {
        return <S.TitleText data-testid='title-otp'>{title}</S.TitleText>
      }

      return null
    }

    const countdownButton = (text: string) => {
      return <RequestOTPButton text={text} onClick={onRequestOTPButton} isLoading={isLoadingRequest} />
    }

    const countdownElement = () => {
      return (
        <S.CountdownWrapper>
          <span data-testid='countdown-phrase'>{phrase}</span>
          <span data-testid='counter'>{getTimerInMinutesAndSeconds(countdown)}</span>
        </S.CountdownWrapper>
      )
    }

    const countdownBlock = () => {
      if (!isShowCountdown) {
        return null
      }

      if (isShowCountdownButton) {
        return countdownButton(
          currentDataSeconds ? getPhrase[oneTimePasswordType].Retry : getPhrase[oneTimePasswordType].Simple
        )
      }

      if (isFirstGetCode) {
        if (countdown > 0) {
          return countdownElement()
        }

        if (!visibleCountdown) {
          return null
        }

        return countdownButton(
          currentDataSeconds ? getPhrase[oneTimePasswordType].Retry : getPhrase[oneTimePasswordType].Simple
        )
      }

      return countdown > 0 ? countdownElement() : countdownButton(getPhrase[oneTimePasswordType].Retry)
    }

    const codeVerifyingLoaderBlock = () => {
      return (
        <S.CountdownWrapper>
          <UiLoader size={16} />
        </S.CountdownWrapper>
      )
    }

    return (
      <>
        {titleBlock()}
        <S.Wrapper data-testid='otp-wrapper'>
          {digits.map((digit, index) => (
            <S.Input
              data-testid={`otp-${oneTimePasswordType}-${index}`}
              key={index}
              ref={ref => setRef(ref, index)}
              value={digit}
              error={error}
              lengthOTP={lengthOneTimePassword[oneTimePasswordType]}
              type='text'
              inputMode='numeric'
              autoComplete='one-time-code'
              autoFocus={index === 0}
              onInput={event => handleChange(index, event)}
              onClick={onClearErrorsHandler}
              onKeyDown={event => handleKeyDown(index, event.nativeEvent.key)}
              onPaste={handlePaste}
              disabled={isVerifying || isInputsDisabled}
            />
          ))}
        </S.Wrapper>
        {isVerifying ? codeVerifyingLoaderBlock() : countdownBlock()}
      </>
    )
  }
)

UiOTP.displayName = 'UiOTP'

export default UiOTP
