import { CountryType } from '@/ui/components/UiPhone/types'

export const INIT_COUNTRY: CountryType = {
  name: 'Россия',
  iso: 'RU',
  cls: 'ru',
  code: '+7',
  priority: 0
}

export const TEXT_SUBMIT_BUTTON = 'Далее'

export const LOGIN_CHALLENGE = 'login_challenge'

export const THEME = 'theme'

export const MINUTE = 60

export const enum TabName {
  Phone = 'Телефон',
  Email = 'Почта'
}

export const MAX_EMAIL_LENGTH = 100

export const MAX_EMAIL_PASSWORD_LENGTH = 50

export const AUTH_TITLE = 'Вход или регистрация'
export const PASSWORD_RECOVERY_TITLE = 'Восстановление пароля'
