import { devtools } from 'zustand/middleware'
import { create } from 'zustand'

import { getCurrentScreenName, getGoyaCookieValue, sendConfiguredEvent } from './utils'

import { IS_REAL_INTEGRATION } from '@/constants'
import { IS_GOYA_QUERIES_ENABLED, PAGE_VIEW_EVENT_NAME } from './constants'
import { AddingEventTuple, GoyaBrowserEvent, GoyaStoreEventConfig } from './types'
import { GoyaEventId } from './enums'

import createSelectors from '@/store/createSelectors'

type GoyaEventsStore = {
  events: Partial<Record<GoyaEventId, GoyaStoreEventConfig>>
  addEvents: (...eventTuples: AddingEventTuple[]) => void
  sendEvent: (eventId: GoyaEventId, uiEvent?: GoyaBrowserEvent) => void
  currentScreenName?: string
}

export const goyaEventsStore = create<GoyaEventsStore>()(
  devtools(
    (set, get) => ({
      events: {},
      addEvents: (...eventTuples) => {
        const goyaCookieValue = getGoyaCookieValue()
        if (!IS_GOYA_QUERIES_ENABLED || !goyaCookieValue) return

        const currentPageScreenName = getCurrentScreenName()

        if (currentPageScreenName !== get().currentScreenName) {
          // событие особой конфигурации (нет отдельного конфига), отправляется на всех страницах с аналитикой
          // confluence code_id:1
          sendConfiguredEvent({
            event_group: PAGE_VIEW_EVENT_NAME,
            event_name: PAGE_VIEW_EVENT_NAME,
            screen_name: currentPageScreenName
          })
          set({ currentScreenName: currentPageScreenName, events: {} })
        }

        const addedEvents: GoyaEventsStore['events'] = {}

        for (const [eventId, eventConfig] of eventTuples) {
          const storeEventConfig = { ...eventConfig, screen_name: currentPageScreenName }
          addedEvents[eventId] = storeEventConfig
        }

        set(({ events }) => ({ events: { ...events, ...addedEvents } }))
      },
      sendEvent: (eventId, uiEvent) => {
        const config = get().events[eventId]
        if (!config) return

        sendConfiguredEvent(config, uiEvent)
      }
    }),
    { name: 'Goya Events', enabled: !IS_REAL_INTEGRATION }
  )
)

export const useGoyaAnalitics = createSelectors(goyaEventsStore)
