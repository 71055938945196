import UiThemeSwitcher from '@/ui/components/UiThemeSwitcher'
import { ThemeList, useThemeUpdater } from '@/ui/contexts/themeContext'

import { TempBtnBlock } from './ThemeSwitcher.style'

type ThemeSwitcherProps = {
  isOn: boolean
}

const ThemeSwitcher = ({ isOn }: ThemeSwitcherProps) => {
  const { setTheme } = useThemeUpdater()

  const onSwitchTheme = (isSwitch: boolean): void => setTheme(isSwitch ? ThemeList.DARK : ThemeList.DEFAULT)

  return isOn ? (
    <TempBtnBlock>
      <UiThemeSwitcher onChange={onSwitchTheme} />
    </TempBtnBlock>
  ) : null
}

export default ThemeSwitcher
