import icons from './constants'
import { Icons } from './types'

import { IconWrapper } from './UiIcon.style'

type PropsType = {
  name: Icons
  width?: string
  height?: string
  color?: string
}

const UiIcon = ({ name, width, height, color }: PropsType) => {
  const Icon = icons[name]

  return (
    <IconWrapper color={color}>
      <Icon width={width} height={height} />
    </IconWrapper>
  )
}

export default UiIcon
