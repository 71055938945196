export enum Namespace {
  Local = 'local',
  Dev = 'dev',
  Stage = 'stage',
  Test = 'test',
  Test01 = 'test01',
  Test02 = 'test02',
  Sandbox = 'sandbox',
  Autotest = 'autotest',
  Preprod = 'preprod',
  Prod = 'prod'
}

export enum Protocol {
  HTTP = 'http://',
  HTTPS = 'https://'
}

export enum Domain {
  Prod = 'gid.ru',
  ProdAuth = 'auth.gid.ru',
  Preprod = 'preprod.gid-auth.ru'
}

export enum Landing {
  Agreement = 'Agreement',
  Partners = 'Partners',
  PersonalData = 'PersonalData',
  PersonalDataConsent = 'PersonalDataConsent'
}
