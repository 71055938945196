export enum CaptchaType {
  Captcha = 'Captcha',
  Recaptcha = 'Recaptcha'
}

export enum ResponseCaptcha {
  Ok = 'ok',
  NotNeeded = 'notNeeded'
}

export enum KSIDType {
  Empty = 'empty'
}
