import * as React from 'react'
import { ThemeProvider as EmotionThemeProvider } from '@emotion/react'

import { ThemeType } from '@/ui/contexts/themeContext/themeStyledTypes'

import { THEME } from './constants'
import { ContextPropsType, ThemeActionType, ThemeUpdaterType } from './types'
import { ThemeActionTypeEnum, ThemeList } from './enums'

import { DARK_THEME } from './customThemes'

const ThemeState = React.createContext<ThemeList | null>(null)
const ThemeUpdater = React.createContext<ThemeUpdaterType | null>(null)

export function useThemeUpdater() {
  const dispatchTheme = React.useContext(ThemeUpdater)
  if (!dispatchTheme) {
    throw new Error('useThemeUpdater context can be accessed only under SsoThemeProvider')
  }
  return dispatchTheme
}

export function useThemeState() {
  const themeContext = React.useContext(ThemeState)
  if (!themeContext) {
    throw new Error('useThemeState context can be accessed only under SsoThemeState')
  }
  return themeContext
}

const ThemeReducer = (state: ThemeType, action: ThemeActionType): ThemeType => {
  switch (action.type) {
    case ThemeActionTypeEnum.SET_THEME:
      if (action.payload === ThemeList.DEFAULT) {
        return THEME
      }
      if (action.payload === ThemeList.DARK) {
        return { ...state, colors: { ...state.colors, ...DARK_THEME.colors } }
      }
      break

    case ThemeActionTypeEnum.SET_BACK_IMG:
      if (action.payload) {
        return { ...state, backgroundImg: action.payload.backgroundImg }
      }
      break

    case ThemeActionTypeEnum.SET_CUSTOM_COLORS:
      if (action.payload) {
        return { ...state, colors: { ...state.colors, ...action.payload } }
      }
      break

    default:
      return state
  }

  return state
}

export const ThemeProvider = ({ children }: ContextPropsType) => {
  const [state, dispatch] = React.useReducer(ThemeReducer, THEME)
  const [themeType, setThemeType] = React.useState<ThemeList>(ThemeList.DEFAULT)

  const memoThemeType = React.useMemo<ThemeList>(() => themeType, [themeType])

  const valueUpdater = React.useMemo<ThemeUpdaterType>(
    () => ({
      setTheme: payload => {
        setThemeType(payload)
        return dispatch({ type: ThemeActionTypeEnum.SET_THEME, payload })
      },
      setBackImg: payload => dispatch({ type: ThemeActionTypeEnum.SET_BACK_IMG, payload }),
      setCustomColors: payload => dispatch({ type: ThemeActionTypeEnum.SET_CUSTOM_COLORS, payload })
    }),
    []
  )

  return (
    <ThemeState.Provider value={memoThemeType}>
      <ThemeUpdater.Provider value={valueUpdater}>
        <EmotionThemeProvider theme={state}>{children}</EmotionThemeProvider>
      </ThemeUpdater.Provider>
    </ThemeState.Provider>
  )
}
