import * as React from 'react'
import { AxiosError } from 'axios'

import { useUiErrorToastifyUpdater } from '@/ui/components/UiErrorToastify'

import { getKeyOtpSeconds } from '@/helpers/otpSecondsUtils'
import { TypeKeySeconds } from '@/helpers/otpSecondsUtils/types'
import useRedirectTo from '@/hooks/useRedirectTo'

import { ErrorCodeName, TErrorsResponse } from '@/types/errors'
import { AuthFactors } from '@/enums/auth'
import { useClientInfo } from '@/store/clientInfoStore'

const useErrorParser = (principalOTP = '', factor?: AuthFactors) => {
  const clientInfo = useClientInfo()
  const { setErrors, setSeconds, setSecondsOTP } = useUiErrorToastifyUpdater()
  const { redirectTo } = useRedirectTo()

  return React.useCallback(
    (error: AxiosError<TErrorsResponse>) => {
      if (error?.response && error.response.status === 401 && clientInfo?.refresh_url) {
        redirectTo(clientInfo?.refresh_url)
        return
      }
      // TODO если ошибки пятисотые, то нотификацию выводить не надо, т. к. будет отображаться модалка с ошибкой
      if (error?.response && error.response.status >= 500) return

      const data = error?.response?.data

      if (data?.retry_after_seconds) {
        switch (data.errors_code) {
          case ErrorCodeName.OtpRateLimit:
            setSecondsOTP(
              getKeyOtpSeconds(principalOTP, factor as TypeKeySeconds),
              data.retry_after_seconds,
              data?.errors_description
            )
            // если код соответсвует, то не нужно парсить ошибку дальше
            return
          default:
            setSeconds(data.retry_after_seconds)
        }
      }

      if (data?.field_errors?.length) {
        setErrors(data.field_errors)
      } else if (data?.errors_description) {
        setErrors([
          {
            error_code: data?.errors_code,
            error_description: data.errors_description
          }
        ])
      }

      if (data?.redirect_to) {
        redirectTo(data.redirect_to)
      }
    },
    [factor, principalOTP, redirectTo, setErrors, setSeconds, setSecondsOTP]
  )
}

export const useFildListErrorParser = () => {
  return (error: AxiosError<TErrorsResponse> | null) => {
    if (error?.response?.data?.field_errors?.length) {
      return error.response.data.field_errors.map(error => error.field_name)
    }

    return []
  }
}

export default useErrorParser
